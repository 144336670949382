<div class="content-area">
  <div class="col-sm-10 offset-sm-1">
    <app-top-bar selectedTab="home" [heading]="''"></app-top-bar>
    <app-header></app-header>
    <div class="title">
        {{pageHeading}}
    </div>
    <div class="col-sm-6 offset-sm-3">
    <mat-card>
      <div class="col-sm-8 offset-sm-2">
        <div class="row application-form-row ">
          <label class="form-label">Choose Layout </label>
          <input type="text" [(ngModel)]="siteAllotmetLayoutName" class="form-textbox" disabled />
        </div>
      </div>
      <div class="col-sm-8 offset-sm-2">
        <div class="row application-form-row ">
          <label class="form-label">Mobile No</label>
          <input type="number" [(ngModel)]="mobileNo" class="form-textbox" disabled/>
        </div>
      </div>
      <div class="col-sm-8 offset-sm-2">
        <div class="row application-form-row ">
          <label class="form-label">Enter 4 Digit OTP </label><p *ngIf="!timerFinished" style="margin-left: 51%; color: red;">{{ timer }} seconds</p>
          <input type="number" [(ngModel)]="enteredOtp" [maxlength]="4" class="form-textbox" />
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <button class="btn btn-primary submit-btn" (click)="submit()">Submit</button>
        </div>
        <div class="col-sm-6">
          <button class="btn btn-primary login-btn" [disabled]="buttonDisabled" (click)="generateOtp()">Regenerate OTP</button>
        </div>
      </div>
    </mat-card>
    </div>
  </div>
</div>