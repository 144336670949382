import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicantEnclosureListComponent } from 'src/app/applicant-enclosure-list/applicant-enclosure-list.component';
import { PreviewDialogComponent } from 'src/app/preview-dialog/preview-dialog.component';
import { RoleConfigService } from 'src/app/shared/config/role/role.config.service';
import { ApplicationService } from 'src/services/application.service';
import { UserService } from 'src/services/user-service/user.service';
import { forkJoin, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { StrorageService } from 'src/services/strorage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EnclosureService } from 'src/services/enclosure-service/enclosure.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Window } from 'selenium-webdriver';
import { ApplicationDetailsPreviewDialogComponent } from '../application-details/application-details-preview-dialog/application-details-preview-dialog.component';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { DateTimeUtils } from 'src/app/utils/date-utils';


@Component({
  selector: 'app-acknowledgement',
  templateUrl: './acknowledgement.component.html',
  styleUrls: ['./acknowledgement.component.scss']
})
export class AcknowledgementComponent implements OnInit {

  public enclosures = [];
  public data: any = undefined;
  public village: any;
  public panchayat: any;
  public isEnclosureShown: any = true;
  public isHelpdeskUser: any;
  public isEditable = false;
  public oneTimeEdit = false;
  public showOneTimeEditButton = false;
  public showSaveChangesBtn = false;
  public faTimes = faTimes;
  public partlyNotifiedSurveyNo: any;
  public partlyNotifedSurveyNosVO: any;
  public selectedCategoriesStr: string = '';
  public previousAllotments: any[] = [];
  public enclosureData: any = {};

  private applicantId: any;
  private applicantNumber: any;
  public enclosure: any;


  @Output() oneTimeEditCallback = new EventEmitter<any>();

  @Output() saveChangesCallback = new EventEmitter<any>();

  public enclosuresPrintData: any = [];

  @ViewChild('printData') printData: ElementRef;

  constructor(private applicationService: ApplicationService,
    private route: ActivatedRoute, private jccEncodeDecoder: JccnoEncodeDecodeService,
    private router: Router, public dialog: MatDialog,
    public userService: UserService, private roleConfig: RoleConfigService,
    private cdr: ChangeDetectorRef, private storageService: StrorageService,
    private _snackBar: MatSnackBar, private enclosureService: EnclosureService) {
    this.applicantId = window.localStorage.getItem("applicantId");
  }

  ngOnInit(): void {
    let user = this.storageService.getUser();
    this.resetEnclosureData();
    if (this.applicantId != null) {
      this.fetchApplicantById(this.applicantId);
    }

  }

  private resetUI = (applicantData: any) => {
    if (applicantData['oneTimeEdit'] == null) {
    }
    if (applicantData['oneTimeEdit']) {
      this.showOneTimeEditButton = false;
      this.showSaveChangesBtn = true;
    }
  }

  private fetchApplicantById = (applicantId: any) => {
    if (!applicantId) {
      return;
    }
    this.applicationService.getApplicantById(applicantId).subscribe(res => {
      this.data = res;
      this.previousAllotments = res.previousSiteAllotments;
      this.enclosureData = res.enclosureData;
      this.fetchEnclosureData();
      this.resetUI(res);
    })
  }

  fetchEnclosureData = () => {
    this.applicationService.getEnclosureListByApplicantId(this.applicantId).subscribe(res => {
      this.enclosures = res;
    })
  }
  resetEnclosureData = () => {
    let enclosureData = this.enclosureService.enclosures;
    for (let i = 0; i < enclosureData.length; i++) {
      enclosureData[i]['active'] = false;
    }

  }
  private printEnclosureData = (data: any) => {
    let enclousresData = this.enclosureService.enclosures;
    Object.keys(data).forEach(function (key) {
      console.log('Key : ' + key + ', Value : ' + data[key]);
      for (let j = 0; j < enclousresData.length; j++) {
        if (key == enclousresData[j]["id"] && data[key]) {
          enclousresData[j]["active"] = true;
        }
      }

    })
    this.enclosuresPrintData = enclousresData;
    console.log(enclousresData);
  }

  saveApplicantStatus() {
    this.applicationService.saveApplicantStatus(this.applicantId).subscribe(res => {
      this._snackBar.open("Application Digitized for " + this.data.acknowledgementNo);
      setTimeout(function () {
        window.location.reload();;
      }, 1000);
      this.storageService.removeItem('applicantId');
    })
  }

  public goDetailsPage(data: any) {
    this.openEnclosure(data);
  }

  openEnclosure = (data: any): void => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '80vh';
    dialogConfig.width = '50vw';
    dialogConfig.data = data.applicantId;
    const dialogRef = this.dialog.open(ApplicantEnclosureListComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {

    })
  }

  onPreviewClick = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '80vh';
    dialogConfig.width = '70vw';
    dialogConfig.data = this.data;
    const dialogRef = this.dialog.open(ApplicationDetailsPreviewDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
    })
  }

  openDialog = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '160px';
    dialogConfig.width = '450px';
    dialogConfig.data = "Confirm Application Submission?"
    const dialogRef = this.dialog.open(ConfirmationModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.saveApplicantStatus();
      }
    })
  }

  onDone() {
    this.openDialog();
  }

  preparePrintData(source: any) {

    let data = "<html><head><style type='text/css' media='print'>@media print {display:block; @page { size: auto; margin: 0;} body { margin:1.6cm; } }</style><script>function step1(){\n" +
      "setTimeout('step2()', 2);}\n" +
      "function step2(){window.print()?dev=version=1.0.0';window.close()}\n" +
      "</script></head><body onload='step1()'>" + source + '?' + Date.now() + "</body></html>";
    return data;
  }
  printPage() {
    window.print();
  }
  onPrint = () => {
    var printContents = this.printData.nativeElement.innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.close();
  }


  private routeToHomePage = (): void => {
    let user = this.storageService.getUser();
    if (user['roleCode'] == this.roleConfig.ROLE_ONLINE_APPLICATION_USER) {
      this.router.navigate(['/application']);
    } else {
      window.scroll(0, 0);
      if (user['roleCode'] == this.roleConfig.ROLE_ADMIN_USER) {
        this.router.navigate(['/admin']);
      } else {
        this.router.navigate(['/application']);
      }
    }
  }

  public closeOneTimeEdit = (): void => {

  }

  openSnackbar = (message: string, action?: string) => {
    if (!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }

  public activateOneTimeEdit = () => {

  }

  public deActivateOneTimeEdit = () => {

  }
}
