import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApplicationFormComponent } from './application-form/application-form.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { ApplicantListComponent } from './applicant-list/applicant-list.component';
import { ApplicantFormComponent } from './applicant-form/applicant-form.component';
import { UploadEnclosureComponent } from './upload-enclosure/upload-enclosure.component';
import { ApplicantEnclosureListComponent } from './applicant-enclosure-list/applicant-enclosure-list.component';
import { HelpDeskLoginComponent } from './help-desk-login/help-desk-login.component';
import { OnlineApplicationLoginComponent } from './online-application-login/online-application-login.component';
import { ApplicationLoginTypeComponent } from './application-login-type/application-login-type.component';
import { CommunityLoginComponent } from './community-login/community-login.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { AdminComponent } from './admin/admin.component';
import { AuditHelpdeskComponent } from './audit-helpdesk/audit-helpdesk.component';
import { AuditHelpdeskLoginComponent } from './audit-helpdesk-login/audit-helpdesk-login.component';
import { PreviewEnclosureComponent } from './preview-enclosure/preview-enclosure.component';
import { PrintDialogAcknowledgementComponent } from './application-form/print-dialog-acknowledgement/print-dialog-acknowledgement.component';
import { CommiteeWorkbenchComponent } from './applicant-list/commitee-workbench/commitee-workbench.component';
import { AuthGuardService } from 'src/services/auth-guard.service';
import { CommitteeActiveStatusComponent } from './applicant-list/committee-active-status/committee-active-status.component';
import { CommitteeDatatableComponent } from './applicant-list/committee-datatable/committee-datatable.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ApplicationInwardWorkbenchComponent } from './application-inward-login/application-inward/application-inward-workbench/application-inward-workbench.component';
import { ApplicationInwardActivityLogComponent } from './application-inward-login/application-inward/application-inward-activity-log/application-inward-activity-log.component';
import { GenericLoginComponent } from './generic-login/generic-login.component';
import { GenerateOtpComponent } from './generate-otp/generate-otp.component';
import { LeadershipDashboardComponent } from './leadership-user/leadership-dashboard/leadership-dashboard.component';
import { AllotmentDashboardComponent } from './allotment-login/allotment-dashboard/allotment-dashboard.component';
import { AllotmentWorkbenchComponent } from './allotment-login/allotment-workbench/allotment-workbench.component';
import { EditApplicationInwardWorkbenchComponent } from './application-inward-login/edit-application-inward-workbench/edit-application-inward-workbench.component';
import { LandloserLetterPrintingWorkbenchComponent } from './allotment-login/landloser-letter-printing-workbench/landloser-letter-printing-workbench.component';
import { PrintingCompletedDetails } from './allotment-login/printing-completed-details/printing-completed-details.component';
import { ActiveStatusDashboardComponent } from './leadership-user/active-status-dashboard/active-status-dashboard.component';
import { SiteStatusDashboardComponent } from './leadership-user/site-status-dashboard/site-status-dashboard.component';
import { LeadershipApplicantDatatableComponent } from './leadership-user/leadership-applicant-datatable/leadership-applicant-datatable.component';
import { SiteMatrixDataViewComponent } from './leadership-user/site-matrix-data-view/site-matrix-data-view.component';

const routes: Routes = [
  {
    path: 'application_login',
    pathMatch: 'full',
    component: ApplicationFormComponent,
  },
  {
    path: 'applicant-list',
    pathMatch: 'full',
    component: ApplicantListComponent,
  },
  {
    path: 'contact',
    pathMatch: 'full',
    component: ContactComponent,
  },
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
  },
  {
    path: 'applicant-form',
    component: ApplicantFormComponent,
    children: [
      {path: '**', redirectTo: 'upload-enclosure'},
      {path: 'upload-enclosure', component: UploadEnclosureComponent},
    ]
  },
  {
    path: 'applicant-enclosure-list',
    pathMatch: 'full',
    component: ApplicantEnclosureListComponent, 
  },
  {
    path: 'helpdesk-login',
    pathMatch: 'full',
    component: HelpDeskLoginComponent,
  },
  {
    path: 'generic-login',
    pathMatch: 'full',
    component: GenericLoginComponent,  
  },
  {
    path: 'preview-enclosure',
    pathMatch: 'full',
    component: PreviewEnclosureComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'online-applicant-login',
    pathMatch: 'full',
    component: OnlineApplicationLoginComponent,
  },
  {
    path: 'application',
    pathMatch: 'full',
    component: ApplicationLoginTypeComponent,
  },
  {
    path: 'committee-login',
    pathMatch: 'full',
    component: CommunityLoginComponent,
  },
  {
    path: 'admin-login',
    pathMatch: 'full',
    component: AdminLoginComponent,
  },
  {
    path: 'admin',
    pathMatch: 'full',
    component: AdminComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'audit-helpdesk',
    pathMatch: 'full',
    component: AuditHelpdeskComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'audit-helpdesk-login',
    pathMatch: 'full',
    component: AuditHelpdeskLoginComponent,
  },
  {
    path: 'print-dialog-acknowledgement',
    pathMatch:'full',
    component: PrintDialogAcknowledgementComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'commitee-workbench',
    pathMatch:'full',
    component: CommiteeWorkbenchComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'committee-active-status',
    pathMatch: 'full',
    component: CommitteeActiveStatusComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'committee-datatable',
    pathMatch: 'full',
    component: CommitteeDatatableComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'landing-page',
    pathMatch: 'full',
    component: LandingPageComponent,
  },
  {
    path: 'application-inward-workbench',
    pathMatch: 'full',
    component: ApplicationInwardWorkbenchComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'application-inward-activitylog',
    pathMatch: 'full',
    component: ApplicationInwardActivityLogComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'generate-otp',
    pathMatch: 'full',
    component: GenerateOtpComponent

  },
  {
    path: 'leadership-dashboard',
    pathMatch: 'full',
    component: LeadershipDashboardComponent

  }, 
  {
    path: 'edit-application-inward-workbench',
    pathMatch: 'full',
    component: EditApplicationInwardWorkbenchComponent,

  },  
  {
    path: 'allotment-dashboard',
    pathMatch: 'full',
    component: AllotmentDashboardComponent, canActivate : [AuthGuardService],

  },
  {
    path: 'allotment-workbench',
    pathMatch: 'full',
    component: AllotmentWorkbenchComponent, canActivate : [AuthGuardService],
  },
  {
    path: 'allotment-letter-printing-workbench',
    pathMatch: 'full',
    component: LandloserLetterPrintingWorkbenchComponent, canActivate : [AuthGuardService],

  },
  {
    path: 'landloser-printing-completed',
    pathMatch: 'full',
    component: PrintingCompletedDetails, canActivate : [AuthGuardService],

  },
  {
    path: 'active-status-dashboard',
    pathMatch: 'full',
    component: ActiveStatusDashboardComponent, canActivate : [AuthGuardService],

  },
  {
    path: 'site-status-dashboard',
    pathMatch: 'full',
    component: SiteStatusDashboardComponent, canActivate : [AuthGuardService],

  },
  {
    path: 'leadership-applicant-datatable',
    pathMatch: 'full',
    component: LeadershipApplicantDatatableComponent, canActivate : [AuthGuardService],

  },
  {
    path: 'leadership-site-matrix-data',
    pathMatch: 'full',
    component: SiteMatrixDataViewComponent, canActivate : [AuthGuardService],

  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }