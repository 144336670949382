<div class="content-area">
  <app-top-bar selectedTab="home"></app-top-bar>
  <div style="margin-left:10%; margin-right:10%;">
    <app-header></app-header>
  </div>
  <div style="margin-left:10%; margin-right:10%;">

  </div>
  <div style="margin-left:10%; margin-right:10%;">

    <div>
    </div>
    <div style="margin-left:10%; margin-right:10%;">

    </div><br><br><br>
    <div class="row login-row">
      <div class="col-sm-3">
        <app-login-button name="Application Inward" color="#c00000" [loginPath]="'/generic-login'" [loginType]="'inward_user'"></app-login-button>
      </div>
      <div class="col-sm-3">
        <app-login-button name="Helpdesk" color="#8faadc" [loginPath]="'/generic-login'" [loginType]="'helpdesk_user'"></app-login-button>
      </div>

      <div class="col-sm-3">
        <app-login-button name="Online Application" color="#be9487" [loginPath]="'/online-applicant-login'" [loginType]="'online_user'"></app-login-button>
      </div>

      <div class="col-sm-3">
        <app-login-button name="HD-Supervisor" color="#ed7d31" [loginPath]="'/online-applicant-login'"
          disabled=true></app-login-button>
      </div>
    </div>
    <div class="row login-row">
      <div class="col-sm-3">
        <app-login-button name="Scrutiny" color="#7c7c7c" [loginPath]="'/helpdesk-login'"
        [disabled]="true"></app-login-button>
      </div>
      <div class="col-sm-3">
        <app-login-button name="Leadership" color="#44546a" [loginPath]="'/generic-login'" [loginType]="'leadership_user'"></app-login-button>
      </div>
      <div class="col-sm-3">
        <app-login-button name="Allotment" color="#00e668"  [loginPath]="'/generic-login'" [loginType]="'allotment_user'"></app-login-button>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>