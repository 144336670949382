import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { AllotmentModeService } from 'src/app/shared/config/allotment-mode/allotment-mode.service';
import { CommonDataUtils } from 'src/app/utils/common-data-utils';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';
import { AllotmentCountContainerComponent } from '../allotment-count-container/allotment-count-container.component';

@Component({
  selector: 'app-allotment-workbench',
  templateUrl: './allotment-workbench.component.html',
  styleUrls: ['./allotment-workbench.component.scss']
})
export class AllotmentWorkbenchComponent implements OnInit {

  @ViewChild(AllotmentCountContainerComponent) countContainer: AllotmentCountContainerComponent;

  public isRefreshing = false;

  public faSync = faSync;

  allotmentType: string;

  user: any;

  selectedVillageId: any = '';

  siteAllotmentCountData: any;

  villageList: any[] = [];

  selectedVillageName: string = '';

  allowSiteMapping: boolean = false;

  allotmentMode: string = '';

  showPrintedSites: boolean = false;

  countMode: string = '';

  currentCountData: any;

  completedCountData: any;

  printedCountData: any;

  currentCountList: any[] = [];

  completedCountList: any[] = [];

  printedCountList: any[] = [];

  ecData: any = {
    villageName: this.selectedVillageName,
    surveyNo: "12/A",
    khatedarName: "John Doe",
    extent: "1A-20G -8 Anna",
    entExtent: "1220"
  };

  entitlementMatrixEnabled = false;

  constructor(private storageService: StrorageService, private router: Router,
    private route: ActivatedRoute, private applicationService: ApplicationService,
    private allotmentModeService: AllotmentModeService) {
    this.user = this.storageService.getUser();
    this.allotmentType = this.route.snapshot.queryParams['allotmentCountType'];
    this.allowSiteMapping = this.route.snapshot.queryParams['allowSiteMapping'];
    this.showPrintedSites = this.route.snapshot.queryParams['showPrintedSites'];
    this.selectedVillageName = this.route.snapshot.queryParams['villageName'];
  }

  ngOnInit() {
    this.fetchAllVillages();
    this.setAllotmentMode();
    this.fetchSiteCountData();
  }

  initializeAllotmentCountData = () => {
    if (this.allotmentType.toLowerCase() == 'total') {
      this.siteAllotmentCountData.headingText = 'Site Allotment Workflow - Total';
      this.siteAllotmentCountData.currentCountData.headerColor = 'green';
      this.siteAllotmentCountData.completedCountData.headerColor = 'indigo';
      this.siteAllotmentCountData.printedCountData.headerColor = 'indigo';
    }
    if (this.allotmentType.toLowerCase() == 'site_allotment') {
      this.siteAllotmentCountData.headingText = 'Site Allotment Workflow – Landloser Module – Site Allotment ';
      this.siteAllotmentCountData.currentCountData.headerColor = 'green';
      this.siteAllotmentCountData.completedCountData.headerColor = 'indigo';
      this.siteAllotmentCountData.printedCountData.headerColor = 'indigo';
    }
    if (this.allotmentType.toLowerCase() == 'allotment_letter_printing') {
      this.siteAllotmentCountData.headingText = 'Site Allotment Workflow – Landloser Module – Allotment Letter Printing';
      this.siteAllotmentCountData.currentCountData.headerColor = 'green';
      this.siteAllotmentCountData.completedCountData.headerColor = 'indigo';
    }
  }

  fetchAllVillages = () => {
    this.applicationService.fetchAllVillages().subscribe(res => {
      this.villageList = res;
      this.villageList.unshift({
        id:'',
        villageName:'All'
      });
      if (this.selectedVillageName) { //village name comes from route query params
        this.getVillageIdFromName();
      }
      this.setAllotmentMode();
    });
  }

  setAllotmentMode() {
    if (!this.allowSiteMapping && (this.selectedVillageId && this.allotmentType == 'site_allotment')) {
      this.allotmentMode = this.allotmentModeService.MODE_COMMENCE_SITE_ALLOTMENT;
      this.countMode = CommonDataUtils.COUNT_MODE_SITE_MAPPING;
      return;
    }
    if (this.allowSiteMapping && (this.selectedVillageId && this.allotmentType == 'site_allotment')) {
      this.allotmentMode = this.allotmentModeService.MODE_SITE_MAPPING;
      this.countMode = CommonDataUtils.COUNT_MODE_SITE_MAPPING;
      return;
    }
    if (!this.showPrintedSites && this.allotmentType == 'allotment_letter_printing') {
      this.allotmentMode = this.allotmentModeService.MODE_PRINT_ALLOTMENT_LETTER;
      this.countMode = CommonDataUtils.COUNT_MODE_PRINTING;
      return;
    }
    if (this.showPrintedSites && this.allotmentType == 'allotment_letter_printing') {
      this.allotmentMode = this.allotmentModeService.MODE_PRINTING_ACTIVITY;
      this.countMode = CommonDataUtils.COUNT_MODE_PRINTING;
      return;
    }
    this.allotmentMode = '';
  }

  fetchSiteCountData = () => {
    this.applicationService.fetchSiteCountData(this.countMode, this.selectedVillageId).subscribe(res => {
      this.siteAllotmentCountData = res;
      this.currentCountData = res.currentCountData;
      this.completedCountData = res.completedCountData;
      this.printedCountData = res.printedCountData;
      this.currentCountList = res.currentCountData.countPerDimensionList;
      this.completedCountList = res.completedCountData.countPerDimensionList;
      this.printedCountList = res.printedCountData?.countPerDimensionList;
      this.initializeAllotmentCountData();
    });
  }

  villageChanged = (villageId: any) => {
    if (villageId) {
      let village = this.villageList.find(village => village.id == villageId);
      this.selectedVillageName = village.villageName;
      this.ecData.villageName = this.selectedVillageName;
      this.setAllotmentMode();
      this.fetchSiteCountData();
    }else{
      this.selectedVillageName = '';
      this.ecData.villageName = '';
      this.setAllotmentMode();
      this.fetchSiteCountData();
    }
  }

  getVillageIdFromName() {
    if (this.selectedVillageName) {
      let village = this.villageList.find(village => village.villageName == this.selectedVillageName);
      this.selectedVillageId = village.id;
    }
  }

  refreshClick() {
    let currentComponent = this;
    this.isRefreshing = true;
    this.fetchSiteCountData();
    setTimeout(() => {
      currentComponent.isRefreshing = false;
    }, 3000)
  }



  clickBack() {
    window.history.back();
  }

}
