<div class="mainpage">
    <hr class="mt-4 hr" />
    <div *ngIf="selectedVillageName && !entitlementMatrixEnabled" class="row d-flex justify-content-center mt-3">
        <div class="col-sm-4 d-flex justify-content-center">
            <button type="button" class="btn btn-primary" (click)="showEntitlementMatrixWorkBench()">Commence Site
                Allotment
                Activity </button>
        </div>
    </div>
    <div *ngIf="entitlementMatrixEnabled" class="row d-flex justify-content-start mt-3">
        <div class="form-label offset-sm-1 my-3">
            Enter EC No :
        </div>
        <form class="ml-2 col-sm-4 w-25">
            <mat-form-field class="w-100 mat-form-resize" appearance="outline">
                <input type="text" class="form-textbox" matInput [formControl]="myControl" [matAutocomplete]="auto"
                    (input)="getEcNumbers($event.target.value)">
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setSelectedEcNumber($event)">
                    <mat-option *ngFor="let ecNumber of ecNumbers" [value]="ecNumber">
                        {{ecNumber}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </form>
    </div>
    <div *ngIf="selectedEcNumber">
        <app-ec-details-container [ecData]="ecData" (updateParent)="parentMethod()"></app-ec-details-container>
        <hr class="mt-4 hr" />
        <div class="row d-flex justify-content-start">
            <div class="col-sm-3 ec-no-text-holder">
                <b>Entitlement Matrix</b>
            </div>
            <div class="ml-5 col-sm-3">
                <button mat-button class="btn btn-primary bg-metallicorange"
                    (click)="onResetClicked(entitlementMatrixList)">
                    Reset</button>
                <button mat-button class="ml-4 btn btn-primary" (click)="onClickReCalculate(entitlementMatrixList)">Re
                    calculate</button>
            </div>
            <div class="row ec-no-text-holder">
                <b>Available Sites  : {{selectedVillageName}}</b>
            </div>
        </div>
        <div class="row d-flex justify-content-start">
            <table border="1" class="mt-2 col-sm-6">
                <thead>
                    <tr>
                        <th>Sl. No.</th>
                        <th>Dimension (in Sq. Ft.)</th>
                        <th>No. of Sites </th>
                        <th>Total Dimension (in Sq. Ft.)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let entitlementMatrix of entitlementMatrixList; let i = index">
                        <td style="text-align: center;">{{ i+1 }}</td>
                        <td><input type="text" class="borderless readonly-td"
                                [(ngModel)]="entitlementMatrix.dimensionLabel" readOnly />
                        </td>
                        <td>
                            <input type="number" class="borderless readonly-td" [(ngModel)]="entitlementMatrix.noOfSites"
                                (change)="noOfSiteChanged(entitlementMatrix)" (keydown)="preventMinusPlus($event)" />
                        </td>
                        <td><input type="text" class="borderless readonly-td"
                                [(ngModel)]="entitlementMatrix.totalDimension" readOnly /></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td><input type="text" [value]="'Total'" class="borderless readonly-td" readOnly /></td>
                        <td><input type="text" class="borderless readonly-td" [(ngModel)]="totalSitesSum" readOnly />
                        </td>
                        <td><input type="text" class="borderless readonly-td" [(ngModel)]="totalDimensionSum"
                                readOnly /></td>
                    </tr>
                </tbody>
            </table>
            <table border="1" class="mt-2 col-sm-5" style="margin-left: 5%;">
                <thead>
                    <tr>
                        <th>Sl. No.</th>
                        <th>Dimension (in Sq. Ft.)</th>
                        <th>No. of Sites </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let ecSiteMasterData of ecSiteMasterDataList; let i = index">
                        <td style="text-align: center;">{{ i+1 }}</td>
                        <td><input type="text" class="borderless readonly-td"
                                [(ngModel)]="ecSiteMasterData.dimensionLabel" readOnly />
                        </td>

                        <td><input type="number" class="borderless readonly-td"
                                [(ngModel)]="ecSiteMasterData.noOfSites" /></td>

                    </tr>
                    <tr>
                        <td></td>
                        <td><input type="text" [value]="'Total'" class="borderless readonly-td" readOnly /></td>
                        <td><input type="text" class="borderless readonly-td" [(ngModel)]="totalAvailableSites" readOnly />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row d-flex justify-content-start mt-5">
            <div class="col-sm-5 ec-no-text-holder">
                <b>{{comments}}</b>
            </div>
            <div class="col-sm-5 ec-no-text-holder" style="margin-left: 12%;">
                <mat-checkbox color="accent" (change)="checkBoxClicked($event)" class="ec-no-text-holder">  
                    <b>Entitlement Matrix successfully printed</b> 
                  </mat-checkbox> 
            </div>
        </div>
        <div class="row d-flex justify-content-around mt-4">
            <div class="col-sm-6">
                <button type="submit" class="btn print-entitlement-btn w-50" (click)="printPage()">Print Entitlement
                    Matrix</button>
            </div>
            <div class="col-sm-4">
                <button type="submit" class="btn submit-btn w-50" (click)="openConfirmationDialog()" [disabled] = "isSubmitBtnEnabled == false">Submit</button>
            </div>
        </div>
    </div>
</div>
<div class="p-2" id="print-section" class="printpage" #printData>
    <div class="">
        <div class="row d-flex justify-content-around">
            <img src="../assets/print-logo.jpeg" class="mx-auto d-block" style="width: 10%; height: 10%;" alt="...">
            <div class="col-sm-10">
                <div class="row offset-sm-2 fw-bold text-center text-dark">
                    <h1 class="heading-text"> ಬೆಂಗಳೂರು ಅಭಿವೃದ್ಧಿ ಪ್ರಾಧಿಕಾರ </h1>
                </div>
                <div class="row fw-bold text-dark">
                    <h1 class="heading-text text-center w-75 ml-4"> Bangalore Development Authority </h1>
                </div>
            </div>
            <div class="row fw-bold text-center text-dark">
                <h2 class="sub-heading-text">ಡಾ||. ಕೆ. ಶಿವರಾಮ ಕಾರಂತ್ ಬಡಾವಣೆಯ ಭೂ ಮಾಲೀಕರಿಗೆ ಬದಲಿ ನಿವೇಶನ ಹಂಚಿಕೆ</h2>
            </div>
        </div>
        <hr class="hr w-100" />
        <div class="container">
            <div class="row justify-content-center">
                <h1 class="blue-tab p-2">ಅಂತಿಮ ಪ್ರತಿ / Final Copy</h1>
            </div>
        </div>

        <div class="pr-4 pl-4">
            <style>
                @import url(http://fonts.googleapis.com/earlyaccess/notosanskannada.css);

                body {
                    font-family: "Nirmala UI", sans-serif;
                    font-size: 19.0px;
                    line-height: 1.11em;
                }
            </style>
        </div>
        <div class="container">
            <div class="row mt-5">
                <label class="pl-4 fw-bold">ಹಕ್ಕು ಪ್ರಮಾಣಪತ್ರ ಸಂಖ್ಯೆ/ EC No : <span> <b> {{ecData ? ecData.ecNo :
                            ''}}</b></span></label>
            </div>
            <div class="row mt-3">
                <label class="pl-4 fw-bold col-sm-6">ಹಳ್ಳಿ / Village : <b> {{ecData ? ecData.villageName : ''}}</b>
                </label>
                <label class="pl-4 fw-bold col-sm-6">ಸರ್ವೆ ನಂ. / Sy. No. : <b> {{ecData ? ecData.surveyNo : ''}}</b>
                </label>
            </div>
            <div class="row mt-3">
                <label class="pl-4 fw-bold">ಖಾತೆದಾರರ ಹೆಸರು / Khatedar Name : <span> <b> {{ecData ? ecData.khatedarName :
                            ''}}</b></span></label>
            </div>
            <div class="row mt-3">
                <label class="pl-4 fw-bold offset-sm-1">Extent (in Sq. Ft.) : <span> <b> {{ecData ? ecData.extent :
                            ''}}</b></span></label>
            </div>
            <div class="row mt-3">
                <label class="pl-4 fw-bold offset-sm-1">Entitled Extent (in Sq. Ft.) : <span> <b> {{ecData ?
                            ecData.entExtent :
                            ''}}</b></span></label>
            </div>
            <div *ngIf="ecData.additionalEntitledExtent">
                <div class="row mt-3">
                    <label class="pl-4 fw-bold offset-sm-1">Additional Entitlement Extent (in Sq.Ft) : <span> <b> {{ecData ?
                                ecData.additionalEntitledExtent :
                                ''}}</b></span></label>
                </div>
                <div class="row mt-3">
                    <label class="pl-4 fw-bold offset-sm-1">New Entitlement Extent (in Sq.Ft) : <span> <b> {{ecData ?
                                ecData.newEntitledExtent :
                                ''}}</b></span></label>
                </div>
            </div>
            <div class="row mt-3">
                <label class="pl-4 fw-bold offset-sm-1">Allotment Extent (in Sq. Ft.) : <span> <b> {{ecData ?
                            ecData.alExtent :
                            ''}}</b></span></label>
            </div>
        </div>

        <div class="row pr-2 pl-2 mt-2">
            <div class="col-sm-12">
                <div class="ml-5 mt-2 mb-1">
                    <span class=" p-2 title">Entitlement Matrix</span>
                </div>
                <table border="1" class="w-100 mt-1 text-center">
                    <tr>
                        <th>Sl.No</th>
                        <th>Dimension (in Sq. Ft.)</th>
                        <th>No. of. Sites</th>
                        <th>Total Dimension (in Sq. Ft.)</th>
                    </tr>
                    <tr *ngFor="let tableData of entitlementMatrixList; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{tableData.dimensionLabel}}</td>
                        <td>{{tableData.noOfSites}}</td>
                        <td>{{tableData.totalDimension}}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>{{'Total'}}</td>
                        <td>{{totalSitesSum}}</td>
                        <td>{{totalDimensionSum}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="ml-4">
            <h4 class=" comments-text">{{comments}}</h4>
        </div>
        <div class="container">
            <div class="row d-flex justify-content-end sign-line-align">
                <h3 class="col-sm-4">ಕಾರ್ಯದರ್ಶಿ, ಬಿ ಡಿ ಎ/ Secretary, BDA</h3>
            </div>
        </div>
        <div class="my-1 footer">
            <h4 class="pt-1">ಈ ದಾಖಲೆ ಸೈಟ್‌ಗಳ ಹಂಚಿಕೆ ಅಥವಾ ಮಾಲೀಕತ್ವವನ್ನು ಸೂಚಿಸುವುದಿಲ್ಲ<br>
                / This document is for representative purposes only and does not indicate allotment or ownership of the
                sites</h4>
        </div>
    </div>
</div>