<div class="container ">
  <app-top-bar selectedTab="home" [heading]="'Edit Application Inward - Workbench'"></app-top-bar>
  <div class=" d-flex justify-content-between font-design">
    <button mat-button class="btn btn-primary mb-1" (click)="clickBack()">Back</button>
    <h2 class="heading-design">Layout: {{userLayoutData['name']}}</h2>
    <div class="row value-text user-details">
      Welcome! <br> {{user['firstName']}}
    </div>
  </div>
</div>
<div class="container">
  <div class="mt-4 row application-form-row">
    <div class="col-sm-6 ">
      <div class="row">
        <div class="col-sm-5 d-flex  align-items-center">
          <label class=" font-design">Application Count :</label>
        </div>
        <div class="col-sm-4 w-25 d-flex text-start align-items-start">
          <input type="number" class="form-textbox" [(ngModel)]="applicationCount" />
        </div>
      </div>
    </div>

    <div class="col-sm-6 d-flex align-items-center ">
      <label class=" font-design ">Source:</label>
      <select class="form-textbox ml-5 w-50" [(ngModel)]="selectedSourceId">
        <option *ngFor="let source of sourceList" [value]="source.bankId">{{source.bankId + '. ' +source.bankName}}
        </option>
      </select>
    </div>

  </div>
  <div class="mt-5">
    <label class="font-design">Batch Name : <b>{{nextBatchName}}</b></label>
  </div>

  <div class="text-center mt-5">
    <button class="btn btn-primary" type="button" (click)="onSubmit()">
      Submit
    </button>
  </div>
</div>