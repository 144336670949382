import {
    HttpHandler,
    HttpRequest,
    HttpEvent,
    HttpErrorResponse,
    HttpInterceptor
  } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { ErrorDialogService } from "../../shared/error/error-dialog.service";
import { ApiLoaderService } from "../../shared/loading/api-loader.service";
import { Injectable } from "@angular/core";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private errorDialogService: ErrorDialogService,
    private apiLoaderService: ApiLoaderService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.apiLoaderService.show();
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error("Error from error interceptor", error);
        this.errorDialogService.openDialog(error.error ?? JSON.stringify(error.error.message), error.status);
        return throwError(error);
      }),
      finalize(() => {
        console.log("http-interceptor finalize()");
        this.apiLoaderService.hide();
      })
    ) as Observable<HttpEvent<any>>;
  }
}