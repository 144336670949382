import moment, { Moment } from "moment"

export class AllotmentTypeConstants {
    
    public static ALLOTMENT_TYPE_LANDLOSER = 'land_loser_allotment';

    public static ALLOTMENT_TYPE_GENERAL = 'general_allotment';

    public static ALLOTMENT_TYPE_LAND_TO_LAND_EXCG = 'land_to_land_exchange_allotment';


    constructor() {}

}