import { HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';

@Component({
  selector: 'app-landloser-letter-printing-activity-log',
  templateUrl: './landloser-letter-printing-activity-log.component.html',
  styleUrls: ['./landloser-letter-printing-activity-log.component.scss']
})
export class LandloserLetterPrintingActivityLogComponent implements OnInit {

  @Input() countData : any;

  @Input() selectedVillageName: string;

  @Input() allotmentType: string;

  @Input() ecData: any;

  tableData: any;

  villageList: any[] = [];

  searchKeyword: string;

  selectedVillageId: number;

  public isRefreshing = false;

  public faSync = faSync;

  public pageSize = 5;

  public currentPage = 0;

  public totalSize: number = 0;

  allotmentTypeId : number = 0;

  public dataSource = new MatTableDataSource<any>();

  public displayedColumns: string[] = ['slNo', 'village', 'ecNo', 'totalAllottedSite', 'ecStatus'];
  printingCount: number;
  printedCount: number;

  constructor(private applicationService: ApplicationService, private cdr: ChangeDetectorRef,
    private router: Router, private route :ActivatedRoute) {
      this.allotmentTypeId = this.route.snapshot.queryParams['allotmentTypeId'];
     }

  ngOnInit(): void {
    console.log('countData', this.countData);
    this.getTableData();
    this.fetchAllVillages();
  }

  fetchAllVillages = () => {
    this.applicationService.fetchAllVillages().subscribe(res => {
      this.villageList = res;
      this.villageList.unshift({
        id: '',
        villageName: 'All'
      })
    });
  }

  villageChanged(villageId: any) {
    if (villageId) {
      let village = this.villageList.find(village => village.id == villageId);
      this.selectedVillageName = village.villageName;
    }
    this.getTableData();
  }

  onClear() {
    this.searchKeyword = null;
  }

  navigateToLetterPrintingWorkbench(ecNumber: string){
    this.router.navigate(['/allotment-letter-printing-workbench'],{queryParams : { ecNo : ecNumber , allotmentTypeId: this.allotmentTypeId}});
  }

  navigateToPrintedLetters(){
    this.router.navigate(['/allotment-workbench'], { queryParams: { allotmentCountType: 'allotment_letter_printing', showPrintedSites: 'true', allotmentTypeId: this.allotmentTypeId } }).then(() => {
      window.location.reload(); //reload on route same page
    });
  }

  getPaginationData(event: any) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getTableData();
  }
  getTableData = () => {
    this.applicationService.fetchEcWisePrintingData(this.currentPage, this.pageSize, this.searchKeyword, this.selectedVillageId).subscribe(res => {
      this.printingCount = res.printingCount;
      this.printedCount = res.printedCount;
      this.tableData = res.pageData;
      this.refreshDataTable(this.tableData);
    });
  }

  private refreshDataTable = (res: any) => {
    this.dataSource.data = res.data;
    this.totalSize = res.totalRecord;
    this.currentPage = (res.currentPage - 1);
    this.dataSource._updateChangeSubscription();
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

  downloadExcelSheet = () => {
    this.applicationService.downloadPrintExcelSheet(this.selectedVillageId, this.searchKeyword);
  }

}
