import moment, { Moment } from "moment"

export class DateTimeUtils {

    private static DEFAULT_DATE_FORMAT:string = "DD/MM/YYYY";

    private static DEFAULT_DATE_TIME_FORMAT:string = "dd/MM/yyyy h:mm";


    constructor() {}

    public static formatDateToStr = (date: Moment) => {
        return date.format(DateTimeUtils.DEFAULT_DATE_FORMAT);
    }

    public static formatDateFromStrToDate = (date: string) => {
        return moment(date).format(DateTimeUtils.DEFAULT_DATE_FORMAT);
    }

    public static formatDateTimeFromStrToDate = (dateTime: string) => {
        return moment(dateTime).format(DateTimeUtils.DEFAULT_DATE_TIME_FORMAT);
    }
}