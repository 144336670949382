import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth.service';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { StrorageService } from 'src/services/strorage.service';
import { UserService } from 'src/services/user-service/user.service';
import { RoleConfigService } from '../shared/config/role/role.config.service';
import { ApplicationService } from 'src/services/application.service';

@Component({
  selector: 'app-online-application-login',
  templateUrl: './online-application-login.component.html',
  styleUrls: ['./online-application-login.component.scss']
})
export class OnlineApplicationLoginComponent implements OnInit {

  email : string;
  otp : number;
  showOtpScreen = false;
  isLoading = false;
  userId: number;
  loginType: string;
  selectedLayoutId:number;
  selectedLayoutName:string;
  layouts: any[] = [];
  timer: number = 60;
  timerFinished: boolean = false; 
  buttonDisabled: boolean = true;
  timerRunning: boolean = false; 
  private interval: any; 

  constructor(private authService: AuthService, private jccEncodeDecoder: JccnoEncodeDecodeService,
     private _snackBar: MatSnackBar, private userService: UserService, private router : Router, 
     private roleConfig: RoleConfigService, private applicationService:ApplicationService, private storageService:StrorageService) { }

  ngOnInit(): void {
    this.fetchLayouts();
   this.loginType = this.roleConfig.ROLE_ONLINE_USER;
  }

  sendOtp = () => {
    try{
      if(!this.email){
        this.openSnackbar('Email field must not be empty', 'Dismiss');
        return;
      }
        this.authService.onlineApplicationGenerateOtp(this.email).subscribe(res =>{
          this.userId = res;
          this.openSnackbar('OTP sent sucessfully!','Dismiss');
            this.showOtpScreen = true;
            this.startTimer();
        })
    }catch (er){
      throw new Error(er.message);
    }
  }

  submitOtp(){
    if(!this.email || !this.otp){
      return;
    }
    this.applicationService.verifyOtp(this.otp, this.userId).subscribe(res=>{;
      console.log(res);
      if(res.success) {
        let layoutData = {
          id: this.selectedLayoutId,
          name: this.selectedLayoutName
        }
        this.storageService.setItem(JSON.stringify(layoutData), 'user_layout');
        this.storageService.saveUser(res.data);
        this.storageService.saveAccessToken(res.data['accessToken']);
        this.openSnackbar('Login Successfully', 'Dismiss');
        this.router.navigate(['/application_login']);
      }else{
        this.openSnackbar(res.message, 'Dismiss');
      }
     })
  }


  fetchLayouts = () => {
    try {
      this.applicationService.getSiteAllotmentLayouts().subscribe(res => {
        this.layouts = res;
      }, err => {
        console.log(err);
        this.openSnackbar(err?.message, "Dismiss");
      })
    } catch (e) {
      this.openSnackbar(e, "Dismiss");
    }
  }

  setLayoutName(layoutId: number) {
    for (let i = 0; i < this.layouts.length; i++) {
      if (this.layouts[i].id == layoutId) {
        this.selectedLayoutName = this.layouts[i].label;
      }
    }
  }

  loginResHandler = (res: any): void => {
    if(res && res.success) {
      this.fetchSavedApplication();
      return;
   }
   this.openSnackbar('Invalid credentials', 'Dismiss');
  }

  fetchSavedApplication(){
     this.authService.fetchOnlineApplicantNo().subscribe(res =>{
       console.log('res ',res)
      this.openSnackbar('Login successful', 'Dismiss');
       if(!res){
        return this.router.navigate(['/application_login']);    
       }
       let docodeNo = this.jccEncodeDecoder.encode(res.applicationNo)
       return  this.router.navigate(['/application_login'], { queryParams: { applicationNo: docodeNo } });
     })
  }

  changeEmail(){
    this.showOtpScreen = false;
  }

  resendOTP(){
    this.sendOtp();
  }

  startTimer() {
    if (!this.timerRunning) { 
      this.timerFinished = false;
      this.buttonDisabled = true;
      this.timer = 60; 
      this.timerRunning = true; 

      this.interval = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          this.timerFinished = true;
          this.buttonDisabled = false;
          clearInterval(this.interval); 
          this.timerRunning = false; 
        }
      }, 1000);
    }
  }

  openSnackbar(message: string, action: string){
    this._snackBar.open(message, action);
  }

  ngOnDestroy() {
    
  }
}
