import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { EcStatusService } from 'src/app/shared/config/status-constants/ec-status.service';
import { ApplicationService } from 'src/services/application.service';
import { EcDetailsContainerComponent } from '../ec-details-container/ec-details-container.component';

@Component({
  selector: 'app-landloser-site-print-entitlement-matrix',
  templateUrl: './landloser-site-print-entitlement-matrix.component.html',
  styleUrls: ['./landloser-site-print-entitlement-matrix.component.scss']
})
export class LandloserSitePrintEntitlementMatrixComponent implements OnInit {

  @ViewChild('printData') printData: ElementRef;

  ecNumbers: string[] = [];

  selectedEcNumber: string;

  @Input() selectedVillageName: string;

  @Input() villageId: number;

  @Input() allotmentType: string;

  ecData: any = {};

  filteredOptions: Observable<string[]>;

  myControl = new FormControl('');

  entitlementMatrixList: any[] = [];


  ecSiteMasterDataList: any[] = [];

  reCalculatedEntitlementMatrixList: any[] = [];

  entitlementMatrixEnabled: boolean = false;

  surveyMstId: any;

  khatedarName: any;
  extent: any;
  entitledExtent: any;
  ecDetailId: any;
  isAllottedExtentGreater: boolean;
  allottedExtent: number = 0;
  comments: string;
  allottedCount: number;
  diffExtent: number;
  totalDimensionSum: number = 0;
  totalSitesSum: number = 0;
  totalAvailableSites: number = 0;
  allotmentTypeId: number = 0;
  allowSiteMapping: any;

  isSubmitBtnEnabled: boolean = false;

  constructor(public dialog: MatDialog, private _snackBar: MatSnackBar,
    private applicationService: ApplicationService, private router: Router,
    private ecStatusConstants: EcStatusService, private route : ActivatedRoute) {
      this.allotmentTypeId = this.route.snapshot.queryParams['allotmentTypeId'];
    }

  ngOnInit(): void {
  }
  parentMethod() {
    this.fetchEcDetails();
  }


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.ecNumbers.filter(option => option.toLowerCase().includes(filterValue));
  }

  openConfirmationDialog = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '190px';
    dialogConfig.width = '450px';
    dialogConfig.data = "Confirm save & submit, Entitlement Matrix for Village: " + this.selectedVillageName + " EC No: " + this.selectedEcNumber
    const dialogRef = this.dialog.open(ConfirmationModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        let payload = this.constructPayload(this.entitlementMatrixList);
        this.applicationService.updateEcStatus(this.ecDetailId, payload).subscribe(res => {
          console.log("res", res);
          this.openSnackbar('Successfully Saved', 'Dismiss');
          this.router.navigate(['/allotment-dashboard']);
        })
      }
    })
  }

  getEcNumbers(keyword: string) {
    if (!keyword) {
      return;
    }
    this.applicationService.searchEcNumbers(keyword, this.villageId, this.ecStatusConstants.EC_PENDING_PROCESSING_CODE).subscribe(res => {
      this.ecNumbers = res;
      this.selectedEcNumber = keyword;
    })
  }

  fetchEcDetails = () => {
    this.applicationService.fetchEcDetails(this.selectedEcNumber).subscribe(res => {
      if (res) {
        this.ecData = res;
        this.diffExtent = res.diffExtent;
        this.comments = res.comments;
        this.ecDetailId = res.ecId;
        this.entitledExtent = res.entExtent;
        this.allottedCount = res.alExtent;
        this.entitlementMatrixList = res.entitledMatrixDataList;
        this.totalSitesSum = res.totalNoOfSites;
        this.totalDimensionSum = res.alExtent;
        this.ecSiteMasterDataList = res.ecSiteMasterDataList;
        this.setSiteDataTotalSiteNos(res.ecSiteMasterDataList);
      }
  })
}

setSiteDataTotalSiteNos(siteList:any[]){
  this.totalAvailableSites = 0;
  for(let i =0; i < siteList.length; i++){
    this.totalAvailableSites = this.totalAvailableSites + siteList[i].noOfSites;
  }
}

reviseEcDetails = (entitledMatrixList: any) => {
  this.applicationService.reviseEcDetails(this.selectedEcNumber, entitledMatrixList).subscribe(res => {
    if(res){
      this.ecData = res;
      this.diffExtent = res.diffExtent;
      this.comments = res.comments;
      this.totalSitesSum = res.totalNoOfSites;
      this.ecDetailId = res.ecId;
      this.entitledExtent = res.entExtent;
      this.totalDimensionSum = res.alExtent;
    }
})
}

calculateDifferenceAndSetComments(ecData:any){
  this.comments = ecData.comments + ' ' + ecData.diffExtent;
  // this.ecData.comments = ecData.comments + ' ' + ecData.diffExtent;
  // this.isAllottedExtentGreater ? this.comments = 'Additional Cash to be paid to BDA for Sq.Ft.' + this.diffExtent : this.comments = 'Cash Compensation for Sq.Ft.'+this.diffExtent;
}

  setSelectedEcNumber(event: any) {
    console.log("event", event);
    this.selectedEcNumber = event.option.value;
    this.fetchEcDetails();
  }

  showEntitlementMatrixWorkBench() {
    this.entitlementMatrixEnabled = true;
  }

  openSnackbar(message: string, action?: string) {
    if (!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }

  constructEntitlementMatrixTable(extent: number) {
    let dimensions = [{ length: 50, breadth: 80, entExtent: 0, noOfSites: 0 }, { length: 40, breadth: 60, entExtent: 0, noOfSites: 0 }, { length: 30, breadth: 50, entExtent: 0, noOfSites: 0 }]
    for (let i = 0; extent <= 54; i++) {
      for (let j = 0; j < dimensions.length; j++) {
        if (extent <= 54) {
          break;
        } else {
          if (extent > dimensions[j].length * dimensions[j].breadth) {
            if (dimensions[j].noOfSites > 0) {
              dimensions[j].noOfSites = dimensions[j].noOfSites + 1;
              continue;
            }
            dimensions[j].entExtent = dimensions[j].length * dimensions[j].breadth;
            dimensions[j].noOfSites = dimensions[j].noOfSites + 1;
            extent = extent - dimensions[j].length * dimensions[j].breadth;
          }
        }
      }
    }
    console.log('dimensionsList', dimensions);
  }

  calculateTotalDimension(entitlementMatrix: any, index: any) {
    console.log(entitlementMatrix);
    let dimensionValue = entitlementMatrix[index].length * entitlementMatrix[index].breadth * entitlementMatrix[index].noOfSites;
    entitlementMatrix[index].totalDimension = dimensionValue;
  }

  noOfSiteChanged(entitledMatrix: any) {
    console.log(entitledMatrix);
    entitledMatrix.overridden = true;
    if (!entitledMatrix.noOfSites && entitledMatrix.noOfSites == "") {
      entitledMatrix.totalDimension = null;
    }
  }

  calculateTotalSitesAndAllottedSum(){
    this.totalDimensionSum = 0;
    this.totalSitesSum = 0;
    for(let i = 0; i < this.entitlementMatrixList.length; i++){
      this.totalDimensionSum = this.totalDimensionSum + this.entitlementMatrixList[i].totalDimension;
      this.totalSitesSum = this.totalSitesSum + this.entitlementMatrixList[i].noOfSites;
    }
    this.ecData.alExtent = this.totalDimensionSum;
  }

  // printEntitlementMatrix = () => {
  //   this.applicationService.downloadPrintEntitlementMatrix(this.ecDetailId, this.constructPayloadForCalculateData(this.entitlementMatrixList));
  // }

  constructPayloadForCalculateData(data: any) {
    let editEntitlementMatrixList: any[] = [];
    for (let i = 0; i < data.length; i++) {
      let payload = {};
      payload['dimensionId'] = data[i].dimensionId;
      payload['dimensionLabel'] = data[i].dimensionLabel;
      payload['length'] = data[i].length;
      payload['breadth'] = data[i].breadth;
      payload['noOfSites'] = data[i].noOfSites;
      payload['totalDimension'] = data[i].totalDimension;
      payload['overridden'] = data[i].overridden;
      payload['recalculated'] = data[i].isRecalculated;
      editEntitlementMatrixList.push(payload);
    }
    this.entitlementMatrixList = editEntitlementMatrixList;
    return editEntitlementMatrixList;
  }

  constructPayload(data: any) {
    let payload = {};
    payload['comments'] = this.comments;
    payload['allotmentTypeId'] = this.allotmentTypeId;
    let mofifiedEntitlementMatrix = [];
    for (let i = 0; i < data.length; i++) {
      let payload = {};
      payload['dimensionLabel'] = data[i].dimensionLabel;
      payload['noOfSites'] = data[i].noOfSites;
      payload['length'] = data[i].length;
      payload['breadth'] = data[i].breadth;
      payload['totalDimension'] = data[i].totalDimension;
      payload['overridden'] = data[i].overridden;
      payload['recalculated'] = data[i].isRecalculated;
      mofifiedEntitlementMatrix.push(payload);
    }
    payload['entitledMatrixAllocationDTOList'] = mofifiedEntitlementMatrix;
    return payload;
  }

  onClickReCalculate(data: any) {
    console.log(this.entitlementMatrixList);
    for(let i = 0; i < data.length; i++) {
        data[i].isRecalculated = true;
    }
    this.reCalculatedEntitlementMatrixList = data;
    let payload = this.constructPayloadForCalculateData(this.entitlementMatrixList)
    this.applicationService.reCalculateEntitlementMatrix(this.ecDetailId, payload).subscribe(res => {
      this.entitlementMatrixList = res;
      for(let i = 0; i < this.entitlementMatrixList.length; i++) {
        for(let j = 0; j < data.length; j++) {
            if(res[i].dimensionLabel == data[j].dimensionLabel) {
              this.entitlementMatrixList[i].isRecalculated = data[j].isRecalculated;
            }
        }
        }  
      this.reviseEcDetails(res);
    })
    console.log(data);
  }

  preparePrintData(source: any) {

    let data = "<html><head><style type='text/css' media='print'>@media print {display:block; @page { size: auto; margin: 0;} body { margin:1.6cm; } }</style><script>function step1(){\n" +
      "setTimeout('step2()', 2);}\n" +
      "function step2(){window.print()?dev=version=1.0.0';window.close()}\n" +
      "</script></head><body onload='step1()'>" + source + '?' + Date.now() + "</body></html>";
    return data;
  }
  printPage() {
    window.print();
  }
  onPrint = () => {
    var printContents = this.printData.nativeElement.innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.close();
  }

  onResetClicked() {
    this.fetchEcDetails();
  }

  checkBoxClicked(event: any) {
    console.log(event.checked);
    if (event.checked) {
        this.isSubmitBtnEnabled = true;
    } else{
      this.isSubmitBtnEnabled = false;
    }
  }

  preventMinusPlus(event: KeyboardEvent) {
    if (event.key === '+' || event.key === '-') {
        event.preventDefault();
    }
}

}
