import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';
import { UserService } from 'src/services/user-service/user.service';

@Component({
  selector: 'app-audit-helpdesk',
  templateUrl: './audit-helpdesk.component.html',
  styleUrls: ['./audit-helpdesk.component.scss']
})
export class AuditHelpdeskComponent implements OnInit {

  public panchayats=[]

  public selectedPanchayat: number;

  public selectedDate: Date;

  public issuedToken: number;

  constructor(private applicationService: ApplicationService, private _snackBar: MatSnackBar, private userService: UserService, private router: Router) {
    if(this.userService.getUserType() != this.userService.AUDIT_HELPDESK_USER){
      this.router.navigate(['/audit-helpdesk-login']);
    }   
   }

  maxDate = new Date();

  ngOnInit(): void {
    // this.fetchAllPanchayats();
  }

  // fetchAllPanchayats(){
  //    this.applicationService.fetchAllPanchayats().subscribe(res =>{
  //      console.log('response ',res)
  //       this.panchayats = res;
  //    })
  // }

  // onSubmit(){
  //   if(!this.selectedPanchayat){
  //     return this.openSnackbar('Please select panchayat', 'Dismiss');
  //   }
  //   if(!this.selectedDate){
  //     return this.openSnackbar('Please select date', 'Dismiss');
  //   }
  //   if(!this.issuedToken){
  //     return this.openSnackbar('Please enter issued tokens', 'Dismiss');
  //   }
   
  //   let payload = {};
  //   payload['panchayatId'] = this.selectedPanchayat;
  //   payload['issuedTokens'] = this.issuedToken;
  //   payload['issuedDate'] = this.selectedDate;
  //    this.applicationService.auditHelpdeskEntry(payload).subscribe(res =>{
  //       this.openSnackbar('Audit saved Successfully', 'Dismiss')
  //       this.router.navigate(['/'])
  //    });
  // }

  openSnackbar(message: string, action: string){
    this._snackBar.open(message, action);
  }
}
