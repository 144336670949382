<div class="col-sm-10 offset-sm-1">
    <app-top-bar selectedTab="home"></app-top-bar>
    <app-header></app-header>

    <div class="col-sm-12 root-container">
        <div class="container">
            <div class="row">
                <div class="col-sm-1 refresh-btn-container">
                    <button class="btn btn-primary" (click)="refreshClick()">
                        <fa-icon [icon]="faSync" [spin]="isRefreshing"></fa-icon>
                    </button>
                </div>
                <div class="col-sm-2 refresh-btn-container">
                    <button class="btn btn-primary" (click)="goBack()">
                        Back
                    </button>
                </div>

                <div class="col-sm-5">
                    <div class="text">
                        <p>Leadership -
                        <div>{{datatableHeading + " Applicants"}} - Summary</div>
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class=" user-details">
                        <div class="value-text">
                            Welcome
                        </div>
                        <div class="value-text">
                            {{user['firstName']}} !
                        </div>
                    </div>
                </div>
                <div class="row" style="margin-top: 21px; margin-left: 1%;">
                    <div class="col-sm-3">
                        <input class="form-control border-end-0 border rounded-pill" [(ngModel)]="searchKeyword" style="font-size: 14px !important;"
                            type="text" placeholder="Search">
                    </div>
                    <div class="input-group-append col-sm-2">
                        <button mat-button class="btn btn-primary " (click)="getApplicantData()">Search</button>
                        <button *ngIf="searchKeyword" mat-button matSuffix mat-icon-button aria-label="Clear" id="searchKeyword" (click)="onClear()">
                        <mat-icon>close</mat-icon>
                        </button>
                        </div>
                    <div class="offset-sm-5 col-sm-1">
                        <div class="p1">
                            Total: {{totalSize}}
                        </div>
                    </div>
                </div>
                <div [hidden]="true" class="col-sm-1 download-xls">
                    <img class="square-icon" src="../../../assets/download_xlsx_icon.png" />
                </div>
                <div class="col-sm-12">
                    <table mat-table [dataSource]="dataSource">

                        <!-- SI No -->
                        <ng-container matColumnDef="siNo">
                            <th mat-header-cell *matHeaderCellDef> SI No. </th>
                            <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}
                        </ng-container>

                        <!-- Applicant Name Column -->
                        <ng-container matColumnDef="acknowledgementNo">
                            <th mat-header-cell *matHeaderCellDef> Acknowledgement No </th>
                            <td mat-cell *matCellDef="let element"> {{element.acknowledgementNo}} </td>
                        </ng-container>

                        <!--  Age Column -->
                        <ng-container matColumnDef="age">
                            <th mat-header-cell *matHeaderCellDef> Age </th>
                            <td mat-cell *matCellDef="let element"> {{element.age}} </td>
                        </ng-container>

                        <!-- Comment Column -->
                        <ng-container matColumnDef="applicationNo">
                            <th mat-header-cell *matHeaderCellDef>Application No</th>
                            <td mat-cell *matCellDef="let element"> {{element.applicationNo}} </td>
                        </ng-container>

                        <!-- Status Column -->
                        <ng-container matColumnDef="applicantName">
                            <th mat-header-cell *matHeaderCellDef>Applicant Name </th>
                            <td mat-cell *matCellDef="let element">{{element.applicantName}}</td>
                        </ng-container>

                        <!-- Village Name Column -->
                        <ng-container matColumnDef="villageName">
                            <th mat-header-cell *matHeaderCellDef> Village Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.villageName}} </td>
                        </ng-container>

                        <!-- Updated Column -->
                        <ng-container matColumnDef="batchName">
                            <th mat-header-cell *matHeaderCellDef> Batch Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.batchName}} </td>
                        </ng-container>

                        <!-- Updated Column -->
                        <ng-container matColumnDef="registrationNo">
                            <th mat-header-cell *matHeaderCellDef> Registration No </th>
                            <td mat-cell *matCellDef="let element"> {{element.registrationNo}} </td>
                        </ng-container>

                        <!-- Alternate site value Column -->
                        <ng-container matColumnDef="formTypeName">
                            <th mat-header-cell *matHeaderCellDef> Form Type </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.formTypeName}} </td>
                        </ng-container>

                        <!-- Observation Document Column -->
                        <ng-container matColumnDef="categoryName">
                            <th mat-header-cell *matHeaderCellDef> Category Type </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.categoryName}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records
                        Found!</div>
                    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"
                        [length]="totalSize" [pageIndex]="currentPage" (page)="getApplicantPaginationData($event)">
                    </mat-paginator>
                </div>
            </div>
            <app-footer class="col-md-10 offset-sm-3"></app-footer>