<div class="container content-area">
    <div class="row">
        <div class="col-sm-9">
<nav class="navbar navbar-expand-sm">
    <ul class="navbar-nav">
        <li class="nav-item" (click)="selectHome()">
            <a class="nav-link" [class.nav-link-selected]="selectedTab == homeTab" 
             [class.nav-link-not-select]="selectedTab != homeTab"
               > HOME </a>
          </li>
    </ul>
  </nav>
</div>
<div class="col-sm-3">
    <div class="row">
        <div class="col-sm-5 ">
    <!-- <button (click)="chooseEnglish()" class="btn" [class.selected-btn]="choosenLanguage == 'en'" [class.non-selected-btn]="choosenLanguage == 'kn'">English</button> -->
</div>
<div class="col-sm-5">
    <!-- <button (click)="chooseKannada()" class="btn kannada-btn"  [class.selected-btn]="choosenLanguage == 'kn'" disabled [class.non-selected-btn]="choosenLanguage == 'en'">ಕನ್ನಡ</button> -->
    
    </div>
    <div class="col-sm-2" [hidden]="!isLoggedIn">
      <img src="../../assets//logout.jpeg" (click)="openLogoutDialog()" class="logout">
    </div>
</div>

</div>
</div>
<div class="row" style="height: 8rem;">
    <div class="title col-sm-9"><label class="header-label">{{heading ? heading : ('top_bar.title' | translate)}}</label></div>
    <div class="col-sm-3">
        <img src="../../assets/bda-logo.png" style="width: 45%; height: 80%;" alt="..."/>
    </div>
</div>
</div>
