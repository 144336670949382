<div class="row content-area">
    <div class="col-sm-10 offset-sm-1">
  <app-top-bar selectedTab="home"></app-top-bar>
  <app-header></app-header>
  <div class="title">
      Welcome to BDA-SITE-ALLOTMENT
      <div class="mt-1"> Online Application Login</div>
  </div>
  <div class="col-sm-6 offset-sm-3">
  <mat-card>
       <div [hidden]="showOtpScreen">
        <div class="col-sm-8 offset-sm-2">
            <div class="row application-form-row ">
                <label class="form-label">Choose Layout </label>
                <select class="form-textbox" [(ngModel)]="selectedLayoutId"
                    (change)="setLayoutName($event.target.value)">
                    <option *ngFor="let layout of layouts" [value]="layout.id">{{layout.label}}</option>
                </select>
            </div>
        </div>
        <div class="col-sm-8 offset-sm-2">
            <div class="row application-form-row ">
                <label class="form-label">Email Address : </label>
                <input type="email" [(ngModel)]="email" class="form-textbox"/>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 offset-sm-3">
                <button class="btn btn-primary login-btn send-otp-btn" (click)="sendOtp()">SEND OTP</button>
            </div>
        </div>
       </div>
       <div [hidden]="!showOtpScreen">
        <div class="col-sm-8 offset-sm-2">
            <div class="row application-form-row ">
                <label class="form-label">Email Address </label>
                <input type="email" [(ngModel)]="email" class="form-textbox" readonly/>
            </div>
        </div>
        <div class="col-sm-8 offset-sm-2">
            <div class="row application-form-row ">
                <label class="form-label">Enter OTP </label>
                <input type="number" [(ngModel)]="otp" class="form-textbox"/>
            </div>
        </div>
        <div class="col-sm-8 offset-sm-2 mt-2">
            <div class="label-btn" (click)="changeEmail()">Change email</div>
            <div class="label-btn">
                <p *ngIf="!timerFinished" style=" color: red;">{{ timer }} seconds</p>
            </div>
            <div *ngIf="timerFinished" class="label-btn" (click)="resendOTP()">Resend OTP</div>
        </div>

        <div class="row">
            <div class="col-sm-6 offset-sm-3">
                <button class="btn btn-primary login-btn submit-otp-btn" (click)="submitOtp()">SUBMIT</button>
            </div>
        </div>
       </div>
  
   

  </mat-card>
</div>
  <app-footer></app-footer>
  <div class="spinner" *ngIf="isLoading">
    <mat-spinner strokeWidth="5" diameter="60"></mat-spinner>
    </div>
    </div>
    </div>

