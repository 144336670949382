<div class="mainpage">
    <div *ngIf="data" fxLayout="row" >
    <mat-card  style="padding-top: 20px !important">
    
    <div class="col-md-12 col-sm-12 details-area">
        <div class="col-md-2 col-sm-2"><mat-icon (click)="printPage()" class="print-icon">print</mat-icon></div>
        <div class="d-flex justify-content-end col-md-10 col-sm-10" style="margin-left: 50%">       
            <button class="btn pre-btn" (click)="onPreviewClick()" style="margin-right: 5px">Preview</button>
            <button class="btn pre-btn" (click)="goDetailsPage(data)" *ngIf="isEnclosureShown" style="margin-right: 5px">Enclosure</button>
            <button class="btn pre-btn one-time-editable" (click)="activateOneTimeEdit()" *ngIf="showOneTimeEditButton" style="margin-right: 5px">One-Time Edit</button>
            <button class="btn pre-btn save-changes" (click)="closeOneTimeEdit()" *ngIf="showSaveChangesBtn">Save Changes</button>
        </div>
    </div>
        <div class="row details-area">
            <div class="col">
                <label class="info-key">Ack No. :</label>
                <label class="info-value">{{data ? data.acknowledgementNo : ''}}</label>
            </div>
            <div class="col">
                <label class="info-key"> Applicant Name :</label>
                <label class="info-value">{{data ? data.applicantName : ''}}</label>
            </div>
            <div class="col">
                <label class="info-key">DOB :</label> 
                <label class="info-value">{{data ? data.dateOfBirth  : ''}}</label> 
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <label class="info-key">Reg No. :</label> 
                <label class="info-value">{{data ? data.registrationNo : ''}}</label> 
            </div>
            <div class="col-sm-3">
                <label class="info-key">Application No.  :</label> 
                <label class="info-value">{{data ? data.applicationNo : ''}}</label> 
            </div>
            
             <div class="col-sm-3">
                <label class="info-key">No of Attempts :</label> 
                <label class="info-value">{{data ? data.previousAttempts : ''}}</label> 
                <br>
                <label class="info-key">(Excluding current)</label>
            </div>
            <div class="col-sm-3">
                <label class="info-key">Age:</label> 
                <label class="info-value">{{data ? data.age : ''}}</label> 
            </div>
            <!-- <div>
                <label class="info-key">Site No :</label>  
                <label class="info-value">{{data ? data.siteNo : ''}}</label>
            </div> -->
           
        </div>
        <div class="row">
            <div class="checkbox-grid">
                <div *ngFor="let enclosure of enclosures; let i = index;" class="checkbox">
                    <mat-checkbox [checked]="enclosure.enclosureUrl != null" disabled><span class="acko-name">{{i+1}}. {{enclosure.enclosureName}}</span></mat-checkbox>
                </div>
            </div>    
        </div>
        <div *ngIf=partlyNotifiedSurveyNo>
            <div style="font-size: 18px;font-family:'Times New Roman';"> <b>The said Property is located on Partially Notified Sy.No. Hence field visit is mandated.</b></div>
        </div>  
        <div class="row btn-row">
                <div class="col-sm-2 offset-sm-9">
                    <button class="btn-primary btn-next" (click)="onDone()">Done</button>
                </div>
        </div>
         
    </mat-card>
  
 </div>
</div>

<!-- section to take print out-->
<div class="p-2" id="print-section" class="printpage" #printData>
    <div class="border border-dark" >
        <div class="fw-bold text-center text-dark">
            <h1>Government of Karnataka</h1>
        </div>
        <div>
            <img src="../assets/print-logo.jpeg" class="rounded mx-auto d-block" style="width: 10%; height: 10%;" alt="...">
        </div>
        <div class="fw-bold text-center text-dark">
            <h1> BANGALORE DEVELOPMENT AUTHORITY</h1>
        </div>  
        <div class="fw-bold text-center text-dark">
            <h2> T. Chowdaiah Road, Kumarapark West, Bangalore - 20</h2>
        </div>
        <div class="container">
            <div class="row justify-content-center">
              <h1 class=" p-2 border border-dark">Acknowledgement Slip</h1>
            </div>
          </div>
        
        <div class="row pr-2 pl-2">
            <div class="col-8 fs-1 "><h1>Notification No: ADM/Allot/Secy/ /</h1></div>
            <div class="col-4 d-flex justify-content-end "><h1>Notification Dt: </h1></div>
          </div>
          <div class="pr-4 pl-4">
                <table class="table border border-dark ">
                    <tbody>
                        <tr class="">
                            <style>@import url(http://fonts.googleapis.com/earlyaccess/notosanskannada.css);
                                body { font-family: "Nirmala UI", sans-serif; font-size: 19.0px; line-height: 1.11em; }</style>
                            <th class=" text-center"style="font-size: 30px;text-align: center;font-family: Tunga;"><h1>Application No./ಅರ್ಜಿ ಸಂಖ್ಯೆ : <br> <b> {{data ? data.applicationNo : ''}}</b></h1> </th>
                            <th class=" text-center" style="font-size: 30px;text-align: center;font-family: Tunga;"><h1>Acknowledgement No./ಸ್ವ ೀಕೃತಿ ಸಂಖ್ಯೆ : <br> <b> {{data ? data.acknowledgementNo : ''}}</b></h1></th>
                          </tr>
                      <tr class="">
                        <th class=""><label class="">Registration No:</label> <b> {{data ? data.registrationNo : ''}}</b>  </th>
                        <th class="" ><label class="">Category: </label> <b> {{data ? data.categoryName : ''}}</b></th>
                      </tr>
                      <tr class="">
                        <th class=""><label class="">Dimension:</label><b> {{data ? data.siteDimensionName : ''}}</b> </th>
                        <th class="" ><label class="">Any Other Allotments: </label><b> {{data ? data.ownsSiteHouse : ''}}</b></th>
                      </tr>
                      <tr class="">
                        <th class=""><label class="">Initial Deposit: </label> <b> {{data ? data.initialDepositAmount : ''}}</b>   </th>
                        <th class="" ><label class="">Application Type: </label><b> {{data ? data.paymentMode : ''}}</b></th>
                      </tr>
                      <tr class="">
                        <th class=""><label class="">Applicant Name: Applicant_name</label> <b> {{data ? data.applicantName : ''}}</b>  </th>
                        <th class="" ><label class="">Father/Husband Name: </label><b> {{data ? data.applicantFatherName : ''}}</b></th>
                      </tr>
                      <tr class="">
                        <th class=""><label class="">DOB: dd/mm/yyyy </label> <b> {{data ? data.dateOfBirth : ''}}</b></th>
                        <th class="" ><label class="">Age: </label><b> {{data ? data.age : ''}}</b></th>
                      </tr>
                      <tr class="">
                        <th><label class="">Applicant Address </label></th>
                        <th><b> {{data ? data.address : ''}}</b></th>
                      </tr>
                      <tr class="">
                        <th class=""><label class="">DD/NEFT/RTGS No:  </label><b> {{data ? data.initialDepositPaymentModeNo : ''}}</b></th>
                        <th class="" ><label class="">Date: </label><b> {{data ? data.initialDepositDate : ''}}</b></th>
                      </tr>
                    </tbody>
                  </table>
          </div>
        <div class="mt-1">
            <label class="pl-4 fw-bold">Site Applied for: <span> <b> {{data ? data.formTypeName : ''}}</b></span></label>
        </div>
        <div class="mt-1">
            <label class="pl-4 fw-bold ">No. of Previous Attempts: <b> {{data ? data.noOfAttempts : ''}}</b> </label>
        </div>
        <div class="row pr-2 pl-2">
            <div class="col-sm-6">
                    <div class="text-center mt-2 mb-1">
                      <span class=" p-2 title">Previous Attempt Details as per Applicant</span>
                  </div>
                <table class="w-100 mt-1 text-center">
                    <tr>
                      <th>Sl.No</th>
                      <th>App. No.</th>
                      <th>Reg. No.</th>
                      <th>Reg Date.</th>
                      <th>Dimension</th>
                      <th>Initial Deposit</th>
                    </tr>
                    <tr *ngFor="let tableData of previousAllotments; let i = index">
                      <td>{{i+1}}</td>
                      <td>{{tableData.registrationNo}}</td>
                      <td>{{tableData.applicationNo}}</td>
                      <td>{{tableData.date}}</td>
                      <td>{{tableData.dimension}}</td>
                      <td>{{tableData.initialDeposit}}</td>
                    </tr>
                  </table>
            </div>
            <div class="col-sm-6">
                
                    <div class="text-center mt-2 mb-1">
                      <span class=" p-1 title">Previous Attempt Details as per EDP DB</span>
                  </div>
                <table class="w-100 mt-1" >
                    <tr>
                      <th>App. No.</th>
                      <th>Reg.No.</th>
                      <th>Applicant Name</th>
                      <th>Dimension</th>

                    </tr>
                    <tr>
                      <td class="p-2"> </td>
                      <td class="p-2"> </td>
                      <td class="p-2"> </td>
                      <td class="p-2"> </td>
                    </tr>
                    <tr>
                      <td class="p-2"> </td>
                      <td class="p-2"> </td>
                      <td class="p-2"> </td>
                      <td class="p-2"> </td>
                    </tr>
                  </table>
            </div>
        </div>
        <div class="ml-4 fw-bold">
            <h1>Supporting Documents</h1>
        </div>
        <div class="pr-4 pl-4">
            <table class="table border border-dark ">
                <tbody>
                  <tr class="">
                    <th class=""><label class="">Domicile Certificate: </label> {{enclosureData.domicileCert ? 'Yes' : 'No'}}  </th>
                    <th class="" ><label class="">Employment Certificate: </label>{{enclosureData.employmentCert ? 'Yes' : 'No'}}</th>
                  </tr>
                  <tr class="">
                    <th class=""><label class="">Caste Certificate:</label> {{enclosureData.casteCert ? 'Yes' : 'No'}} </th>
                    <th class="" ><label class="">Specially Abled Certificate: </label>{{enclosureData.speciallyAbledCert ? 'Yes' : 'No'}}</th>
                  </tr>
                  <tr class="">
                    <th class=""><label class="">IServiceman/Ex-Serviceman Certificate: </label> {{enclosureData.servicemanCert ? 'Yes' : 'No'}}  </th>
                    <th class="" ><label class="">Income Certificate: </label>{{enclosureData.incomeCert ? 'Yes' : 'No'}}</th>
                  </tr>
                </tbody>
              </table>
      </div>
      <div class="border border-right-0 border-left-0 text-center my-1 ">
        <h1 class="pt-1">For Office use only</h1>
      </div>
        </div>
        
</div>