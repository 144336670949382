import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';

@Component({
  selector: 'app-printing-completed-activity-log',
  templateUrl: './printing-completed-activity-log.component.html',
  styleUrls: ['./printing-completed-activity-log.component.scss']
})
export class PrintingCompletedActivityLog implements OnInit {

  @Input() selectedVillageName: string;

  @Input() allotmentType: string;

  @Input() ecData: any;

  villageList: any[] = [];

  searchKeyword: string;

  selectedVillageId:number;

  public isRefreshing = false;

  public faSync = faSync;

  public pageSize = 5;

  public currentPage = 0;

  public totalSize: number = 0;

  public dataSource = new MatTableDataSource<any>();

  public displayedColumns: string[] = ['slNo','village','ecNo','totalAllottedSite','entitledExtent', 'allottedExtent','comments','ecStatus'];
  
  tableData: any;
  printingCount: any;
  printedCount: any;
  allotmentTypeId : number = 0;

  constructor(private applicationService : ApplicationService, private cdr: ChangeDetectorRef,
    private router : Router, private route : ActivatedRoute) {
      this.allotmentTypeId = this.route.snapshot.queryParams['allotmentTypeId'];
     }

  ngOnInit(): void {
    this.fetchAllVillages();
    this.getTableData();
  }

  fetchAllVillages = () => {
    this.applicationService.fetchAllVillages().subscribe(res => {
      this.villageList = res;
      this.villageList.unshift({
        id: '',
        villageName: 'All'
      })
    });
  }

  getTableData = () => {
    this.applicationService.fetchPrintedLettersData(this.currentPage, this.pageSize, this.searchKeyword, this.selectedVillageId).subscribe(res => {
      this.printingCount = res.printingCount;
      this.printedCount = res.printedCount;
      this.tableData = res.pageData;
      this.refreshDataTable(this.tableData);
    });
  }

  navigateToLetterPrinting(){
    this.router.navigate(['/allotment-workbench'], { queryParams: { allotmentCountType: 'allotment_letter_printing' } }).then(() => {
      window.location.reload(); //reload on route same page
    });
  }

  villageChanged(villageId:any) {
    if(villageId){
      let village = this.villageList.find(village => village.id == villageId);
      this.selectedVillageName = village.villageName; 
    } 
    this.getTableData();
  }

  onClear() {
    this.searchKeyword = null;
    // call api ()
  }

  private refreshDataTable = (res: any) => {
    this.dataSource.data = res.data;
    this.totalSize = res.totalRecord;
    this.currentPage = (res.currentPage - 1);
    this.dataSource._updateChangeSubscription();
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

  getPaginationData(event: any) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getTableData();
  }

  downloadExcelSheet = () => {
    this.applicationService.downloadPrintingCompletedExcelSheet(this.selectedVillageId, this.searchKeyword);
  }


}
