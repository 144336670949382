import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { StrorageService } from './strorage.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {


  constructor(private apiService: ApiService, private storageService: StrorageService) { }

  saveApplicationDetails(payload: any): Observable<any> {
    return this.apiService.post('/v1/applicants', payload);
  }

  getApplicationDetailsTemplates(): Observable<any> {
    return this.apiService.get('/v1/templates/applicationDetails');
  }

  getApplicantById(applicantId: number): Observable<any> {
    return this.apiService.get('/v1/applicants/fetchApplicant/' + applicantId);
  }

  getEnclosureListByApplicantId(applicantId: any): Observable<any> {
    return this.apiService.get('/v1/applicants/' + applicantId + '/fetchEnclosures');
  }

  saveApplicantEnclosures(applicantId: string, formData: FormData): Observable<any> {
    return this.apiService.post('/v1/applicants/' + applicantId + '/uploadDocument', formData);
  }

  saveApplicantStatus(applicantId: number): Observable<any> {
    return this.apiService.post('/v1/applicants/' + applicantId + '/saveApplicantStatus');
  }

  getNextBatchName(bankId?: number): Observable<any> {
    let url = '/v1/inwards/nextBatchNo';
    if (bankId) {
      url = url + "?bankId=" + bankId;
    }
    return this.apiService.get(url);
  }

  getBankSources(): Observable<any> {
    return this.apiService.get('/v1/banks');
  }

  saveApplicationInward(payload: any): Observable<any> {
    return this.apiService.post('/v1/inwards', payload);
  }

  getInwardActivityLogs(page?: any, pageSize?: any, sourceId?: number, keyword?: any): Observable<any> {
    if (!page) {
      page = page + 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    let url = '/v1/inwards/activityLog?page=' + page + '&pageSize=' + pageSize;
    if (sourceId) {
      url = url + "&bankId=" + sourceId;
    }
    if (keyword) {
      url = url + "&keyword=" + keyword;
    }
    return this.apiService.get(url);
  }

  getSiteAllotmentLayouts(): Observable<any> {
    return this.apiService.get('/v1/siteAllotmentLayouts');
  }

  generateLoginOtpSms(userId: any): Observable<any> {
    return this.apiService.post('/v1/users/generateLoginOtpSms?userId=' + userId);
  }


  verifyOtp(otp: any, userId: any): Observable<any> {
    return this.apiService.post('/v1/users/verifyOtp?otp=' + otp + '&userId=' + userId);
  }

  getAllInwardBatchName(): Observable<any> {
    return this.apiService.get('/v1/inwards/getAllBatchNames');
  }

  fetchAllApplicantCount(): Observable<any[]> {
    return this.apiService.get('/v1/applicants/applicantsCount');
  }

  getApplicantStatistics(): Observable<any> {
    return this.apiService.get('/v1/applicants/statistics');
  }
  getApplicantCountPerStatus(fromDate: any, toDate: any): Observable<any> {
    let url = '/v1/applicants/applicantCountByDate';
    if (fromDate) {
      url = url + "?fromDate=" + fromDate;
    }
    if (toDate) {
      url = url + "&toDate=" + toDate;
    }
    return this.apiService.get('/v1/applicants/applicantCountByDate');
  }

  updateApplicationInward(inwardId: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/inwards/update/' + inwardId, payload);
  }

  getInwardDataById(inwardId: any): Observable<any> {
    return this.apiService.get('/v1/inwards/' + inwardId);
  }

  fetchAllVillages(): Observable<any> {
    return this.apiService.get('/v1/villages');
  }

  // saveSiteAllotmentEcDetails(payload: any): Observable<any> {
  //   return this.apiService.post('/v1/ecDetails', payload);
  // }

  fetchSiteCountData(countMode?: string, villageId?:number): Observable<any> {
    let url = '/v1/entitledMatrixDimension/getSiteCounts';
    if (!countMode) {
      url = url + '?countMode=mapping';
    } if(countMode)  {
      url = url + '?countMode=' + countMode;
    }
    if(villageId){
      url = url + '&villageId=' + villageId;
    }
    return this.apiService.get(url);
  }

  fetchEcDetails(ecNumber: string): Observable<any> {
    return this.apiService.get('/v1/ecDetails/fetchByEcNumber/' + ecNumber);
  }

  reviseEcDetails(ecNumber: string, payload:any): Observable<any> {
    return this.apiService.put('/v1/ecDetails/reviseEcData/' + ecNumber, payload);
  }

  searchEcNumbers(keyword?: any, villageId?: any, statusCode?: any): Observable<any> {
    let url = '/v1/ecDetails/ecNos';
    let paramsArr = [];
    if (keyword) {
      paramsArr.push("keyword=" + keyword);
    }
    if (villageId) {
      paramsArr.push("villageId=" + villageId);
    }
    if (statusCode) {
      paramsArr.push("statusCode=" + statusCode);
    }
    if (paramsArr.length > 0) {
      url += "?" + paramsArr.join("&");
    }
    return this.apiService.get(url);
  }

  fetchEcWisePrintingData(page?: any, pageSize?: any, keyword?: any, villageId?: any): Observable<any> {
    let url = '/v1/ecDetails/fetchAllotmentLetters';
    if (!page) {
      page = page + 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    url = url + '?page=' + page + '&pageSize=' + pageSize;
    if (keyword) {
      url = url + "&keyword=" + keyword;
    }
    if (villageId) {
      url = url + "&villageId=" + villageId;
    }
    return this.apiService.get(url);
  }

  fetchPrintedLettersData(page?: any, pageSize?: any, keyword?: any, villageId?: any): Observable<any> {
    if (!page) {
      page = page + 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    let url = '/v1/ecDetails/fetchPrintedLetters?page=' + page + '&pageSize=' + pageSize;
    if (keyword) {
      url = url + "&keyword=" + keyword;
    }
    if (villageId) {
      url = url + "&villageId=" + villageId;
    }
    return this.apiService.get(url);
  }

  updateEcStatus(ecDetailId: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/ecDetails/updateStatus/' + ecDetailId, payload);
  }

  fetchAllSiteMasterData(statusCode: any, dimensionId?: any, villageId?:any): Observable<any> {
    let url = '/v1/ecSiteMasters?statusCode=' + statusCode;
    if (dimensionId) {
      url = url + '&dimensionId=' + dimensionId;
    }
    if (villageId) {
      url = url + '&villageId=' + villageId;
    }
    return this.apiService.get(url);
  }
  saveSiteMapping(ecDetailId: any, payload: any): Observable<any> {
    return this.apiService.post('/v1/ecDetails/siteMapping/' + ecDetailId, payload);
  }

  fetchEcPrintingDetails(ecNumber: string): Observable<any> {
    return this.apiService.get('/v1/ecDetails/ecPrinting/' + ecNumber);
  }

  editEntitlementMatrixCalculation(ecDetailId: any, payload: any): Observable<any> {
    return this.apiService.put('/v1/ecDetails/editEntitlementMatrix/' + ecDetailId, payload);
  }

  printEcDetails(ecDetailId: any): Observable<any> {
    return this.apiService.post('/v1/ecDetails/changePrintStatus/' + ecDetailId);
  }

  printSiteAndUpdateStatus(siteNo: any): Observable<any> {
    return this.apiService.put('/v1/siteStatuses/updateStatusToPrinted?siteNo=' + siteNo);
  }

  fetchEcPrintedDetails(ecNumber: string): Observable<any> {
    return this.apiService.get('/v1/ecDetails/ecPrinted/' + ecNumber);
  }

  downloadPrintExcelSheet(villageId?: any, keyword?: any) {
    let url = '/v1/ecDetails/downloadXlsForAllotmentPrinted';
    if (villageId != null && keyword != null) {
      url = url + '?villageId=' + villageId + '&keyword=' + keyword;
    } else {
      if (villageId) {
        url = url + '?villageId=' + villageId;
      }
      if (keyword) {
        url = url + '?keyword=' + keyword;
      }
    }
    this.apiService.downloadBlob(url, 'xlsx');
  }

  downloadPrintingCompletedExcelSheet(villageId?: any, keyword?: any) {
    let url = '/v1/ecDetails/downloadXlsForAllotmentPrintCompleted';
    if (villageId != null && keyword != null) {
      url = url + '?villageId=' + villageId + '&keyword=' + keyword;
    } else {
      if (villageId) {
        url = url + '?villageId=' + villageId;
      }
      if (keyword) {
        url = url + '?keyword=' + keyword;
      }
    }
    this.apiService.downloadBlob(url, 'xlsx');
  }

  // downloadPrintEntitlementMatrix(ecId: any, payload:any) {
  //   let url = '/v1/ecDetails/generatePrintEntitlementPdf';
  //   if (ecId) {
  //     url = url + '?ecId=' + ecId;
  //   }
  //   this.apiService.downloadBlob(url, 'pdf', payload);
  // }

reCalculateEntitlementMatrix(ecDetailId: any, payload: any[]): Observable < any > {
  return this.apiService.post('/v1/ecDetails/reCalculateEntitlementMatrix/' + ecDetailId, payload);
}

fetchActiveStatusCount(villageId?: number): Observable < any > {
  let url = '/v1/ecStatuses';
  if(villageId){
    url = url + '?villageId='+villageId;
  }
  return this.apiService.get(url);
}

fetchSiteStatusCount(villageId:number): Observable < any > {
  let url = '/v1/siteStatuses/aggregatedCount';
  if(villageId){
    url = url + '?villageId='+villageId;
  }
  return this.apiService.get(url);
}

fetchAllotmentTypes(): Observable < any > {
  return this.apiService.get('/v1/allotmentTypes/');
}
validateOnlineApplicantData(): Observable<any> {
  return this.apiService.get('/v1/applicants/validateOnlineApplicantData');
}

fetchApplicantLeadershipData(page:number, pageSize:number, statusCode?:string,searchKeyword?:string): Observable<any> {
  if (!page) {
    page = page + 1;
  }
  if (!pageSize) {
    pageSize = 10;
  }
  let url = '/v1/applicants/fetchApplicantDatatable?page='+page+'&pageSize='+pageSize;
  if(statusCode){
    url = url + '&channelCode='+statusCode;
  }
  if(searchKeyword){
    url = url + '&searchKeyword='+searchKeyword;
  }
  return this.apiService.get(url);
}

fetchSiteMasterStatusData(villageId?: any): Observable<any> {
  let url = '/v1/ecSiteMasters/getSiteMasterByDimension';
  if (villageId) {
      url = url + '?villageId='+ villageId;
  }
   return this.apiService.get(url);
}

updateEcDetailsExtentValue(ecDetailId: any, payload: any): Observable<any> {
  return this.apiService.put('/v1/ecDetails/updateEntitlementValue/'+ecDetailId, payload);
}


}
