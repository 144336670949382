import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-printing-completed-details',
  templateUrl: './printing-completed-details.component.html',
  styleUrls: ['./printing-completed-details.component.scss']
})
export class PrintingCompletedDetails implements OnInit {

  user: any;

  ecData: any = {
    villageName: "test Village",
    ecNo: "EC-1212",
    surveyNo: "12/A",
    khatedarName: "John Doe",
    extent: "1A-20G -8 Anna",
    entExtent: "1220",
    alExtent: "1250",
    comments: "Prepopulated",
    diffExtent: "30"
  };

  siteDataList: any[] = [
    // {
    //   "dimension": "50X80",
    //   "noOfSites": 3,
    //   "siteNo": "S-200",
    // },
    // {
    //   "siteNo": "S-201",
    // },
    // {
    //   "siteNo": "S-202",
    // }
  ];

  public isRefreshing = false;

  public faSync = faSync;
  selectedEcNumber: string;
  entitlementMatrixList : any [] = [];
  entitledExtent: number;
  allottedExtent: number;
  isAllottedExtentGreater: boolean;
  comments : string;

  constructor(private storageService: StrorageService, private router: Router, private applicationService : ApplicationService,
    private route: ActivatedRoute) {
    this.user = this.storageService.getUser();
    this.selectedEcNumber = this.route.snapshot.queryParams['ecNo'];
  }

  ngOnInit(): void {
    this.fetchEcDetails();
  }

  refreshClick() {
    let currentComponent = this;
    this.isRefreshing = true;
    setTimeout(() => {
      currentComponent.isRefreshing = false;
    }, 3000)
  }

  clickBack() {
    window.history.back();
  }

  fetchEcDetails() {
    this.applicationService.fetchEcDetails(this.selectedEcNumber).subscribe(res => {
      if(res){
        this.ecData = res;
        this.entitledExtent = res.entExtent;
        this.entitlementMatrixList = res.entitledMatrixDataList;
        this.getEcPrintingData();
        this.calculateDifferenceAndSetComments();
      }
  })
}

getEcPrintingData = () => {
  this.applicationService.fetchEcPrintedDetails(this.selectedEcNumber).subscribe(res => {
    console.log("printing data", res);
    this.siteDataList = res.siteDataList;
  });
}

calculateDifferenceAndSetComments(){
  let difference = 0;
  let allottedExtentInMetres = 0;
  for(let i = 0; i < this.entitlementMatrixList.length ; i++){
    allottedExtentInMetres = allottedExtentInMetres + this.entitlementMatrixList[i].totalDimension;
  }
  this.allottedExtent = allottedExtentInMetres*10.76;
  difference = this.entitledExtent-this.allottedExtent;
  this.isAllottedExtentGreater = difference >= 0 ? false : true;
  this.isAllottedExtentGreater ? this.comments = 'Additional Cash to be paid to BDA for Sq.Ft.' : this.comments = 'Cash Compensation for Sq.Ft.';
}

}
