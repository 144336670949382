import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EcStatusService {

  readonly EC_PENDING_PROCESSING_CODE: string = 'ec_pending_processing';

  readonly EC_SITE_SUMMARY_DETERMINED_CODE: string = 'ec_site_summary_determined';

  readonly EC_SITE_SITE_ALLOTTED_PENDING_PRINT_CODE: string = 'ec_site_allotted_and_pending_al_print';

  constructor() { }
}
