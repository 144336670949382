<app-top-bar [heading]="'BDA – Site Allotment'"> </app-top-bar>
<div class="container">
    <app-header></app-header>
    <div class="row d-flex justify-content-between mt-4">
        <div class="col-sm-2">
            <button mat-button class="btn btn-primary" (click)="clickBack()">Back</button>
        </div>
        <div class="col-sm-7 title text text-center">
            Site Allotment Workflow – Landloser Module – AL Printed
        </div>
        <div class="col-sm-3 user-details">
            <div class="value-text">
                Welcome {{user['firstName']}} !
            </div>
        </div>
        <button class="col-sm-1 btn btn-primary refresh-btn" (click)="refreshClick()">
            <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
        </button>
    </div>
    <div class="row">
        <app-ec-details-container class="col-sm-12" [ecData]="ecData"></app-ec-details-container>
    </div>
    <div class="row d-flex justify-content-start mt-5">
        <table border="1" class=" col-sm-7">
            <thead>
                <tr>
                    <th>Sl. No.</th>
                    <th>Dimension</th>
                    <th>No. of Sites</th>
                    <th>Site No</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let siteData of siteDataList; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{siteData.dimensionLabel}}</td>
                    <td>{{siteData.noOfSites}}</td>
                    <td>{{siteData.siteNo}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div style="height: 4rem;"></div>
</div>