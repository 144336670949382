import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';
import { faTimes} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-preview-dialog',
  templateUrl: './preview-dialog.component.html',
  styleUrls: ['./preview-dialog.component.scss']
})
export class PreviewDialogComponent implements OnInit {
 applicationNo : any;
 applicationData;
 selectedPanchayat = ''
 public faTimes = faTimes;
  constructor(private applicationService: ApplicationService,private route: ActivatedRoute,public dialogRef: MatDialogRef<PreviewDialogComponent>,  @Inject(MAT_DIALOG_DATA, ) public data) { 
   
    this.applicationNo = data;
    console.log("jcc", this.applicationNo)
  }

  ngOnInit(): void {
    this._fetchDataByApplicationNo();
  }
  private _fetchDataByApplicationNo() {
    // this.applicationService.fetchApplicantByApplicationNo(this.applicationNo).subscribe(res=>{
    //   console.log("app",res);
    //   this.applicationData = res;
    //   this.fetchAllPanchayats();
    //  })
  }
  close() {
    this.dialogRef.close();
  }
  cancelClick(){
    this.close();
  }

  private fetchAllPanchayats(){
    // this.applicationService.fetchAllPanchayats().subscribe(res=>{
    //   if(this.applicationData &&  this.applicationData.panchayat && res){
    //     this.selectedPanchayat = res[this.applicationData.panchayat-1]?.name;
    //   }
    // })
  }
  

}
