import { Component, OnInit } from '@angular/core';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';

@Component({
  selector: 'app-site-matrix-data-view',
  templateUrl: './site-matrix-data-view.component.html',
  styleUrls: ['./site-matrix-data-view.component.scss']
})
export class SiteMatrixDataViewComponent implements OnInit {

  public faSync = faSync;

  public isRefreshing = false;

  siteDataList : any[] = [];

  selectedVillageId: any = '';

  selectedVillageName: string = 'All';

  villageList: any[] = [];

  availableSitesCount: any;

  allottedSitesCount: any;

  constructor(private applicationService:ApplicationService) { }

  ngOnInit(): void {
    this.fetchSiteStatusData();
    this.fetchAllVillages();
  }

  fetchSiteStatusData = () =>{
    this.applicationService.fetchSiteMasterStatusData(this.selectedVillageId).subscribe(res => {
      this.siteDataList = res.siteAndDimensionDataList;
      this.availableSitesCount = res.availableSitesCount;
      this.allottedSitesCount = res.allottedSitesCount;
    })
  }

  fetchAllVillages = () => {
    this.applicationService.fetchAllVillages().subscribe(res => {
      this.villageList = res;
      this.villageList.unshift({
        id:'',
        villageName:'All'
      });
    });
  }

  getVillageIdFromName() {
    if (this.selectedVillageName) {
      let village = this.villageList.find(village => village.villageName == this.selectedVillageName);
      this.selectedVillageId = village.id;
    }
  }

  villageChanged = (villageId: any) => {
    this.selectedVillageId = villageId;
    let village = this.villageList.find(village => village.id == villageId);
    this.selectedVillageName = village.villageName;
    this.getVillageIdFromName();
    this.fetchSiteStatusData();
  }

  refreshClick() {
    let currentComponent = this;
    this.isRefreshing = true;
    this.fetchSiteStatusData();
    setTimeout(() => {
      currentComponent.isRefreshing = false;
    }, 1980)
  }

}
