<app-top-bar class="mainpage"> </app-top-bar>
<div class="container mainpage">
  <app-header></app-header>
  <div class="row d-flex justify-content-between mt-4">
    <div class="col-sm-3">
      <button mat-button class="btn btn-primary" (click)="clickBack()">Back</button>
    </div>
    <div class="col-sm-5 title text text-center">
      {{siteAllotmentCountData ? siteAllotmentCountData.headingText : ''}}
    </div>
    <div class="col-sm-3 user-details">
      <div class="value-text">
        Welcome {{user['firstName']}} !
      </div>
    </div>
  </div>
  <div class="row d-flex justify-content-between mt-2">
    <div class="col-sm-1">
      <button class="btn btn-primary refresh-btn" (click)="refreshClick()">
        <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
      </button>
    </div>
    <div *ngIf="selectedVillageId" class="col-sm-4 text-center">
      <h2>Village: <b>{{selectedVillageName}}</b></h2>
    </div>
    <div class="col-sm-3">
      <mat-form-field>
        <mat-label>Village </mat-label>
        <mat-select id="Villages" name="Villages" [(ngModel)]="selectedVillageId"
          (selectionChange)="villageChanged(selectedVillageId)">
          <mat-option *ngFor="let village of villageList" [value]="village.id">{{village.id+'
            '+village.villageName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="siteAllotmentCountData" class="row mt-2 justify-content-center">
    <div class="col mt-4">
      <app-allotment-count-container
        [countData]="currentCountData" [dimensionCounts]="currentCountList"></app-allotment-count-container>
    </div>
    <div style="height: 16rem; width: 1px; background-color: rgb(165, 164, 164);"></div>
    <div class="col mt-4">
      <app-allotment-count-container
        [countData]="completedCountData" [dimensionCounts]="completedCountList"></app-allotment-count-container>
    </div>
    <div *ngIf="printedCountData" style="height: 16rem; width: 1px; background-color: rgb(165, 164, 164);"></div>
    <div *ngIf="printedCountData" class="col mt-4">
      <app-allotment-count-container
        [countData]="printedCountData" [dimensionCounts]="printedCountList"></app-allotment-count-container>
    </div>
  </div>
</div>
<div class="container">
  <app-landloser-site-print-entitlement-matrix *ngIf="allotmentMode == 'commence_site_allotment'"
    [selectedVillageName]="selectedVillageName" [villageId]="selectedVillageId"
    [allotmentType]="'site_allotment'"></app-landloser-site-print-entitlement-matrix>
  <app-landloser-site-mapping-workbench *ngIf="allotmentMode == 'site_mapping'"
    [selectedVillageName]="selectedVillageName" [villageId]="selectedVillageId"
    [allotmentType]="'site_allotment'"></app-landloser-site-mapping-workbench>
  <app-landloser-letter-printing-activity-log *ngIf="allotmentMode == 'print_allotment_letter'"
    [selectedVillageName]="selectedVillageName"
    [allotmentType]="'allotment_letter_printing'"></app-landloser-letter-printing-activity-log>
  <app-printing-completed-activity-log *ngIf="allotmentMode == 'printing_activity'"
    [selectedVillageName]="selectedVillageName"
    [allotmentType]="'allotment_letter_printing'"></app-printing-completed-activity-log>
  <app-footer></app-footer>
</div>