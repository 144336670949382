<app-top-bar [heading]="'BDA – Site Allotment'"> </app-top-bar>
<div class="container   ">
    <app-header></app-header>
    <div class="row mt-4">
        <div class="col-sm-1">
            <button mat-button class="btn btn-primary" (click)="clickBack()">Back</button>
        </div>
        <div class="col-sm-1">
            <button class=" w-75 btn btn-primary refresh-btn" (click)="refreshClick()">
                <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
            </button>
        </div>
        <div class="col-sm-7">
            <h1 class="w-100 ml-5 text-center heading-text">Active Status Dashboard</h1>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-sm-9">
            <h2 class="w-100 ml-5 heading-text">Received Applications</h2>
        </div>
    </div>
    <div class="row d-flex justify-content-start">
        <div class="col-sm-4 my-4 d-flex justify-content-center">
            <div class="status-tile" [style.background-color]="ecColors[0]">
                {{'Total Applications '}}({{applicantCount}})
            </div>
        </div>
        <div class="col-sm-4 my-4 d-flex justify-content-center">
            <div class="status-tile hl-pointer" [style.background-color]="ecColors[1]" (click)="navigateDataTable('hd')">
                {{'Applications - Helpdesk '}}({{helpdeskApplicantCount}})
            </div>
        </div>
        <div class="col-sm-4 my-4 d-flex justify-content-center">
            <div class="status-tile hl-pointer" [style.background-color]="ecColors[2]" (click)="navigateDataTable('online')">
                {{'Applications - Online '}}({{onlineApplicantCount}})
            </div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-sm-9">
            <h2 class="w-100 ml-5 heading-text">Ec Status</h2>
        </div>
        <div class="col-sm-3">
            <mat-form-field>
                <mat-label>Village </mat-label>
                <mat-select id="Villages" name="Villages" [(ngModel)]="villageId" (selectionChange)="onVillageChange()">
                    <mat-option *ngFor="let village of villageList" [value]="village.id">{{village.id+'
                        '+village.villageName}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="row d-flex justify-content-start">
        <div *ngFor="let ecCountData of ecCountDataList; let i = index"
            class="col-sm-4 my-4 d-flex justify-content-center">
            <div class="status-tile" [style.background-color]="ecColors[i]">
                {{ecCountData.statusName+' '}}({{ecCountData.count}})
            </div>
        </div>
    </div>

    <div class="row mt-3">
        <div class="ml-5 col-sm-8">
            <h1 class="w-100 heading-text">Site Status</h1>
        </div>
    </div>

    <div class="row d-flex justify-content-between">
        <div *ngFor="let countData of siteCountList; let i = index" class="col-sm-4 my-4 d-flex justify-content-center">
            <div class="status-tile" [style.background-color]="siteColors[i]">
                {{countData.statusName+' '}}({{countData.count}})
            </div>
        </div>
    </div>

</div>