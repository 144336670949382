import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ApplicationService } from 'src/services/application.service';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { DateTimeUtils } from 'src/app/utils/date-utils';
import { Router } from '@angular/router';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-application-inward-activity-log',
  templateUrl: './application-inward-activity-log.component.html',
  styleUrls: ['./application-inward-activity-log.component.scss']
})
export class ApplicationInwardActivityLogComponent implements OnInit {

  public faSync = faSync;

  public dataSource = new MatTableDataSource<any>();

  public sourceList : any[] = [];

  public pageSize = 5;

  public currentPage = 0;

  public totalSize: number = 0;

  public searchKeyword : any;

  public selectedSourceId : number;

  isRefreshing: boolean;

  totalApplicantCount: any;

  userLayoutData:any;

  constructor(private applicationService: ApplicationService, private storageService: StrorageService,
    private _snackBar: MatSnackBar,private cdr: ChangeDetectorRef,private router: Router) { }

  ngOnInit(): void {
    this.userLayoutData = this.storageService.getUserLayoutData();
    this.fetchBankSources();
    this.getActivityLogs();
  }
  public displayedColumns: string[] = ['slNo','batchName','batchSize','sourceName','inwardDateTime','processedBy', 'action'];

  onClear() {
    this.searchKeyword = null;
    this.getActivityLogs();
  }

  getActivityLogs() {
    this.applicationService.getInwardActivityLogs(this.currentPage, this.pageSize, this.selectedSourceId, this.searchKeyword).subscribe(res => {
      console.log('res', res);
      this.refreshDataTable(res);
    })
  }

  fetchBankSources = () => {
    this.applicationService.getBankSources().subscribe(res =>{
    this.sourceList = res;
    this.sourceList.unshift({
      bankId: '',
      bankName: 'All'
    });
    })
  }

  getPaginationData(event: any) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getActivityLogs();
  }

  formatDateTime(date: any){
    return DateTimeUtils.formatDateTimeFromStrToDate(date);
  }

  private refreshDataTable = (res: any) => {
    this.dataSource.data = res.data;
    this.totalSize = res.totalRecord;
    this.totalApplicantCount = res.data[0] ? res.data[0].totalApplicationCount : 0;
    this.currentPage = (res.currentPage - 1);
    this.dataSource._updateChangeSubscription();
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

  refreshClick() {
    let currentComponent = this;
    this.isRefreshing = true;
    setTimeout(() => {
      currentComponent.isRefreshing = false;
    }, 3000)
    this.getActivityLogs();
  }

  clickBack() {
    this.router.navigate(['/application-inward-workbench']);
  }

  editInward(inwardId :any) {
    this.router.navigate(['/edit-application-inward-workbench'], {queryParams : { inwardId: inwardId }});
  }

}
