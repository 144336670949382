import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { RoleConfigService } from 'src/app/shared/config/role/role.config.service';
import { ApplicationService } from 'src/services/application.service';

@Component({
  selector: 'app-active-status-dashboard',
  templateUrl: './active-status-dashboard.component.html',
  styleUrls: ['./active-status-dashboard.component.scss']
})
export class ActiveStatusDashboardComponent implements OnInit {

  constructor(private applicationService: ApplicationService, private roleConfig:RoleConfigService,
    private router:Router) { }

  ecCountDataList: any[] = [];

  siteCountList: any[] = [];

  ecColors: string[] = ['#479c9d', '#e39e83', '#3c4d93', '#96c180', '#e49c64', '#a9973f', '#671819'];

  siteColors: string[] = ['#479c9d', '#e39e83', '#3c4d93', '#96c180', '#e49c64', '#a9973f', '#671819'];

  public isRefreshing = false;

  villageList: any[] = [];

  villageId: number;

  public faSync = faSync;

  applicantCount: number;

  helpdeskApplicantCount: number;

  onlineApplicantCount: number;

  ngOnInit(): void {
    this.fetchAllVillages();
    this.fetchActiveStatusCount();
    this.fetchSiteStatusCount();
    this.getApplicantStatistics();
  }

  fetchActiveStatusCount = () => {
    this.applicationService.fetchActiveStatusCount(this.villageId).subscribe(res => {
      this.ecCountDataList = res;
      this.recycleEcColorsList();
    })
  }

  fetchSiteStatusCount = () => {
    this.applicationService.fetchSiteStatusCount(this.villageId).subscribe(res => {
      this.siteCountList = res;
      this.recycleSiteColorsList();
    })
  }


  refreshClick() {
    let currentComponent = this;
    this.isRefreshing = true;
    this.fetchActiveStatusCount();
    this.fetchSiteStatusCount();
    this.getApplicantStatistics();
    setTimeout(() => {
      currentComponent.isRefreshing = false;
    }, 3000)
  }

  fetchAllVillages = () => {
    this.applicationService.fetchAllVillages().subscribe(res => {
      this.villageList = res;
      this.villageList.unshift({
        id: '',
        villageName: 'All'
      });
    });
  }

  onVillageChange() {
    this.fetchActiveStatusCount();
    this.fetchSiteStatusCount();
  }

  clickBack() {
    window.history.back();
  }

  navigateDataTable(applicantChannel:string){
    if(applicantChannel == this.roleConfig.CHANNEL_HELPDESK){
      this.router.navigate(['/leadership-applicant-datatable'],{queryParams:{channelCode: this.roleConfig.CHANNEL_HELPDESK}})
    }
    if(applicantChannel == this.roleConfig.CHANNEL_ONLINE){
      this.router.navigate(['/leadership-applicant-datatable'],{queryParams:{channelCode: this.roleConfig.CHANNEL_ONLINE}})
    }
  }

  recycleEcColorsList = () => {
    let colorIdx = 0;
    for (let i = 0; i < this.ecCountDataList.length; i++) {
      if (colorIdx >= this.ecColors.length) {
        colorIdx = 0;
      }
      if (i >= this.ecColors.length) {
        this.ecColors.push(this.ecColors[colorIdx]);
        colorIdx++;
      }
    }
  }

  recycleSiteColorsList = () => {
    let colorIdx = 0;
    for (let i = 0; i < this.siteCountList.length; i++) {
      if (colorIdx >= this.siteColors.length) {
        colorIdx = 0;
      }
      if (i >= this.siteColors.length) {
        this.siteColors.push(this.siteColors[colorIdx]);
        colorIdx++;
      }
    }
  }

  getApplicantStatistics = () => {
    this.applicationService.getApplicantStatistics().subscribe(res => {
      this.applicantCount = res.totalCount;
      this.helpdeskApplicantCount = res.helpdeskApplicantCount;
      this.onlineApplicantCount = res.onlineApplicantCount;
    })
  }

}
