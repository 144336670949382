<div class="mainpage">
    <hr class="mt-4 hr" />
    <div *ngIf="siteMappingEnabled" class="row d-flex justify-content-start mt-3">
        <div class="form-label offset-sm-1 my-3">
            Enter EC No :
        </div>
        <form class="ml-2 col-sm-4 w-25">
            <mat-form-field class="w-100 mat-form-resize" appearance="outline">
                <input type="text" class="form-textbox" matInput [formControl]="myControl" [matAutocomplete]="auto"
                    (input)="getEcNumbers($event.target.value)">
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setSelectedEcNumber($event)">
                    <mat-option *ngFor="let ecNumber of ecNumbers" [value]="ecNumber">
                        {{ecNumber}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </form>
    </div>
    <div *ngIf="selectedEcNumber">
        <app-ec-details-container [ecData]="ecData"></app-ec-details-container>
        <hr class="mt-4 hr" />
        <div class="row d-flex justify-content-start">
            <div class="col-sm-6 ec-no-text-holder">
                <b>Entitlement Matrix</b>
            </div>
        </div>
        <div class="row d-flex justify-content-start">
            <table border="1" class="mt-2 col-sm-7">
                <thead>
                    <tr>
                        <th>Sl. No.</th>
                        <th>Dimension</th>
                        <th>No. of Sites</th>
                        <th>Total Dimension</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let entitlementMatrix of entitlementMatrixList; let i = index">
                        <td>{{ i+1 }}</td>
                        <td><input type="text" class="borderless readonly-td" [(ngModel)]="entitlementMatrix.dimensionLabel"
                                readOnly /></td>
                        <td><input type="number" class="borderless readonly-td" [(ngModel)]="entitlementMatrix.noOfSites"
                                readOnly /></td>
                        <td><input type="text" class="borderless readonly-td" [(ngModel)]="entitlementMatrix.totalDimension"
                                readOnly /></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td><input type="text" [value]="'Total'" class="borderless readonly-td" readOnly /></td>
                        <td><input type="text" class="borderless readonly-td" [(ngModel)]="totalSitesSum" readOnly /></td>
                        <td><input type="text" class="borderless readonly-td" [(ngModel)]="totalDimensionSum" readOnly />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row d-flex justify-content-start mt-4">
            <div class="col-sm-5 ec-no-text-holder">
                <b class="text-danger">Comments : {{ecData.comments}}</b>
            </div>
        </div>
        <hr class="mt-4 hr" />
        <div class="row d-flex justify-content-start mt-5">
            <div class="col-sm-6 ec-no-text-holder">
                <b>Site Mapping</b>
            </div>
        </div>
        <app-ec-details-container [ecData]="ecData"></app-ec-details-container>
        <div class="row d-flex justify-content-start">
            <table border="1" class="mt-3 col-sm-10">
                <thead>
                    <tr class="text-center">
                        <th style="width: 3%;"></th>
                        <th style="width: 8%;">Dimension (in Sq. Ft.)</th>
                        <th style="width: 6%;">No. of Sites</th>
                        <th style="width: 35%;">Chosen Sites </th>
                        <th style="width: 2%;">Action </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let siteMap of siteMappingList; let i = index">
                        <td class="text-center">
                            <mat-checkbox type="radio" color="primary" class="borderless"
                                (change)="setSiteMapping(siteMap,i)" [(ngModel)]="siteMap.checked"
                                [disabled]="siteMap.noOfSites == 0"></mat-checkbox>
                        </td>
                        <td class="text-center"><input type="text" class="borderless readonly-td"
                                [(ngModel)]="siteMap.dimensionLabel" readOnly /></td>
                        <td class="text-center"><input type="number" class="borderless readonly-td"
                                [(ngModel)]="siteMap.noOfSites" readOnly /></td>
                        <td><input type="text" class="scroll-cell borderless readonly-td" [(ngModel)]="siteMap.siteNo"
                                readOnly /></td>
                        <td class="text-center"><img src="../../assets/refresh_img.png" height="25" width="25"
                                style="margin-left: 7px;" (click)="onRefreshClicked(siteMap)"
                                *ngIf="siteMap.hasSiteMappingSelected"></td>
    
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row mt-2">
            <div class="offset-sm-8 col-sm-1">
                <button type="button" class="btn btn-bg-blue" (click)="printPage()">
                    Print
                </button>
            </div>
        </div>
        <div class="row d-flex justify-content-start mt-4">
            <div class="col-sm-5 ec-no-text-holder">
                <b class="text-danger">Comments : {{ecData.comments}}</b>
            </div>
        </div>
        <div class="row d-flex justify-content-between mt-5 ec-no-text-holder">
            <div class="col-sm-4">Dimension : {{selectedSiteMap.dimensionLabel}}</div>
            <div class="col-sm-3">Available : {{selectedSiteMap.availableCount}}</div>
            <div class="col-sm-3">Entitled : {{selectedSiteMap.noOfSites}}</div>
            <div class="col-sm-2">Allotted :{{selectedSiteMap.allottedCount}} </div>
        </div>
    
        <div class="row d-flex justify-content-between mt-5">
            <label class="col-sm-2 dropdown-label">Choose Site No.: </label>
            <select class="col-sm-4 dropdown-align w-25" [(ngModel)]="selectedSiteId"
                (click)="setSelectedSiteData($event.target.value)">
                <option value="" disabled selected>Select Site No</option>
                <option *ngFor="let site of siteList" [value]="site.siteMstId">{{site.siteNo}}</option>
            </select>
        </div>
        <div class="col-sm-5 ec-no-text-holder mt-5" style="margin-left: 32%;">
            <mat-checkbox color="accent" (change)="checkBoxClicked($event)" class="ec-no-text-holder">  
                <b>Site Mapping successfully printed</b> 
              </mat-checkbox> 
        </div>
        <div *ngIf="hasChoosenAllSites" class="row d-flex justify-content-center mt-5">
            <button class="btn btn-bg-orange col-sm-2 w-75" (click)="openDialog()" [disabled] = "isSubmitBtnEnabled == false">Save & Submit</button>
        </div>
    </div>
</div>

<!-- print section -->
<div class="p-2" id="print-section" class="printpage" #printData>
    <div class="">
        <div class="row d-flex justify-content-around">
            <img src="../assets/print-logo.jpeg" class="mx-auto d-block" style="width: 10%; height: 10%;" alt="...">
            <div class="col-sm-10">
                <div class="row offset-sm-2 fw-bold text-center text-dark">
                    <h1 class="heading-text"> ಬೆಂಗಳೂರು ಅಭಿವೃದ್ಧಿ ಪ್ರಾಧಿಕಾರ </h1>
                </div>
                <div class="row fw-bold text-dark">
                    <h1 class="heading-text text-center w-75 ml-4"> Bangalore Development Authority </h1>
                </div>
            </div>
            <div class="row fw-bold text-center text-dark">
                <h2 class="sub-heading-text">ಡಾ||. ಕೆ. ಶಿವರಾಮ ಕಾರಂತ್ ಬಡಾವಣೆಯ ಭೂ ಮಾಲೀಕರಿಗೆ ಬದಲಿ ನಿವೇಶನ ಹಂಚಿಕೆ</h2>
            </div>
        </div>
        <hr class="hr w-100" />
        <div class="container">
            <div class="row justify-content-center">
                <h1 class="blue-tab p-2">ಅಂತಿಮ ಪ್ರತಿ / Final Copy</h1>
            </div>
        </div>

        <div class="pr-4 pl-4">
            <style>
                @import url(http://fonts.googleapis.com/earlyaccess/notosanskannada.css);

                body {
                    font-family: "Nirmala UI", sans-serif;
                    font-size: 19.0px;
                    line-height: 1.11em;
                }
            </style>
        </div>
        <div class="container">
            <div class="row mt-5">
                <label class="pl-4 fw-bold">ಹಕ್ಕು ಪ್ರಮಾಣಪತ್ರ ಸಂಖ್ಯೆ/ EC No : <span> <b> {{ecData ? ecData.ecNo :
                            ''}}</b></span></label>
            </div>
            <div class="row mt-3">
                <label class="pl-4 fw-bold col-sm-6">ಹಳ್ಳಿ / Village : <b> {{ecData ? ecData.villageName : ''}}</b>
                </label>
                <label class="pl-4 fw-bold col-sm-6">ಸರ್ವೆ ನಂ. / Sy. No. : <b> {{ecData ? ecData.surveyNo : ''}}</b>
                </label>
            </div>
            <div class="row mt-3">
                <label class="pl-4 fw-bold">ಖಾತೆದಾರರ ಹೆಸರು / Khatedar Name : <span> <b> {{ecData ? ecData.khatedarName :
                            ''}}</b></span></label>
            </div>
            <div class="row mt-3">
                <label class="pl-4 fw-bold offset-sm-1">Extent (in Sq. Ft.) : <span> <b> {{ecData ? ecData.extent :
                            ''}}</b></span></label>
            </div>
            <div class="row mt-3">
                <label class="pl-4 fw-bold offset-sm-1">Entitled Extent (in Sq. Ft.) : <span> <b> {{ecData ?
                            ecData.entExtent :
                            ''}}</b></span></label>
            </div>
            <div *ngIf="ecData.additionalEntitledExtent">
                <div class="row mt-3">
                    <label class="pl-4 fw-bold offset-sm-1">Additional Entitlement Extent (in Sq.Ft) : <span> <b> {{ecData ?
                                ecData.additionalEntitledExtent :
                                ''}}</b></span></label>
                </div>
                <div class="row mt-3">
                    <label class="pl-4 fw-bold offset-sm-1">New Entitlement Extent (in Sq.Ft) : <span> <b> {{ecData ?
                                ecData.newEntitledExtent :
                                ''}}</b></span></label>
                </div>
            </div>
            <div class="row mt-3">
                <label class="pl-4 fw-bold offset-sm-1">Allotment Extent (in Sq. Ft.) : <span> <b> {{ecData ?
                            ecData.alExtent :
                            ''}}</b></span></label>
            </div>
        </div>

        <div class="row pr-2 pl-2 mt-2">
            <div class="col-sm-12">
                <div class="ml-5 mt-2 mb-1">
                    <span class=" p-2 title">Site Mapping</span>
                </div>
                <table border="1" class="w-100 mt-1 text-center">
                    <tr>
                        <th style="width: 4%;"></th>
                        <th style="width: 10%;">Dimension (in Sq. Ft.)</th>
                        <th style="width: 5%;">No. of. Sites</th>
                        <th style="width: 30%;">Choosen Sites</th>
                    </tr>
                    <tr *ngFor="let siteMap of siteMappingList; let i = index">
                        <td>
                            <input type="checkbox" color="primary" [disabled]="siteMap.noOfSites == 0" [(ngModel)]="siteMap.checked"/>
                        </td>
                        <td>{{siteMap.dimensionLabel}}</td>
                        <td>{{siteMap.noOfSites}}</td>
                        <td>{{siteMap.siteNo}}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>{{'Total'}}</td>
                        <td>{{totalSitesSum}}</td>
                        <td></td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="container">
            <div class="row d-flex justify-content-end sign-line-align">
                <h3 class="col-sm-4">ಕಾರ್ಯದರ್ಶಿ, ಬಿ ಡಿ ಎ/ Secretary, BDA</h3>
            </div>
        </div>
        <div class="my-1 footer">
            <h4 class="pt-1">ಈ ದಾಖಲೆ ಸೈಟ್‌ಗಳ ಹಂಚಿಕೆ ಅಥವಾ ಮಾಲೀಕತ್ವವನ್ನು ಸೂಚಿಸುವುದಿಲ್ಲ<br>
                / This document is for representative purposes only and does not indicate allotment or ownership of the
                sites</h4>
        </div>
    </div>
</div>