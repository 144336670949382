<div class="content-area col-sm-10 offset-sm-1">
    <div class="headerpage col-sm-12">
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>
        <div class="row header">

            <div class="col-sm-6">
                <h1 class="display-4 header-text" style="margin-top: 10px;">Application Form
                    <!-- <span class="heading-title"> {{heading}} </span> -->
                </h1>
                <!-- <label class="info-title" >Applications without Documents will not be Considered.</label>
                    <label class="info-message"><b>PLEASE UPLOAD RELEVANT DOCUMENTS.</b></label> -->
            </div>
            <div class="col-sm-2 offset-sm-4 user-details">
                <div>
                    <div class="row value-text">
                        Welcome! <br> {{user['firstName']}}
                    </div>
                </div>
                <!-- <div>
                    <div class="row key-text">
                        Application ID
                    </div>
                    <div class="row value-text">
                        {{applicationId ? applicationId : 'Not Created' }}
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <!-- <div>
            <label style="font-size: 22px;font-family: Montserrat;font-weight: bold;">Channel : {{applicationType}}</label>
        </div> -->
</div>
<div class="row w-100 d-flex justify-content-between">
    <div class="col-sm-5 mx-5">
        <!-- <div class="row">
                    <div class="col-sm-3 d-flex justify-content-end" style="font-size: 17px;">
                        Status :
                    </div>
                    <div class="col-sm-5 p-0 value-texts">
                        <span style="color:#25D69C">{{status}}</span>
                    </div>
                </div> -->
    </div>
</div>
<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5rem">
    <div *ngFor="let menu of menus" (click)="openSelectedMenu(menu)">
        <mat-card [class.form-selected-card]="menu.active" [class.form-not-selected-card]="!menu.active">
            {{menu.name}}
        </mat-card>
    </div>
</div>

<!-- new code-->
<div *ngIf="selectedMenu.showMatCard">
    <mat-card class="jcc-card form-card">
        <div class="form-main-title">
            {{selectedMenu.name}}
        </div>
        <div fxLayout="row">
            <app-application-details *ngIf="selectedMenu['code'] == 'application_details'" fxFlex="100%"
                (newApplicantCallback)="updateUI($event)"
                (nextScreenCallback)="nextScreenCallback($event)"></app-application-details>
        </div>
    </mat-card>
</div>
<div fxLayout="row" class="d-flex justify-content-center w-100" *ngIf="!selectedMenu.showMatCard">
    <app-upload-enclosure *ngIf="selectedMenu['code'] == 'enclosures'" [applicantId]="applicantId" fxFlex="80%"
        (nextScreenCallback)="nextScreenCallback($event)"></app-upload-enclosure>
    <app-acknowledgement *ngIf="selectedMenu['code'] == 'acknowledgement'" fxFlex="100%"
        (oneTimeEditCallback)="oneTimeEditCallback($event)"
        (saveChangesCallback)="nextScreenCallback($event)"></app-acknowledgement>
</div>
<!-- end -->
<app-footer></app-footer>