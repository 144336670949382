import moment, { Moment } from "moment"

export class CommonDataUtils {

    private static TODAY_DATE:Moment = moment();

    private static EWS_SHORT_NAME = 'EWS';

    private static SCST_SHORT_NAME = 'SC/ST';

    private static GEN_SHORT_NAME = 'GEN';
    
    public static COUNT_MODE_SITE_MAPPING = 'mapping';

    public static COUNT_MODE_PRINTING = 'printing';

    constructor() {}

    public static calculateAgeInYears (date:any) {
        const momentDate = moment(date);
        return CommonDataUtils.TODAY_DATE.diff(momentDate, 'years');
    }


  public static fetchApplicationNoPrefix(label:string){
    if(label.includes('EWS')){
        return CommonDataUtils.EWS_SHORT_NAME + ' ';
    }
    if(label.includes('SC/ST & Cat-1')){
        return CommonDataUtils.SCST_SHORT_NAME+ ' ';
    }
    if(label.includes('General Category')){
        return CommonDataUtils.GEN_SHORT_NAME+ ' ';
    }
  }

}