<app-top-bar> </app-top-bar>
<div class="container">
    <app-header></app-header>
    <div class="my-5">
        <div class="row"> 
            <!-- #00b050 -->
            <button class="btn btn-primary col-sm-1" style="height:2.4rem" (click)="refreshClick()">
                <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
            </button>
            <div class="offset-sm-1 col-sm-2">
                <button (click)="navigateToActiveStatusPage()" class="status-btn ml-4">
                    Active Status
                </button>
            </div>
            <div class="ml-4 col-sm-2">
                <button (click)="navigateToSiteMatrixDataPage()" class="site-matrix-btn ml-4">
                    Site Matrix
                </button>
            </div>
            <div class="col-sm-2 offset-sm-3 user-details d-flex justify-content-center">
                <div>
                    <div class="row value-text">
                        Welcome! <br> {{user['firstName']}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <h2 class="statics-header my-3">Key Metrics</h2>
    <app-statics></app-statics>

    <div>
        <app-application-bar-chart></app-application-bar-chart>
    </div>
</div>
