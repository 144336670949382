import { Injectable } from '@angular/core';
import { ApiService } from 'src/services/api.service';
import { StrorageService } from 'src/services/strorage.service';
import { RoleConfigService } from '../../config/role/role.config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private apiService: ApiService, private storageService: StrorageService, 
    private roleConfig: RoleConfigService) { }

    public login(payload: any, successCallback: any) : void {
      this.apiService.post('/v1/users/login', payload).subscribe(res => {
        this.storageService.saveUser(res.data);
        successCallback(res);
      });
    }
}
