<div class="container">
  <div>
      <app-top-bar selectedTab="home"></app-top-bar>
      <app-header></app-header>
  </div>
  <br>
 
  <div class="tool-bar">
  
              <button class="btn btn-primary refresh-btn" >
                  <fa-icon [icon]="faSync" class="close-icon"   [spin]="isRefreshing"></fa-icon>
              </button>
          
      <button mat-button class="btn btn-primary  refresh-btn"(click)="clickBack()">Back</button>
  
  <label class="login-title">Committee Observations - Dashboard</label>

   
      <div class="col-sm-3 offset-sm-1 user-details">
          <div class="value-text">
              Welcome  <br>
          {{user['firstName']}} !
          </div>
          </div>
      </div>
  </div>
  <div class="d-flex justify-content-around">
      <div class="col-sm-4 ">
          <button mat-button class="btn content-btn btn-width w-75 notifications" 
          id="allApplicants" type="button" (click)="notificationBtnClick(null)" [ngStyle]="{ 'background-color': allApplicationBtn ? '#ed7d31' : '#a6a6a6;' }">Total</button>
        </div>  
      <div class="col-sm-4 d-flex">
          <button mat-button class="btn content-btn btn-width w-75 oldNotification" 
          id="selectapp" type="button" (click)="notificationBtnClick('OLD')"  [ngStyle]="{ 'background-color': oldNotificationBtn ? '#ed7d31' : '#a6a6a6;' }">Old Notification</button>
        </div>  
  
        <div class="col-sm-4 d-flex ">
          <button  mat-button  class="btn content-btn btn-width w-75 newNotification"  
          type="button" (click)="notificationBtnClick('NEW')"  [ngStyle]="{ 'background-color': newNotificationBtn ? '#ed7d31' : '#a6a6a6;' }">New Notification</button>
        </div>
   </div>

<div class="col-sm-2 offset-sm-8 title">
  <div class="p1">
  <mat-form-field appearance="fill">
      <mat-label>Village</mat-label>
      <mat-select id="village" name="villageId" [(ngModel)]="villageId" >
        <mat-option *ngFor="let village of villages" [value]="village.id">
          {{village.id}} {{village.name}}
        </mat-option>
      </mat-select>
    
    </mat-form-field>
    <div class="p1">
    Total: {{getTotalApplicantCount()}}
  </div>
</div>
</div>
<div class="content-area">
  <div class="container">
      <div class="row">
          <div>
              <div *ngFor="let status of statusDataList; let i = index;" class=" d-flex justify-content-around village-options     width: 291px;">          
       
                  <div class="col-sm-6"
                  [class.cursor-style]="type == 'village'">
                  <button  class="button " [class.village]="type == 'village'"  (click)="navigateToDataTable(status)" 
                      [style.background-color]=" status.status.id == selectStatus ? colors[i] :'grey'"
                      [style.border-color]=" status.status.id == selectStatus ? 'black' :'grey'"
                      [style.color]=" status.status.id == selectStatus ? 'black' :'#ffffff'">
                      
                    
              
                      <div >
                          <div class="center-align" >
                              <a> {{status.status}}</a> <span class="vertical-divider"></span>
                          </div>
                          <div class="center-align">
                              {{status.count}}
                          </div>
                      </div>
                     
                  </button>
                  </div>
              </div>
          </div>
          
          
          
      </div>
    
    
     
  </div>
</div> 
<br><br>
<app-footer></app-footer>