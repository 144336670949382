<div class="content-area">
    <div class="col-sm-10 offset-sm-1">
        <app-top-bar selectedTab="home" [heading]="''"></app-top-bar>

        <app-header></app-header>
        <div class="title">
            {{pageHeading}}
        </div>
        <div class="col-sm-6 offset-sm-3">
            <mat-card>
                <div class="col-sm-8 offset-sm-2">
                    <div class="row application-form-row ">
                        <label class="form-label">Choose Layout </label>
                        <select class="form-textbox" [(ngModel)]="selectedLayoutId"
                            (change)="setLayoutName($event.target.value)">
                            <option *ngFor="let layout of layouts" [value]="layout.id">{{layout.label}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-8 offset-sm-2">
                    <div class="row application-form-row password-field">
                        <label class="form-label">Enter Password : </label>
                        <input [type]="passwordVisibility" [(ngModel)]="password" class="form-textbox" />
                        <fa-icon [hidden]="passwordVisibility == 'password'" [icon]="faEye" (click)="showPassword()"
                            class="field-icon"></fa-icon>
                        <fa-icon [hidden]="passwordVisibility == 'text'" [icon]="faEyeSlash" (click)="hidePassword()"
                            class="field-icon"></fa-icon>
                    </div>
                </div>
                <div class="col-sm-8 offset-sm-2">
                    <div class="row application-form-row ">
                        <label class="form-label">Enter Registered Mobile Number: </label>
                        <input type="number" [(ngModel)]="mobileNo" class="form-textbox" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 offset-sm-3">
                        <button class="btn btn-primary login-btn" (click)="generateOtp()">{{ otpRequired ? 'Generate OTP' : 'Submit'}}</button>
                    </div>
                </div>
            </mat-card>
        </div>
        <app-footer></app-footer>
    </div>
</div>