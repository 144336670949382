import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';
import { RoleConfigService } from '../shared/config/role/role.config.service';

@Component({
  selector: 'app-generate-otp',
  templateUrl: './generate-otp.component.html',
  styleUrls: ['./generate-otp.component.scss']
})
export class GenerateOtpComponent implements OnInit {

  userId : any;

  otp : any;

  enteredOtp : any ;

  timer: number = 60;

  timerFinished: boolean = false; 

  buttonDisabled: boolean = true;

  timerRunning: boolean = false; 

  private interval: any; 

  public siteAllotmetLayoutName : any;

  public pageHeading : any;
  loginType: any;
  mobileNo: any;


  constructor(private storageService:StrorageService,private cdr:ChangeDetectorRef,
    private applicationService: ApplicationService, private router: Router, private roleConfig : RoleConfigService,
    private route: ActivatedRoute, private _snackBar: MatSnackBar) {
      this.userId = this.route.snapshot.queryParams['userId'];
      this.mobileNo = this.storageService.getItem('loginMobileNo');
      this.siteAllotmetLayoutName = this.route.snapshot.queryParams['siteAllotmentLayoutName']
      this.pageHeading = this.route.snapshot.queryParams['pageHeading']
      this.loginType = this.route.snapshot.queryParams['loginType'];
     }

  ngOnInit() {
    this.generateOtp();
  }


  submit() {
    this.applicationService.verifyOtp(this.enteredOtp, this.userId).subscribe(res=>{;
      console.log(res);
      if(res.success) {
        this.storageService.removeItem('loginMobileNo');
        this.storageService.saveAccessToken(res.data['accessToken']);
        this.openSnackbar('Login Successfully', 'Dismiss');
        if(this.loginType == this.roleConfig.ROLE_HELP_DESK_USER) {
          this.router.navigate(['/application_login']);
        }else if (this.loginType == this.roleConfig.ROLE_APPLICATION_INWARD_USER) {
          this.router.navigate(['/application-inward-workbench']);
        }
        else if (this.loginType == this.roleConfig.ROLE_LEADERSHIP_USER) {
          this.router.navigate(['/leadership-dashboard']);
        }
        else if (this.loginType == this.roleConfig.ROLE_ALLOTMENT_USER) {
          this.router.navigate(['/allotment-dashboard']);
        }
      }else{
        this.openSnackbar(res.message, 'Dismiss');
      }
     })

  }

  generateOtp() {
    this.applicationService.generateLoginOtpSms(this.userId).subscribe(res=>{;
      if(res) {
        this.openSnackbar('OTP Sent Successfully', 'Dismiss');
        this.startTimer();
      }
     })
  }

  openSnackbar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  startTimer() {
    if (!this.timerRunning) { 
      this.timerFinished = false;
      this.buttonDisabled = true;
      this.timer = 60; 
      this.timerRunning = true; 

      this.interval = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          this.timerFinished = true;
          this.buttonDisabled = false;
          clearInterval(this.interval); 
          this.timerRunning = false; 
        }
      }, 1000);
    }
  }

}
