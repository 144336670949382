<div style="margin-top: 5rem;"></div>
<div class="container mt-5">
    <h2 class="text-center">Site Allotment Workflow – Landloser Module – Allotment Letter Printed – EC wise</h2>
    <div class="row mt-5">
        <div class="col-sm-6">
            <div fxLayout="row" fxLayoutAlign="space-around" class=" w-75">
                <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important;"
                    type="text" placeholder="Search By EC No." [(ngModel)]="searchKeyword" id="searchKeyword">
                <span class="input-group-append"><br>&nbsp;
                    <button mat-button class="btn btn-primary" (click)="onClear()">Search</button>
                    <button *ngIf="searchKeyword" mat-button mat-icon-button aria-label="Clear" (click)="onClear()">
                        <mat-icon>close</mat-icon>
                    </button>
                </span>
            </div>
        </div>
        <div>
            
        </div>
        <div class="col-sm-6 d-flex justify-content-end">
          <div>
            <mat-form-field>
                <mat-label>Village </mat-label>
                <mat-select id="Villages" name="Villages" [(ngModel)]="selectedVillageId"
                  (selectionChange)="villageChanged(selectedVillageId)">
                  <mat-option *ngFor="let village of villageList" [value]="village.id">{{village.id+'
                    '+village.villageName}}</mat-option>
                </mat-select>
              </mat-form-field>
          </div> 
        </div>
    </div>

    <div class="row d-flex justify-content-around mt-4">
        <div class="w-25 count-heading" [ngStyle]="{'background-color':'green'}" (click)="navigateToLetterPrinting()">
            EC – Site Allotted, Pending AL Print <br> ({{printingCount}})
        </div>
        <div class="w-25 count-heading" [ngStyle]="{'background-color':'indigo'}">
            EC – AL Printed <br> ({{printedCount}})
        </div>
    </div>

    <div class="row d-flex justify-content-end">
        <img class="col-sm-1" style="height: 4.3rem; width: 1rem;" (click)="downloadExcelSheet()" src="../../../assets/download_xlsx_icon.png"/>
    </div>

    <mat-card class="mt-5"> 
        
        <table mat-table [dataSource]="dataSource" class="w-100">
            <ng-container matColumnDef="slNo">
                <th mat-header-cell *matHeaderCellDef> Serial No. </th>
                <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}</td>
            </ng-container>

            <ng-container matColumnDef="village">
                <th mat-header-cell *matHeaderCellDef> Village </th>
                <td mat-cell *matCellDef="let element" class="column-container">{{element.villageName}}</td>
            </ng-container>

            <ng-container matColumnDef="ecNo">
                <th mat-header-cell *matHeaderCellDef> EC No. </th>
                <td mat-cell *matCellDef="let element" class="column-container">
                    <a href="{{'/#/landloser-printing-completed?ecNo='+element.ecNo}}" target="_blank">{{element.ecNo}}</a>
                </td>
            </ng-container>
            
            <ng-container matColumnDef="totalAllottedSite">
                <th mat-header-cell *matHeaderCellDef> Total Allotted Sites  </th>
                <td mat-cell *matCellDef="let element" class="column-container pl-2">{{element.totalAllottedSites}}</td>
            </ng-container>

            <ng-container matColumnDef="entitledExtent">
                <th mat-header-cell *matHeaderCellDef> Entitled (in Sq. Ft) </th>
                <td mat-cell *matCellDef="let element" class="column-container">{{element.entExtent}}</td>
            </ng-container>

            <ng-container matColumnDef="allottedExtent">
                <th mat-header-cell *matHeaderCellDef> Allotted (in Sq. Ft) </th>
                <td mat-cell *matCellDef="let element" class="column-container">{{element.allottedExtent}}</td>
            </ng-container>

            <ng-container matColumnDef="comments">
                <th mat-header-cell *matHeaderCellDef> Comments </th>
                <td mat-cell *matCellDef="let element" class="column-container">{{element.comments}}</td>
            </ng-container>

            <ng-container matColumnDef="ecStatus">
                <th mat-header-cell *matHeaderCellDef> EC Status </th>
                <td mat-cell *matCellDef="let element" class="column-container">{{element.ecStatus}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
        </table>
        <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
          <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" [length]="totalSize"
             [pageIndex]="currentPage" (page)="getPaginationData($event)">
        </mat-paginator> 
     </mat-card>
</div>