import { Component, Input, OnInit } from '@angular/core';
import { StrorageService } from 'src/services/strorage.service';
import { RoleConfigService } from '../shared/config/role/role.config.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth.service';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { ApplicationService } from 'src/services/application.service';
import { UserService } from 'src/services/user-service/user.service';
import { AlertDialogService } from '../shared/alert/alert-dialog.service';
import { MessageConstantService } from '../shared/services/messageconstant/message-constant.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
   name: string; 
  color: string;
  loginPath: string;
  disabled?: boolean = false;
  isUserLoggedIn : any;
  style: string;

 public newsContent: any[] = [];

 public profileContent: any[] = [];

 public shownNewsNumber = 1;

 public enableHelpDeskUser: any;

 public enableScrutinyUser: any;

 public  enableFieldUser: any;

 public enableOnlineApplicationUser: any;

 public enableComitteeUser: any;

 clicked=true;


  constructor(private storageService:StrorageService, 
    private router: Router ,
    private roleConfigService: RoleConfigService,
    private authService: AuthService, 
    private jccEncodeDecoder: JccnoEncodeDecodeService,
    private applicationService: ApplicationService,
     private userService: UserService,
     private alertDialogService: AlertDialogService,
     private messageConsantService: MessageConstantService
     ) { }

  ngOnInit(): void {
    if(this.storageService.isAlreadyLogin() 
    && this.storageService.isLoggedInTokenExpired()) {
    this.storageService.clear();
    this.alertDialogService.openDialog(this.messageConsantService.SESSION_EXPIRY_MESSAGE, () => {
        this.router.navigate(['/']);
    });
}
    this.addNewsPaperContent();
    this.addProfileContent();
    this.redirectHomeScreen();
  }

  private checkLoginSettings(){
    // this.applicationService.getLoginSettings().subscribe(res=> {
    //   for(let i = 0;i < res.length;i++) {
    //       let loginSettings = res[i];
    //       if(loginSettings['loginUserType'] == 'help_desk_user') {
    //         this.enableHelpDeskUser = loginSettings['enabled'];
    //       }
    //       if(loginSettings['loginUserType'] == 'scrutiny_user') {
    //         this.enableScrutinyUser = loginSettings['enabled'];
    //       }
    //       if(loginSettings['loginUserType'] == 'field_visit_user') {
    //         this.enableFieldUser = loginSettings['enabled'];
    //       }
    //       if(loginSettings['loginUserType'] == 'online_application_user') {
    //         this.enableOnlineApplicationUser = loginSettings['enabled'];
    //       }
    //       if(loginSettings['loginUserType'] == 'comittee_user') {
    //         this.enableComitteeUser = loginSettings['enabled'];
    //       }
    //   }
    // })
  }

  redirectHomeScreen(): void {
    if(!this.storageService.isAlreadyLogin()){
      this.checkLoginSettings();
      return;
    }
    let user = this.storageService.getUser();
    let roleCode = user['roleCode'];
    if(roleCode == this.roleConfigService.ROLE_HELP_DESK_USER) {
      this.router.navigate(['/application']);
    }
    if(roleCode == this.roleConfigService.ROLE_SCRUTINY_USER) {
      this.router.navigate(['/scrutiny-user-dashboard']);
    }
    if(roleCode == this.roleConfigService.ROLE_SCRUTINY_ADMIN) {
      this.router.navigate(['/applicant-list']);
    }
    if(roleCode == this.roleConfigService.ROLE_COMMITTEE) {
      this.router.navigate(['/applicant-list']);
    }
    if(roleCode == this.roleConfigService.ROLE_FIELD_USER) {
      this.router.navigate(['/field-viisit-user-dashboard']);
    }
    if(roleCode == this.roleConfigService.ROLE_ONLINE_APPLICATION_USER) {
      this.fetchOnlineSavedApplication();
    }
  }

  fetchOnlineSavedApplication(){
    this.authService.fetchOnlineApplicantNo().subscribe(res =>{
      if(!res){
        this.router.navigate(['/application_login']);    
      } else {
        let docodeNo = this.jccEncodeDecoder.encode(res.applicationNo)
        this.router.navigate(['/application_login'], { queryParams: { applicationNo: docodeNo } });
      }
    })
 }

  onPrevNewsClick(){
    if(this.shownNewsNumber == 1){
      return;
    }
    this.shownNewsNumber--;
  }

  onNextNewsClick(){
    if(this.shownNewsNumber == this.newsContent.length-2){
      return;
    }
    this.shownNewsNumber++;
  }
  onInwardClick(){
    this.router.navigate(['/generic-login'],{queryParams : { loginType : this.roleConfigService.ROLE_APPLICATION_INWARD_USER}});
  }
  onHelpDeskClick(){
    this.router.navigate(['/generic-login'],{queryParams : { loginType : this.roleConfigService.ROLE_HELP_DESK_USER}} );
}
onScrutinyClick(){
 
}
onApplicantClick(){
 
}
onFieldVisitClick(){
 
 
}


  isHelpdeskUser(){
     this.router.navigate([this.loginPath]);
    return this.userService.getUserType() == this.userService.HELPDESK_USER;

  }

  isOnlineApplicationUser(){
    return this.userService.getUserType() == this.userService.ONLINE_APPLICATION_USER;
  }

  isCommitteeUser(){
    
    return this.userService.getUserType() == this.userService.COMITTEE_USER;
  }

  isScrutinyUser(){
    return this.userService.getUserType() == this.userService.SCRUTINY_USER;
  }
  isFieldUser(){
    return this.userService.getUserType() == this.userService.FIELD_VISIT_USER;
  }
  addStyle(){
    this.style='font-size:20px';
  }

  addNewsPaperContent(){

      let news1 = {};
      news1['date'] = "Dec 3, 2020";
      news1['title'] = "Supreme Court committee formed, headed by Justice. A V Chandrashekar";
      news1['news'] = "Supreme Court committee formed, headed by Justice. A V Chandrashekar";

      let news2 = {};
      news2['date'] = "Jan 16, 2021";
      news2['title'] =  "The committee decides to use data and technology to enable the work. \
                        It brings in NavigemData as the Consultants for building the solution.";
      news2['news'] =  "The committee decides to use data and technology to enable the work. \
                        It brings in NavigemData as the Consultants for building the solution.";

     let news3 = {};
      news3['date'] = "Jan 20, 2021";
      news3['title'] = "The committee decides to use law students from CRM Law College, University \
                       in Banglore India, as Interms for their work at 17 villages ";
      news3['news'] = "The committee decides to use law students from CRM Law College, University in \
                       Banglore India, as Interms for their work at 17 villages ";

      let news4 = {};
      news4['date'] = "Jan 31, 2021";
      news4['title'] = "A tentative date for the commencement of the Mock drill \
                       for the pilot at Vaderahalli panchayat.";
      news4['news'] = "A tentative date for the commencement of the Mock drill \
                       for the pilot at Vaderahalli panchayat.";

  

      this.newsContent.push(news1);
      this.newsContent.push(news2);
      this.newsContent.push(news3);
      this.newsContent.push(news4);
   
  }

  addProfileContent(){

    let profile1 = {};
    profile1['name'] = "Sadashiva Siddappa Sultanpuri" ; 
    profile1['imagePath'] = "../../assets/Shri.Sadashiva.jpeg";
    profile1['content'] = "Born on 07.12.1961. Joined Judiciary as Civil Judge (Junior Division) on \
    11.02.1997. Served at Ranebennuru, Madikeri, Somawarapete, Byadagi,  \
    Theerthahalli.  Promoted on 03.08.2004 as Civil Judge (Senior Division) and  \
    served at Bengaluru, Mysuru, Chitradurga. On 02.02.2014 promoted as District \
    and Sessions Judge and served in Family Court at Vijayapura and Bengaluru. \
    Retired on Superannuation as Principal District and Sessions Judge, \
    Chamarajanagara on 31.12.2021.";

    let profile2 = {};
    profile2['name'] = "Shri.V.G.Bopaiah" ; 
    profile2['imagePath'] = "../../assets/Shri.Bopaiah.jpeg";
    profile2['content'] = "Born on 08.05.1956. Joined Government service as Assistant  Public Prosecutor \
    on 05.03.1986. Joined Judiciary on 18.02.1987 as the then Munsiff and Judicial \
    Magistrate-Ist class. Retired on Superannuation as District and Sessions Judge, \
    Super time scale on 31.05.2016. Thereafter, served as Additional Registrar,\
    Enquiries , Karnataka Lokayukta,  Bengaluru from 18.07.2016 till 17.07.2021.";

    let profile3 = {};
    profile3['name'] = "Shri.N.Sunil Kumar Singh" ; 
    profile3['imagePath'] = "../../assets/Shri.N.SunilKumarSingh.jpeg";
    profile3['content'] = "N. Sunil Kumar Singh,born on 15 July 1961, joined Karnataka Judiciary on\
    17.2.2003 and retired as District and Sessions judge on 31.7.2021 at Bangalore.";

    this.profileContent.push(profile2);
    this.profileContent.push(profile3);
    this.profileContent.push(profile1);
 
  }
}
