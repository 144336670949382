<div class="container ">
    <app-top-bar selectedTab="home" [heading]="'Application Inward – Activity Log'"></app-top-bar>
    <div class="row">
        <div class="col-sm-1 ">
            <button mat-button class="btn btn-primary mb-1" (click)="clickBack()">Back</button>
              <button class="btn btn-primary refresh-btn mb-2" (click)="refreshClick()">
                  <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
              </button>
        </div>
        <div class="col-sm-10 d-flex justify-content-center">
            <h2>Layout: {{userLayoutData['name']}} </h2>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6">
            <div fxLayout="row" fxLayoutAlign="space-around" class=" w-75">
                <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important;"
                    type="text" placeholder="Search by Batch Name" [(ngModel)]="searchKeyword" id="searchKeyword">
                <span class="input-group-append"><br>&nbsp;
                    <button mat-button class="btn btn-primary" (click)="getActivityLogs()">Search</button>
                    <button *ngIf="searchKeyword" mat-button mat-icon-button aria-label="Clear" (click)="onClear()">
                        <mat-icon>close</mat-icon>
                    </button>
                </span>
            </div>
        </div>
        <div class="col-sm-6 d-flex justify-content-end">
          <div>
            <mat-form-field>
                <mat-label>Source Banks </mat-label>
                <mat-select id="Source Banks" name="Source Banks" [(ngModel)]="selectedSourceId" (selectionChange)="getActivityLogs()">
                    <mat-option *ngFor="let source of sourceList" [value]="source.bankId">{{source.bankId+' '+source.bankName}}</mat-option>
                </mat-select>
            </mat-form-field>
            <h3>Total : {{totalApplicantCount}}</h3>

          </div> 
        </div>
    </div>
    <mat-card> 
        
        <table mat-table [dataSource]="dataSource" class="w-100">
            <ng-container matColumnDef="slNo">
                <th mat-header-cell *matHeaderCellDef> Serial No. </th>
                <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}</td>
            </ng-container>

            <ng-container matColumnDef="batchName">
                <th mat-header-cell *matHeaderCellDef> Batch Name </th>
                <td mat-cell *matCellDef="let element" class="column-container">{{element.batchName}}</td>
            </ng-container>

            <ng-container matColumnDef="batchSize">
                <th mat-header-cell *matHeaderCellDef> Batch Size </th>
                <td mat-cell *matCellDef="let element" class="column-container">{{element.applicationCount}}</td>
            </ng-container>
            
            <ng-container matColumnDef="sourceName">
                <th mat-header-cell *matHeaderCellDef> Source Name </th>
                <td mat-cell *matCellDef="let element" class="column-container">{{element.bankName}}</td>
            </ng-container>

            <ng-container matColumnDef="inwardDateTime">
                <th mat-header-cell *matHeaderCellDef> Inward Date Time </th>
                <td mat-cell *matCellDef="let element" class="column-container">{{element.inwardDateTime}}</td>
            </ng-container>

            <ng-container matColumnDef="processedBy">
                <th mat-header-cell *matHeaderCellDef> Processed By </th>
                <td mat-cell *matCellDef="let element" class="column-container">{{element.updatedBy}}</td>
            </ng-container>

            <!-- Action By Column -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="data-header">   Actions    </th>
                <td mat-cell *matCellDef="let element" class="column-container">
                    <img class="edit" src="assets/edit.jpeg" (click)="editInward(element.inwardId)">
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
        </table>
        <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
          <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" [length]="totalSize"
            [pageIndex]="currentPage" (page)="getPaginationData($event)">
        </mat-paginator> 
     </mat-card>
</div>