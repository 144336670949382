<div class="content-area">
<div class="col-sm-12">
   <div class="row">
        <div class="offset-md-1 col-md-7">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.applicationForm"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.applicationForm"></fa-icon>
            <label> 1. Application Form
            </label>
        </div>
        <div class="col-md-4">
            <input class="file-holder" type="file" id="app_form_cpy" [disabled]="!enclosure || enclosure.applicationForm" name="app_form_cpy" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('app_form_cpy')"></fa-icon>
        </div>
        
    </div>

    <div class="row">
        <div class="offset-md-1 col-md-7">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.bankPassbookPage"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.bankPassbookPage"></fa-icon>
            <label> 2. Bank passbook page/Cancelled Cheque</label>
        </div>
        <div class="col-md-4">
            <input class="file-holder" type="file" id="bank_passbook_page_cpy" [disabled]="!enclosure || enclosure.bankPassbookPage" name="bank_passbook_page_cpy" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('bank_passbook_page_cpy')"></fa-icon>
        </div>
    </div>

    <div class="row">
        <div class="offset-md-1 col-md-7">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.passportPic"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.passportPic"></fa-icon>
            <label>3. Applicant passport pic</label>
        </div>
        <div class="col-md-4">
            <input class="file-holder" type="file" id="passport_pic" name="passport_pic" [disabled]="!enclosure || enclosure.passportPic" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('passport_pic')"></fa-icon>
        </div>
        <!-- [disabled]="!enclosure || enclosure.panCard" -->
    </div>

    <div class="row">
        <div class="offset-md-1 col-md-7">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.specimenSign"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.specimenSign"></fa-icon>
            <label>4. Applicant specimen signature.</label>
        </div>
        <div class="col-md-4">
            <input class="file-holder" type="file" id="specimen_sign_cpy" name="specimen_sign_cpy" [disabled]="!enclosure || enclosure.specimenSign" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('specimen_sign_cpy')"></fa-icon>
        </div>
    </div>

    <div class="row">
        <div class="offset-md-1 col-md-7">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.domicileCert"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.domicileCert"></fa-icon>
            <label>5. Domicile Certificate</label>
        </div>
        <div class="col-md-4">
            <input class="file-holder" type="file" id="domicile_cert_cpy" name="domicile_cert_cpy" [disabled]="!enclosure || enclosure.domicileCert" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('domicile_cert_cpy')"></fa-icon>
        </div>
    </div>
    <br>
    
    <div class="row">
        <div class="offset-md-1 col-md-7">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.employmentCert"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.employmentCert"></fa-icon>
            <label>6. Employment Certificate</label>
        </div>
        <div class="col-md-4">
            <input class="file-holder" type="file" id="regn_fee" name="employment_cert_cpy" [disabled]="!enclosure || enclosure.employmentCert" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('employment_cert_cpy')"></fa-icon>
        </div>
    </div>
    <br>

    <div class="row">
        <div class="offset-md-1 col-md-7">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.casteCert"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.casteCert"></fa-icon>
            <label>7. Caste Certificate</label>
        </div>
        <div class="col-md-4">
            <input class="file-holder" type="file" id="caste_cert_cpy" name="caste_cert_cpy" [disabled]="!enclosure || enclosure.casteCert" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('caste_cert_cpy')"></fa-icon>
        </div>
    </div>

    <!-- <div class="row">
        <div class="col-md-7">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.otherOwnershipDocs"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.otherOwnershipDocs"></fa-icon>
            <label>8.ಇತರೆ ಹಕ್ಕು ದಾಖಲಾತಿಗಳು(ದಾನಪತ್ರ, ವಿಲ್ ಇತ್ಯಾದಿ) / Documents to show the ownership of revenue site (Copy of Gift deed/Will / etc..)</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="other_ownerShip_docs" name="other_ownerShip_docs" [disabled]="!enclosure || enclosure.otherOwnershipDocs" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('other_ownerShip_docs')"></fa-icon>
        </div>
    </div> -->
    <br> 

    <div class="row">
        <div class="offset-md-1 col-md-7">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.speciallyAbledCert"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.speciallyAbledCert"></fa-icon>
            <label> 8. Specially Abled Certificate</label>
        </div>
        <div class="col-md-4">
            <input class="file-holder" type="file" id="encumberance_cert" name="specially_abled_cert_cpy" [disabled]="!enclosure || enclosure.speciallyAbledCert" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('specially_abled_cert_cpy')"></fa-icon>
        </div>
    </div>
    <br>

    <div class="row">
        <div class="offset-md-1 col-md-7">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.servicemanCert"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.servicemanCert"></fa-icon>
            <label>9. Serviceman/Ex-Serviceman Certificate </label>
        </div>
        <div class="col-md-4">
            <input class="file-holder" type="file" id="serviceman_cert_cpy" name="serviceman_cert_cpy" [disabled]="!enclosure || enclosure.servicemanCert" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('serviceman_cert_cpy')"></fa-icon>
        </div>
    </div>
    <br>

    <div class="row">
        <div class="offset-md-1 col-md-7">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.incomeCert"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.incomeCert"></fa-icon>
            <label>10. Income Certificate</label>
        </div>
        <div class="col-md-4">
            <input class="file-holder" type="file" id="income_cert_cpy" name="income_cert_cpy" [disabled]="!enclosure || enclosure.incomeCert" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('income_cert_cpy')"></fa-icon>
        </div>
    </div>
    <div class="row">
        <div class="col-md-2 offset-md-5 button-row">
            <input type="button" class="btn btn-primary" value="Save" (click)="onSubmit()"/>
        </div>
    </div>
</div>