<div class="rowcontent-area">
    <div class="col-sm-10 offset-sm-1">
        <app-top-bar selectedTab="audit"></app-top-bar>
        <app-header></app-header>
        <div class="col-sm-6 offset-sm-3">
            <label class="title">AUDIT - HELPDESK</label>
            <mat-card>

                <div class="row">
                    <div class="col-sm-10 offset-sm-1">
                    <label>Choose Panchayat :</label>
                </div>
                </div>
                <div class="row">
                    <div class="col-sm-10 offset-sm-1">
                    <!-- <input type="date" max="{{ maxDate |date:'yyyy-MM-dd'}}" autocomplete="off" onkeydown="return false" class="form-group"/> -->
               <select  [(ngModel)]="selectedPanchayat" class="form-group">
                  <option disabled>Select Panchayat</option>
                  <option  *ngFor="let panchayat of panchayats" [value]="panchayat.id">
                    {{panchayat.name}}
                  </option>
               </select>
                </div>
                </div>
             
                <div class="row">
                    <div class="col-sm-10 offset-sm-1">
                    <label>Date :</label>
                </div>
                </div>
                <div class="row">
                    <div class="col-sm-10 offset-sm-1">
                    <input [(ngModel)]="selectedDate" type="date" max="{{ maxDate |date:'yyyy-MM-dd'}}"  onkeydown="return false" class="form-group"/>
                </div>
                </div>
                <div class="row"> 
                    <div class="col-sm-10 offset-sm-1">  
                    <label>Enter Tokens Issued :</label>  
                </div>   
                </div>
                    <div class="row">  
                     <div class="col-sm-10 offset-sm-1"> 
                    <input [(ngModel)]="issuedToken" type="number"/>
              </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 offset-sm-3">
                        <button mat-raised-button class="btn btn-primary" cdkFocusInitial>SUBMIT</button>
                    </div>
                </div>
       
            </mat-card>
        </div>
        <app-footer></app-footer>
        </div>
        </div>
