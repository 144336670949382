import { Component, OnInit } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
import * as Highcharts from 'highcharts';
import { DateAdapter } from '@angular/material/core';
import { DateTimeUtils } from 'src/app/utils/date-utils';
import moment from 'moment';

declare var require: any;
let Boost = require("highcharts/modules/boost");
let noData = require("highcharts/modules/no-data-to-display");
let More = require("highcharts/highcharts-more");

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
require("highcharts/modules/networkgraph")(Highcharts);

@Component({
  selector: 'app-application-bar-chart',
  templateUrl: './application-bar-chart.component.html',
  styleUrls: ['./application-bar-chart.component.scss'],

})
export class ApplicationBarChartComponent implements OnInit {

  public fromDate;

  public toDate;

  highcharts = Highcharts;

  chart;
  
  chartCallback;

  updateFromInput = false;

  maxDate = new Date();

  dataSet: any[] = [
   {
      name:'',
      data:[]
   },
   {
      name:'',
      data:[]
   },
  ];

  labelData : any [] = [];

  chartData: any[] = [];

  options: any = {
   chart: {
     type: "column"
   },
   title: {
     text: "Daily Statistics"
   },
   xAxis: {
     categories: this.labelData,
     title: {
       text: null
     }
   },
   yAxis: {
     min: 0,
     title: {
       text: "Application Numbers",
       align: "high"
     },
     labels: {
       overflow: "justify"
     }
   },
   tooltip: {
     valuePrefix: "Count "
   },
   plotOptions: {
      column: {
         stacking: 'normal',
         dataLabels: {
             enabled: true
         },
     },
   },
   legend: {
      symbolWidth: 60,
      symbolHeight: 10,
      symbolRadius:0
   },
   credits: {
     enabled: false
   },
   series: this.dataSet,
   colors:['#0070c0','#008b8b']
 };   

  constructor(private applicationService: ApplicationService, private dateAdapter: DateAdapter<Date>) { 
   this.dateAdapter.setLocale('en-GB');
  }
  

  ngOnInit(): void {
  // this.getChartData();  
  }


  getChartData = (fromDate?:any, toDate?:any) => {
   try {
     this.applicationService.getApplicantCountPerStatus(DateTimeUtils.formatDateToStr(moment(this.fromDate)), DateTimeUtils.formatDateToStr(moment(this.toDate))).subscribe(res => {
      console.log('data chart data',res);
      if(res) {
        this.chartData = res;
        this.filterChartData();
      }
     }, err => {
       console.log(err);
     })
   } catch (e) {
   }
 }

 filterChartData(){
  if(!this.chartData){
    return;
  }
  for(let i = 0; i < this.chartData.length; i++){
    this.dataSet[i].data = [];
  }
   this.dataSet[0].name = "Applications Received";
   this.dataSet[1].name = "Applications Digitised";
   for(let i = 0; i < this.chartData.length; i++){
      this.dataSet[0].data.push(this.chartData[i].applicationInwardCount);
      this.dataSet[1].data.push(this.chartData[i].applicantCount);
      this.labelData[i] = this.chartData[i].date;
   }
   console.log("filter chart data",this.dataSet);
   console.log("label data",this.labelData);
   this.updateFromInput = true;
  }

  onDateChange(){
   this.getChartData();
  }

}
