<div (click)="onLoginClick()" class="content-area">
    <div class="name col-sm-12" [style.background-color]="color" >
        <div>
            {{name}}
        </div>
        <div>
            Login
        </div>
    </div>
</div>
