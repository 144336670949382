import { HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/services/api.service';
import { UserService } from 'src/services/user-service/user.service';
import { StrorageService } from 'src/services/strorage.service';
import { ApiLoaderService } from 'src/app/shared/loading/api-loader.service';
import { MessageConstantService } from 'src/app/shared/services/messageconstant/message-constant.service';

@Injectable()
export class TokenInterceptorService {

  constructor(public apiService: ApiService, private storageService: StrorageService,
    private apiLoaderService: ApiLoaderService,
    private messageConstantService: MessageConstantService) {} 

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let header = {};
    let accessToken = this.storageService.getAccessToken();
    if(accessToken){
      if(this.storageService.isLoggedInTokenExpired()) {
        this.apiLoaderService.hide();
        let error = new Error();
        error['dialog_type'] = 'alert';
        error['message_type'] = 'session_expired';
        error['message'] = this.messageConstantService.SESSION_EXPIRY_MESSAGE;
        throw error;
      }
      if(accessToken && accessToken != "undefined"){ //temporary removed authorization!
        header['Authorization'] = accessToken;
      }else{
        console.log('Continuing without Authorization!');
      }
    }
    request = request.clone({ setHeaders: header});
    return next.handle(request)
  }
}
