import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RoleConfigService {

  ROLE_AUDIT_HELP_DESK: string = "audit_helpdesk";

  ROLE_ONLINE_APPLICATION_USER: string = "online_application_user";

  ROLE_SCRUTINY_USER: string = "scrutiny_user";

  ROLE_SCRUTINY_ADMIN: string = "scrutiny_admin";

  ROLE_COMMITTEE: string = "comittee_user";

  ROLE_HELP_DESK_USER: string = "helpdesk_user";

  ROLE_APPLICATION_INWARD_USER: string = "inward_user";

  ROLE_ADMIN_USER: string = "admin_user";

  ROLE_FIELD_USER: string = "field_user";

  ROLE_LEADERSHIP_USER: string = "leadership_user";

  ROLE_ALLOTMENT_USER: string = "allotment_user";

  ROLE_ONLINE_USER: string = "online_user";

  CHANNEL_HELPDESK: string = "hd"

  CHANNEL_ONLINE: string = "online"

  constructor() { }
}
