<app-top-bar [heading]="'BDA – Site Allotment'"> </app-top-bar>
<div class="container">
    <app-header></app-header>
    <div class="row d-flex justify-content-between mt-4">
        <div class="col-sm-2">
            <button mat-button class="btn btn-primary" (click)="clickBack()">Back</button>
        </div>
        <div class="col-sm-7 title text text-center">
            Site Allotment Workflow – Landloser Module – Allotment Letter Printing
        </div>
        <div class="col-sm-3 user-details">
            <div class="value-text">
                Welcome {{user['firstName']}} !
            </div>
        </div>
        <button class="col-sm-1 btn btn-primary refresh-btn" (click)="refreshClick()">
            <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
        </button>
    </div>
    <div class="row">
        <app-ec-details-container class="col-sm-12" [ecData]="ecPrintingData"></app-ec-details-container>
    </div>
    <div class="row d-flex justify-content-start mt-4">
        <table border="1" class=" col-sm-7">
            <thead>
                <tr>
                    <th>Sl. No.</th>
                    <th>Dimension</th>
                    <th>Site No</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let siteData of siteDataList; let i = index">
                    <td>{{i+1}}</td>
                    <td><input type="text" class="borderless" [(ngModel)]="siteData.dimensionLabel" /></td>
                    <td><input type="text" class="borderless readonly-td" [(ngModel)]="siteData.siteNo" readonly /></td>
                    <td class="d-flex justify-content-center"><button class="btn print-btn" (click)="onPrint(i)" [disabled]="siteData.printed">Print</button></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="row d-flex justify-content-center" style="height: 6rem;">
        <button *ngIf="showDoneBtn" class="col-sm-1 btn submit-btn" (click)="openDialog()">Done</button>
    </div>
</div>