import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';
import { faTimes} from '@fortawesome/free-solid-svg-icons';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-commitee-workbench',
  templateUrl: './commitee-workbench.component.html',
  styleUrls: ['./commitee-workbench.component.scss']
})
export class CommiteeWorkbenchComponent implements OnInit {

  applicationNo : any;
  applicationData;
  selectedPanchayat = ''
  public faTimes = faTimes;
  dialogRef: any;
  public application_id: any;
  
  

  constructor(private applicationService: ApplicationService,private route: ActivatedRoute, private router: Router,) {
    this.application_id = this.route.snapshot.queryParams['application_id'];
   }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }
  cancelClick(){
    this.close();
  }
  encloPage() {
    let fullURL = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');
    window.open(fullURL+'#/preview-enclosure?application_id='+this.application_id, '_blank');
  }

  clickBack(){
    window.history.back();
  }


}
