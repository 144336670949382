<div  *ngIf="ecData.ecNo" class="row d-flex justify-content-between ec-no-text-holder mt-4">
    <div class="col-sm-6 d-flex justify-content-start">
        <label>Village: <b>{{ecData.villageName}}</b></label>
    </div>
    <div class="col-sm-6 d-flex justify-content-start">
        Sy. No: {{ecData.surveyNo}}
    </div>
    <div class="col-sm-6 d-flex justify-content-start mt-2">
        EC No: <span class="text-danger ml-1">{{ecData.ecNo}}</span>
    </div>
    <div class="col-sm-6 d-flex justify-content-start mt-2">
        Extent: {{ecData.extent}}
    </div>
    <div class="col-sm-6 d-flex justify-content-start mt-4">
        Khatedar Name : {{ecData.khatedarName}}
    </div>
    <div class="col-sm-6 d-flex justify-content-start mt-4">
        Entitled Extent (in Sq.Ft): {{ecData.entExtent}}
        <div *ngIf="isAddBtnClicked == false && isAddBtn">
            &nbsp;&nbsp;&nbsp;
            <button class="btn btn-primary mat-focus-indicator mat-button mat-button-base" style="margin-top: -8px;" (click) = "onAddBtnClicked()">Add</button>
        </div>
    </div>
    <div *ngIf="ecData.comments" class="col-sm-6 mt-2">
        <textarea rows="1" cols="20" class="text-danger">
            Comments : {{ecData.comments}}
        </textarea>
    </div>
    <div class="col-sm-6 d-flex justify-content-start mt-4" *ngIf="isAddBtnClicked && isAddBtn">
            <label class="form-label">Add Additional Entitlement Extent (in Sq.Ft): </label>
             <input type="number" style="width: 100px;margin-top: -10px;" [(ngModel)]="additionalEntitledExtent" class="form-textbox"/>
        &nbsp;
        <button class="btn btn-primary mat-focus-indicator mat-button mat-button-base" style="margin-top: -8px;" (click) = "onUpdateClicked(additionalEntitledExtent)">Update</button>
    </div>
    <div class="col-sm-6 d-flex justify-content-start mt-4" *ngIf="!isAddBtn">
        Additional Entitlement Extent (in Sq.Ft): {{additionalEntitledExtent ? additionalEntitledExtent : 0}}
    </div>
    <div class="col-sm-6 d-flex justify-content-start mt-4"></div>
    <div class="col-sm-6 d-flex justify-content-start mt-4" *ngIf="isAddBtnClicked">
        New Entitlement Extent (in Sq.Ft): {{newAdditionalExtentValue ? newAdditionalExtentValue : 0}}
    </div>
    <div class="col-sm-6 d-flex justify-content-start mt-4"></div>

    <div *ngIf="ecData.alExtent" class="col-sm-6 d-flex justify-content-start mt-2">
        Allotment Extent (in Sq.Ft): {{ecData.alExtent}}
    </div>
    <div  class="col-sm-6"></div>
    <div  class="col-sm-6 d-flex justify-content-start mt-3">
        Difference (in Sq.Ft): <span class="text-danger ml-1">{{ecData.diffExtent ? ecData.diffExtent : 0}}</span>
    </div>
</div>