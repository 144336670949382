import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';

@Component({
  selector: 'app-ec-details-container',
  templateUrl: './ec-details-container.component.html',
  styleUrls: ['./ec-details-container.component.scss']
})
export class EcDetailsContainerComponent implements OnInit {

  @Input() ecData: any = {};

  public additionalEntitledExtent : any;

  public newAdditionalExtentValue : any;

  public isAddBtn : boolean = false;

  public isAddBtnClicked : boolean = false;

  allotmentType: any;

  allowSiteMapping: any;

  @Output() updateParent: EventEmitter<any> = new EventEmitter();



  constructor( private applicationService: ApplicationService,
    private _snackBar: MatSnackBar,private route : ActivatedRoute) {
      this.allotmentType = this.route.snapshot.queryParams['allotmentCountType'];
      this.allowSiteMapping = this.route.snapshot.queryParams['allowSiteMapping'];
     }

    ngOnChanges() {
      console.log(this.ecData);
      if (this.ecData.additionalEntitledExtent) {
        this.isAddBtnClicked = true;
        this.additionalEntitledExtent = this.ecData.additionalEntitledExtent;
        this.newAdditionalExtentValue = this.convertToInt(this.additionalEntitledExtent)   + this.convertToInt(this.ecData.entExtent);

    }
  }

  ngOnInit(): void {
    if (this.allotmentType == 'site_allotment' && !this.allowSiteMapping) {
      this.isAddBtn = true;
    }
    console.log(this.ecData);
  }

  onAddBtnClicked() {
    this.isAddBtnClicked = true;
  }

  convertToInt(val){
    return parseInt(val);
  }

  onUpdateClicked (additionalExtent: any) {
    this.additionalEntitledExtent = additionalExtent;
    this.newAdditionalExtentValue = this.convertToInt(this.additionalEntitledExtent)   + this.convertToInt(this.ecData.entExtent);
    let payload = {};
    payload['additionalExtentValue'] = this.additionalEntitledExtent;
    payload['newExtentValue'] = this.newAdditionalExtentValue;
      this.applicationService.updateEcDetailsExtentValue(this.ecData.ecId, payload).subscribe(res => {
        if(res){
          this.openSnackbar("Additional Entitlement Value updated");
          this.updateParent.emit();
        }
    })
  }

  openSnackbar = (message: string, action?: string) => {
    if(!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }


}
