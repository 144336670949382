import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AllotmentModeService {

  constructor() { }

  public readonly MODE_COMMENCE_SITE_ALLOTMENT: string = "commence_site_allotment";

  public readonly MODE_SITE_MAPPING: string = "site_mapping";

  public readonly MODE_PRINT_ALLOTMENT_LETTER: string = "print_allotment_letter";

  public readonly MODE_PRINTING_ACTIVITY: string = "printing_activity";


}
