import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-application-inward-workbench',
  templateUrl: './application-inward-workbench.component.html',
  styleUrls: ['./application-inward-workbench.component.scss']
})
export class ApplicationInwardWorkbenchComponent implements OnInit {

  public user: any;
  userLayoutData: any;
  public nextBatchName: '';
  public sourceList: any[] = [];
  public selectedSourceId: number = undefined;
  public applicationCount: number;

  constructor(public dialog: MatDialog, private storageService : StrorageService, private applicationService: ApplicationService,
    private _snackBar: MatSnackBar, private router: Router) {
    this.user = storageService.getUser();
    this.userLayoutData = this.storageService.getUserLayoutData();
   }

  ngOnInit(): void {
    this.fetchBankSources();
    this.fetchNextBatchName();
  }

  fetchBankSources = () => {
    try{
      this.applicationService.getBankSources().subscribe(res =>{
        this.sourceList = res;
      }, err=>{
          console.log(err);
          this.openSnackbar(err?.message, "Dismiss");
      })
    } catch(e){
      this.openSnackbar(e, "Dismiss");
    }
  }

  fetchNextBatchName = () => {
    try{
      this.applicationService.getNextBatchName(this.selectedSourceId).subscribe(res =>{
        this.nextBatchName = res.nextBatchNo;
      }, err=>{
          console.log(err);
          this.openSnackbar(err?.message, "Dismiss");
      })
    } catch(e){
      this.openSnackbar(e, "Dismiss");
    }
  }

  saveApplicationInward = () => {
    let payload = this.validateAndConstructPayload();
    try{
      this.applicationService.saveApplicationInward(payload).subscribe(res =>{
        if(res){
          this.navigateToActivityLogs();
        }
      }, err=>{
          console.log(err);
          this.openSnackbar(err?.message, "Dismiss");
      })
    } catch(e){
      this.openSnackbar(e, "Dismiss");
    }
  }

  navigateToActivityLogs(){
    this.router.navigate(['/application-inward-activitylog']);
  }

  openSnackbar(message: string, action?: string){
    if(!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }

  openDialog = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '200px';
    dialogConfig.width = '520px';
    dialogConfig.data = "Confirm save & submit – Batch no- "+this.nextBatchName+" & Batch Size: "+ this.applicationCount;
    const dialogRef = this.dialog.open(ConfirmationModalComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(res =>{
      if(res){
        this.saveApplicationInward();
      }
    })
  }

  validateFormData(){
    if(!this.applicationCount){
      throw new Error('Applicant Count field must not be empty');
    }
    if(!this.selectedSourceId){
      throw new Error('Source field must not be empty');
    }
  }

  validateAndConstructPayload = () => {
    let payload = {};
    payload['applicationCount'] = this.applicationCount;
    payload['sourceId'] = this.selectedSourceId;
    return payload;
}

  onSubmit = () => {
    this.validateFormData();
    this.openDialog();
  }
}
