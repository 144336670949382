import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplicationService } from 'src/services/application.service';
import { AuthService } from 'src/services/auth.service';
import { faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { UserService } from 'src/services/user-service/user.service';
import { RoleConfigService } from '../shared/config/role/role.config.service';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-help-desk-login',
  templateUrl: './help-desk-login.component.html',
  styleUrls: ['./help-desk-login.component.scss']
})
export class HelpDeskLoginComponent implements OnInit {


  panchayats : any[];
  selectedPanchayat : number;
  password : string;
  mobileNo : number;
  enteredOtp : string;

  faEye = faEye;

  faEyeSlash = faEyeSlash;

  passwordVisibility = 'password';

  selectedLayout: any;

 readonly OTP_SENT : number = 1;

 readonly OTP_RESENT : number = 2;

  otpStatus : number = 0;

  public layouts = [
    {
      "layoutName":"Dr. Shivarama Karanth Layout",
      "code":"DSKL"
    }
  ]

  constructor(private applicationService: ApplicationService, private authService: AuthService,
     private _snackBar: MatSnackBar, private router: Router, private userService: UserService,
     private roleConfig: RoleConfigService, private jccEncodeDecoder: JccnoEncodeDecodeService, private storageService : StrorageService) { }

  ngOnInit(): void {
    // this.fetchPanchayats();
  }

  // fetchPanchayats(){
  //   this.applicationService.fetchAllPanchayats().subscribe(res =>{
  //      this.panchayats = res;
  //   })
  // }

  generateOtp(){
    if(!this.isValidLogin()){
      return;
    }
    // console.log(this.selectedNotification);
    this.otpStatus = this.OTP_SENT;
    let payload = {};
    payload['mobile'] = this.mobileNo;
    payload['password'] = this.password;
    payload['loginType'] = this.roleConfig.ROLE_HELP_DESK_USER;
    // this.authService.login(payload, this.loginSuccess);
  }

  submit(){
    let user = { 
      firstName : this.mobileNo,
      roleCode : this.roleConfig.ROLE_HELP_DESK_USER,
    }
    this.storageService.saveUser(user);
    this.router.navigate(['/application_login']);
  }

  loginSuccess = (res: any): void => {
    console.log("helpdesk",res);
    if(res && res.success) {
      this.openSnackbar('Login successful', 'Dismiss');
      this.router.navigate(['/application_login']);
      return;
   }
   this.openSnackbar('Invalid credentials', 'Dismiss');
  }

  isValidLogin() : boolean{
    //  if(!this.selectedPanchayat){
    //    this.openSnackbar('Please select panchayat', 'Dismiss');
    //    return false;
    //  }
      if(!this.mobileNo){
        this.openSnackbar('Please enter mobile number', 'Dismiss');
        return false;
     }
      if(!this.password){
        this.openSnackbar('Please enter password', 'Dismiss');
        return false;
     }
    return true;
  }

  openSnackbar(message: string, action: string){
    this._snackBar.open(message, action);
  }

  showPassword(){
    this.passwordVisibility = 'password'
  }

  hidePassword(){
    this.passwordVisibility = 'text';
  }

  ngOnDestroy() {
    
  }
}
