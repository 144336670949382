import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { BlobDownloaderService } from './blob-downloader.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private API_URL: string;

  constructor(private http: HttpClient, private _snackBar: MatSnackBar
    ) { 
    this.API_URL = `${environment.BASE_URL}` + 'api';
  }
    private formatErrors(error: any) {
   alert('Api Error' + error?.error?.message)
      return  throwError(error.error);
    }
  
    get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
      return this.http.get(this.API_URL + `${path}`, { params });
    }
  
    put(path: string, body: Object = {}): Observable<any> {
      return this.http.put(
        this.API_URL + `${path}`,body
      );
    }
  
    post(path: string, body: Object = {}): Observable<any> {
      return this.http.post(this.API_URL + `${path}`, body);
    }

    downloadBlob(path: string,fileExt:string, body: Object = {}){

      var httpOptions = {
         headers: new HttpHeaders({
            'Accept':'application/'+fileExt,
         }),
         'responseType': 'blob' as 'json'
      }
    
      this.http.post(this.API_URL + `${path}`, body,httpOptions)
      .subscribe(
         (blob:Blob) => {
          BlobDownloaderService.downloadFile(blob,fileExt);
            console.log(blob) //do something with the blob
         },
         error => {
            console.error('download error:', error)
         }, 
         () => {
            console.log('Completed file download.')
         }
      )
    }
  
    delete(path: string): Observable<any> {
      return this.http.delete(
        this.API_URL + `${path}`
      );
    }

    openSnackbar(message: string, action: string){
      this._snackBar.open(message, action);
    }
}
