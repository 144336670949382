<div fxFlex="100%" class="d-flex justify-content-center">
    <!-- <div fxFlex="25" class="side-menu-container"> -->
    <!-- <app-side-menu [menus]="menus" (onMenuClick)="onItemClicked($event)"></app-side-menu> -->
    <!-- </div> -->

    <div fxFlex="75" style="padding-left: 55px">
        <div class="row application-form-row">
            <label class="appln-form-label">Application Form : <span class="text-danger"> *
                </span></label>
            <div class="col-sm-12" *ngFor="let formType of formTypeList">
                <input id="selectedFormTypeId" (change)="setFormTypeData(formType)" type="radio"
                    [(ngModel)]="selectedFormTypeId" name="selectedFormTypeId" [value]="formType.id"
                    [disabled]="(data && data.formTypeId)" />
                <label for="single" class="radio-text">{{formType.label}}</label>
            </div>
        </div>
        <div class="row application-form-row ">
            <label class="form-label w-100">Application No: <span class="text-danger"> *
                </span></label>
                <input *ngIf="!data" type="text" class="form-textbox" style="width: 7%;" [(ngModel)]="appNoPrefix" readonly/>
            <input type="text" [(ngModel)]="applicationNo" style="width: 93%;" class="form-textbox" 
                [readonly]="(data && data.applicationNo) && !isEditable" />
        </div>
        <div *ngIf="!isOnlineUser" class="row application-form-row ">
            <label class="form-label">Batch Name: <span class="text-danger"> *
                </span></label>
                <form class="example-form w-100">
                    <mat-form-field class="w-100 mat-form-resize" appearance="outline">
                      <input type="text"
                      class="form-textbox"
                             placeholder=""
                             matInput
                             [formControl]="myControl"
                             [(ngModel)]="selectedBatchName"
                             [matAutocomplete]="auto" [readOnly]="(data && data.batchName)">
                      <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let batchName of filteredOptions | async" [value]="batchName">
                            {{batchName}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </form>
        </div>
        <div class="row application-form-row">
            <label class="appln-form-label w-100">1. Site Dimension : <span class="text-danger"> *
                </span></label>
            <div class="col" *ngFor="let dimension of dimensionList">
                <input id="selectedDimensionId" type="radio" (change)="setSiteDimensionData(dimension)"
                    [(ngModel)]="selectedDimensionId" name="selectedDimensionId" [value]="dimension.id"
                    [disabled]="(data && data.dimensionId)" />
                <label for="single" class="radio-text">{{dimension.label}}</label>
            </div>
        </div>
        <div class="row application-form-row">
            <label class="appln-form-label w-100">2. Initial Deposit : <span class="text-danger"> *
                </span></label>
            <div class="col-sm-3" *ngFor="let initialDeposit of initialDepositList">
                <input id="initialDepositId" type="radio" (change)="setInitialDepositData(initialDeposit)"
                    [(ngModel)]="initialDepositId" name="initialDepositId" [value]="initialDeposit.id"
                    [disabled]="(data && data.initialDepositId)" />
                <label for="single" class="radio-text">{{initialDeposit.label}}</label>
            </div>
        </div>
        <h2 class="mt-5 sub-heading">Personal Information</h2>
        <div class="row application-form-row">
            <label class="form-label">3. Name Of The Applicant : <span class="text-danger"> *
                </span></label>
            <input type="text" [(ngModel)]="applicantName" class="form-textbox"
                [readonly]="(data && data.applicantName) && !isEditable" />
        </div>
        <div class="row application-form-row ">
            <label class="form-label">4. Father’s/Husband’s Name: <span class="text-danger"> *
                </span></label>
            <input type="text" [(ngModel)]="fatherName" class="form-textbox"
                [readonly]="(data && data.familyMemberName) && !isEditable" />
        </div>
        <div class="row application-form-row " fxLayout="row" fxLayoutAlign="space-between">
            <label class="form-label">5. Address :
                <span class="text-danger"> * </span></label>
            <input type="text" [(ngModel)]="addressBox1" class="form-textbox"
                [readonly]="(data && data.applicantAddress) && !isEditable" />
            <input type="text" [(ngModel)]="addressBox2" class="form-textbox mt-1"
                [readonly]="(data && data.applicantAddress) && !isEditable" />
            <input type="text" [(ngModel)]="addressBox3" class="form-textbox mt-1"
                [readonly]="(data && data.applicantAddress) && !isEditable" />
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between">
            <div style="width: 70%" class="row mt-2">
                <label class="form-label"> City :
                    <span class="text-danger"> * </span></label>
                <input type="text" [(ngModel)]="city" class="form-textbox"
                    [readonly]="(data && data.city) && !isEditable" />
            </div>
            <div style="width: 30%" class="row mt-2">
                <label class="form-label"> Pin code :
                    <span class="text-danger"> * </span></label>
                <input type="number" [(ngModel)]="pinCode" (keypress)="validateNumber($event, 6)" class="form-textbox"
                    [readonly]="(data && data.pinCode) && !isEditable" />
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between">
            <div style="width: 40%" class="row application-form-row">
                <label class="form-label"> 6. Date of Birth (dd:mm:yyyy) :
                <span class="text-danger"> * </span></label>
                <mat-form-field class="w-100 mat-form-resize h-75" appearance="outline">
                    <input matInput [matDatepicker]="dobPicker"  [(ngModel)]="dateOfBirth" [readonly]="!data" [disabled]="(data && data.dateOfBirth)"
                    (dateInput)="calculateAgeFromDate(dateOfBirth)" [max]="todayDate" class="mat-date-default">
                    <mat-datepicker-toggle matIconSuffix [for]="dobPicker" style="height: 40px;"></mat-datepicker-toggle>
                    <mat-datepicker #dobPicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div style="width: 40%" class="row application-form-row">
                <label class="form-label">7. Age :</label>
                <input type="number" [(ngModel)]="age" class="form-textbox"
                    [readonly]="(data && data.age) && !isEditable" disabled />
            </div>
        </div>
        <div class="row application-form-row ">
            <div class="col-sm-6">
                <label class="form-label">8. Gender : <span class="text-danger"> *
                    </span></label>
                <div *ngFor="let gender of genderList" class="d-flex align-items-center ml-5">
                    <input id="genderId" type="radio" (change)="setGenderName(gender)" [(ngModel)]="genderId"
                        name="genderId" [value]="gender.id" [disabled]="(data && data.genderId)" />
                    <label for="single" class="radio-text">{{gender.label}}</label>
                </div>
            </div>
            <div class="col-sm-6">
                <label class="form-label">9. Marital Status : <span class="text-danger"> *
                    </span></label>
                <div *ngFor="let maritialStatus of maritialStatusList" class="d-flex align-items-center ml-5">
                    <input id="maritialStatusId" type="radio" (change)="setMaritalStatusName(maritialStatus)"
                        [(ngModel)]="maritialStatusId" name="maritialStatusId" [value]="maritialStatus.id"
                        [disabled]="(data && data.maritalStatusId)" />
                    <label for="single" class="radio-text">{{maritialStatus.label}}</label>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between">
            <div style="width: 60%" class="row application-form-row">
                <label class="form-label">10. Occupation :
                    <span class="text-danger"> * </span></label>
                <input type="text" [(ngModel)]="occupation" class="form-textbox" oninput=" this.value = this.value.replace(/[0-9]/g,'')"
                    [readonly]="(data && data.occupation) && !isEditable" />
            </div>
            <div style="width: 40%" class="row application-form-row">
                <label class="form-label">11. Annual Income :
                    <span class="text-danger"> * </span></label>
                <input *ngIf="!(data && data.annualIncome)" type="number" [(ngModel)]="annualIncome" class="form-textbox" (keypress)="validateNumber($event)" 
                    [readonly]="(data && data.annualIncome) && !isEditable" />
                <input *ngIf="(data && data.annualIncome)" type="text" [(ngModel)]="annualIncome" class="form-textbox"
                [readonly]="(data && data.annualIncome) && !isEditable" />
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between">
            <div style="width: 60%" class="row application-form-row">
                <label class="form-label">12. PAN No : </label><span class="text-danger"> * </span>
                <input type="text" [(ngModel)]="panNo" (keypress)="validateNumber($event, 10)" class="form-textbox"
                    [readonly]="(data && data.panCardNo) && !isEditable" />
            </div>
            <div style="width: 40%" class="row application-form-row">
                <label class="form-label">13. AADHAAR No : </label><span class="text-danger"> * </span>
                <input type="number" [(ngModel)]="aadharNumber" (keypress)="validateNumber($event, 12)"
                    class="form-textbox" [readonly]="(data && data.aadhaarNo) && !isEditable" />
            </div>
        </div>
        <h2 class="mt-5 sub-heading">Registration Information</h2>
        <div fxLayout="row" fxLayoutAlign="space-between">
            <div style="width: 60%" class="row application-form-row">
                <label class="form-label">14. Registration No :</label><span class="text-danger"> *
                </span>
                <input type="text" [(ngModel)]="registrationNo" class="form-textbox" (blur)="constructNoOfSiteAllotmentTableSize()"
                    [readonly]="(data && data.registrationNo) && !isEditable" />
            </div>
            <div style="width: 40%" class="row application-form-row">
                <label class="form-label"> 15. Registration Date (dd:mm:yyyy) :
                    <span class="text-danger"> * </span></label>
                    <mat-form-field class="w-100 mat-form-resize h-75" appearance="outline">
                        <input matInput [matDatepicker]="regDatePicker" [(ngModel)]="dateofRegister"
                         [max]="todayDate" class="mat-date-default" [readonly]="!data" [disabled]="(data && data.registrationDate)">
                        <mat-datepicker-toggle matIconSuffix [for]="regDatePicker" style="height: 40px;"></mat-datepicker-toggle>
                        <mat-datepicker #regDatePicker></mat-datepicker>
                    </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between">
            <div style="width: 40%" class="row application-form-row">
                <label class="form-label"> 16. Registration Fee :</label>
            </div>
        </div>
        <div class="mt-2 row application-form-row">
            <div class="col-sm-3 mb-2"> <label class="form-label">Previous Registration Fee :</label></div>
            <div class="col-sm-9 mb-2"><input type="number" [(ngModel)]="previousFee"
                    (keypress)="validateNumber($event)" class="form-textbox"
                    [readonly]="(data && data.prevRegistrationFee) && !isEditable" /></div>
            <div class="col-sm-3 mb-2"> <label class="form-label ">Revised Registration Fee :</label></div>
            <div class="col-sm-9 mb-2"><input type="number" [(ngModel)]="revFee" (keypress)="validateNumber($event)"
                    class="form-textbox" [readonly]="(data && data.revRegistrationFee) && !isEditable" /></div>
            <div class="col-sm-3 mb-2"><label class="form-label ">Difference Regn Fee Paid :</label></div>
            <div class="col-sm-9 mb-2"> <input type="number" [(ngModel)]="diffFee" (keypress)="validateNumber($event)"
                    class="form-textbox" [readonly]="(data && data.diffRegistrationFee) && !isEditable" /></div>
            <div class="col-sm-3 mb-2"> <label class="form-label ">New Registration Fee :</label></div>
            <div class="col-sm-9 mb-2"> <input type="number" [(ngModel)]="newFee" class="form-textbox"
                    [readonly]="(data && data.newRegistrationFee) && !isEditable" (keypress)="validateNumber($event)" />
            </div>
        </div>
        <h2 class="mt-5 sub-heading">Initial Deposit Details</h2>
        <div fxLayout="row" fxLayoutAlign="space-between">
            <div style="width: 40%" class="row application-form-row">
                <label class="form-label">17. Initial Deposit :</label><span class="text-danger"> *
                </span>
                <input type="number" [(ngModel)]="initialDepositAmount" class="form-textbox"
                    [readonly]="(data && data.age) && !isEditable" (keypress)="validateNumber($event)" />
            </div>
            <div fxFlexOffset="30" style="width: 30%" class="row application-form-row">
                <label class="form-label"> Date (dd:mm:yyyy) :
                    <span class="text-danger"> * </span></label>
                    <mat-form-field class="w-100 mat-form-resize h-75" appearance="outline">
                        <input matInput [matDatepicker]="initialDepositDatePicker" [(ngModel)]="initialDepositDate"
                         [max]="todayDate" class="mat-date-default" style="width: 80%;" [readonly]="!data" [disabled]="(data && data.initialDepositDate)">
                        <mat-datepicker-toggle matIconSuffix [for]="initialDepositDatePicker" style="height: 40px;"></mat-datepicker-toggle>
                        <mat-datepicker #initialDepositDatePicker></mat-datepicker>
                    </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between">
            <div style="width: 30%" class="row application-form-row">
                <label class="form-label">Payment Mode :<span class="text-danger"> * </span></label>
                <select class="form-textbox" [(ngModel)]="selectedPaymentModeId" (change)="setPaymentTypeName($event)"
                    [disabled]="(data && data.paymentModeId) && !isEditable">
                    <option *ngFor="let paymentMode of paymentModes" [value]="paymentMode.id">
                        {{paymentMode.paymentMode}}
                    </option>
                </select>
            </div>
            <div fxFlexOffset="30" style="width: 40%" class="row application-form-row">
                <label class="form-label">DD/ NEFT/ RTGS No :<span class="text-danger"> * </span></label>
                <input *ngIf="selectedPaymentModeName == 'DD'" type="number" [(ngModel)]="paymentModeNumber"
                    class="form-textbox" [readonly]="(data && data.initialDepositPaymentModeNo) && !isEditable"
                    (keypress)="validateNumber($event)" />
                <input *ngIf="selectedPaymentModeName != 'DD'" type="text" [(ngModel)]="paymentModeNumber"
                    class="form-textbox" [readonly]="(data && data.initialDepositPaymentModeNo) && !isEditable" />
            </div>
        </div>
        <h2 class="mt-5 sub-heading">Previous Attempt & Eligible Category Info</h2>
        <div fxLayout="row" fxLayoutAlign="space-between">
            <div style="width: 40%" class="row application-form-row">
                <label class="form-label">18. Category : <span class="text-danger"> * </span></label>
            </div>
        </div>
        <div style="width: 100%;" class="row application-form-row">
            <div *ngFor="let category of categories" class="col-sm-3  d-flex align-items-center">
                <input type="radio" [(ngModel)]="selectedCategoryId" class="radio-size ms-3"
                    [disabled]="(data && data.categoryId) && !isEditable" name="selectedCategoryId"
                    id="selectedCategoryId" (change)="categorySelected(category)" [value]="category.id" />
                <label class="font-weight-bold ml-3"> {{category.label}} </label>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between">
            <div class="row application-form-row">
                <label class="form-label">19. Are you residing in Karnataka for not less than 15 years prior to the date
                    of Registration? <span class="text-danger"> * </span></label>
                <input id="residesAbove15Yrs" type="radio" [(ngModel)]="domicile" class="ml-4 radio-size"
                    name="residesAbove15Yrs" [value]="'Yes'" [disabled]="(data && data.domicile) && !isEditable" />
                <label class="ml-1 mr-2 font-weight-bold ">Yes</label>
                <input id="residesAbove15Yrs" type="radio" [(ngModel)]="domicile" class="ml-2 radio-size"
                    name="residesAbove15Yrs" [value]="'No'" [disabled]="(data && data.domicile) && !isEditable" />
                <label class="ml-1 font-weight-bold ">No</label>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between">
            <div class="row application-form-row">
                <label style="height: auto; width: 80%;" class="form-label">20. Do you or your wife/husband or your
                    dependent children or your dependent parents or your dependent brothers/sisters own a site or a
                    house in Bangalore Metropolitan area or have been allotted a site or a house in the Bangalore
                    Metropolitan area by the erstwhile CITB, BDA, KHB, HBCS or any other authority
                    <span class="text-danger"> * </span></label>
                <input id="ownsSiteAllottedByAuthority" type="radio" [(ngModel)]="ownsSiteAllottedByAuthority"
                    class="ml-4 radio-size" name="ownsSiteAllottedByAuthority" [value]="'Yes'"
                    [disabled]="(data && data.ownSiteHouse) && !isEditable" />
                <label class="ml-1 mr-2 font-weight-bold ">Yes</label>
                <input id="ownsSiteAllottedByAuthority" type="radio" [(ngModel)]="ownsSiteAllottedByAuthority"
                    class="ml-2 radio-size" name="ownsSiteAllottedByAuthority" [value]="'No'"
                    [disabled]="(data && data.ownSiteHouse) && !isEditable" />
                <label class="ml-1 font-weight-bold ">No</label>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between">
            <div class="row application-form-row">
                <label class="form-label w-75">21. No of attempts made previously for allotment of site (excluding this
                    application): <span class="text-danger"> * </span></label>
                <input type="number" [(ngModel)]="noOfSiteAllotmentAttempts" (keyup)="constructNoOfSiteAllotmentTableSize()"
                    class="floated-textbox w-25" [disabled]="(data && data.previousAttempts >= 0 ) && !isEditable"
                    (keypress)="validateNumber($event)" />
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between">
            <div class="row application-form-row w-75">
                <label class="form-label w-75">22. Details of your previous attempts with BDA for allotment of site :
                    <span class="text-danger"> * </span></label>
                <label class="font-weight-bold">{{noOfSiteAllotmentAttempts}}</label>
            </div>
        </div>
        <table border="1">
            <thead>
                <tr>
                    <th>Sl. No.</th>
                    <th>Reg. No</th>
                    <th>Application No.</th>
                    <th>Date (dd/mm/yyyy)</th>
                    <th>Category</th>
                    <th>Dimension (AA X BB)</th>
                    <th>Initial Deposit</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let prevSiteTableData of previousSiteAllotmentTableDataList; let i = index">
                    <td>{{ i+1 }}</td>
                    <td><input type="text" class="borderless" [(ngModel)]="prevSiteTableData.registrationNo"
                            readOnly /></td>
                    <td><input type="text" class="borderless" [(ngModel)]="prevSiteTableData.applicationNo"
                            [readOnly]="data" /></td>
                    <td><input type="text" class="borderless" [(ngModel)]="prevSiteTableData.date"
                            [readOnly]="data" /></td>
                    <td><input type="text" class="borderless" [(ngModel)]="prevSiteTableData.category"
                            [readOnly]="data " /></td>
                    <td><input type="text" class="borderless" [(ngModel)]="prevSiteTableData.dimension"
                            readOnly /></td>
                    <td><input type="text" class="borderless" [(ngModel)]="prevSiteTableData.initialDeposit"
                            [readOnly]="data" /></td>
                </tr>
            </tbody>
        </table>
        <div fxLayout="row" fxLayoutAlign="space-between mt-2">
            <div class="row application-form-row w-100">
                <label style="height: auto; width: 65%;" class="form-label">23. Do you wish to be considered for
                    allotment under J Category:
                    <span class="text-danger"> * </span></label>
                <input id="allotmentUnderJCategory" type="radio" [(ngModel)]="allotmentUnderJCategory"
                    class="ml-4 radio-size" name="allotmentUnderJCategory" [value]="'Yes'"
                    [disabled]="(data && data.jcategory) && !isEditable" />
                <label class="ml-1 mr-2 font-weight-bold ">Yes</label>
                <input id="allotmentUnderJCategory" type="radio" [(ngModel)]="allotmentUnderJCategory"
                    class="ml-2 radio-size" name="allotmentUnderJCategory" [value]="'No'"
                    [disabled]="(data && data.jcategory) && !isEditable" />
                <label class="ml-1 font-weight-bold ">No</label>
            </div>
        </div>
        <div *ngIf="selectedMaritalStatus == 'Married'">
            <div fxLayout="row" fxLayoutAlign="space-between">
                <div class="row application-form-row w-75">
                    <label class="form-label w-100">24. If married, furnish the following details :</label>
                </div>
            </div>
            <div class="row application-form-row">
                <label class="form-label">a. Spouse’s Name: <span class="text-danger"> *
                    </span></label>
                <input type="text" [(ngModel)]="spouseName" class="form-textbox" oninput=" this.value = this.value.replace(/[0-9]/g,'')" 
                    [readonly]="(data && data.applicantName) && !isEditable" />
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between">
                <div style="width: 40%" class="row application-form-row">
                    <label class="form-label"> b. Date of Birth (dd:mm:yyyy) :
                        <span class="text-danger"> * </span></label>
                        <mat-form-field class="w-100 mat-form-resize h-75" appearance="outline">
                            <input matInput [matDatepicker]="spouseDatePicker" [(ngModel)]="spouseDateOfBirth"
                             [max]="todayDate" class="mat-date-default" [readonly]="!data" [disabled]="(data && data.spouseDateOfBirth)">
                            <mat-datepicker-toggle matIconSuffix [for]="spouseDatePicker" style="height: 40px;"></mat-datepicker-toggle>
                            <mat-datepicker #spouseDatePicker></mat-datepicker>
                        </mat-form-field>
                </div>
                <div style="width: 30%" fxFlexOffset="30" class="row application-form-row">
                    <label class="form-label">c. AADHAAR No : </label><span class="text-danger"> *
                    </span>
                    <input type="number" [(ngModel)]="spouseAadhaarNo" (keypress)="validateNumber($event, 12)"
                        class="form-textbox" [readonly]="(data && data.aadhaarNo) && !isEditable" />
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between">
                <div style="width: 30%" class="row application-form-row">
                    <label class="form-label w-100"> c. No. of Children :
                        <span class="text-danger"> * </span></label>
                    <input type="number" [(ngModel)]="noOfChildren" class="form-textbox w-50"
                        [readonly]="(data && data.noOfChildren) && !isEditable" (keypress)="validateNumber($event)" />
                </div>
                <div style="width: 50%" fxFlexOffset="20" class="row application-form-row">
                    <label class="form-label">d. Occupation : </label>
                    <input type="text" [(ngModel)]="spouseOccupation" class="form-textbox" oninput=" this.value = this.value.replace(/[0-9]/g,'')"
                        [readonly]="(data && data.spouseOccupation) && !isEditable" />
                </div>
            </div>
        </div>
        <h2 class="mt-5 sub-heading">Bank Info</h2>

        <div fxLayout="row" fxLayoutAlign="space-between">
            <div class="row application-form-row w-75">
                <label class="form-label w-100">25 Bank Info :</label>
            </div>
        </div>
        <div class="row application-form-row">
            <label class="form-label">Name of the Bank: <span class="text-danger"> *
                </span></label>
            <input type="text" [(ngModel)]="bankName" class="form-textbox"
                [readonly]="(data && data.bankName) && !isEditable" />
        </div>
        <div class="row application-form-row">
            <label class="form-label">Address of the Bank: <span class="text-danger"> *
                </span></label>
            <input type="text" [(ngModel)]="bankAddress" class="form-textbox"
                [readonly]="(data && data.bankAddress) && !isEditable" />
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between">
            <div style="width: 30%" class="row application-form-row">
                <label class="form-label w-100"> Pincode : <span class="text-danger"> * </span></label>
                <input type="number" [(ngModel)]="bankPincode" (keypress)="validateNumber($event,6)"
                    class="form-textbox w-75" [readonly]="(data && data.bankPincode) && !isEditable" />
            </div>
            <div style="width: 50%" fxFlexOffset="20" class="row application-form-row">
                <label class="form-label">IFSC Code :
                    <span class="text-danger"> * </span></label>
                <input type="text" [(ngModel)]="bankIfsc" class="form-textbox"
                    [readonly]="(data && data.bankIfsc) && !isEditable" />
            </div>
        </div>
        <div class="row application-form-row">
            <label class="form-label">Account No : <span class="text-danger"> *
                </span></label>
            <input type="text" [(ngModel)]="bankAccountNo" class="form-textbox"
                [readonly]="(data && data.bankAccountNo) && !isEditable" />
        </div>
        <div class="row">
            <div class="col-sm-6 application-form-row">
                <label class="form-label w-25"> AADHAAR No : </label>
                <input type="number" [(ngModel)]="aadharNumber" class="form-textbox w-75"
                    [readonly]="(data && data.aadhaarNo) && !isEditable" disabled />
            </div>
            <div class="col-sm-6 application-form-row d-flex text-end align-items-center">
                <label class="form-label w-25">Mobile No : +91</label>
                <input type="number" [(ngModel)]="mobileNo" (keypress)="validateNumber($event, 10)"
                    class="form-textbox w-75" [readonly]="(data && data.bankMobileNo) && !isEditable" />
            </div>
        </div>
        <div class="row application-form-row ">
            <label class="form-label">E-mail: </label>
            <input type="text" [(ngModel)]="email" class="form-textbox"
                [readonly]="(data && data.bankEmail) && !isEditable" />
        </div>
        <div fxLayout="row" style="margin-top: 20px">
            <button fxFlexOffset="84" class="btn btn-primary" (click)="openPreviewDialog()">Preview</button>
            <button fxFlexOffset="4" class="btn save-btn"  (click)="onSubmit()" [disabled]="data">Save</button>
        </div>
    </div>
</div>