<div class="content-area">
    <div class="col-sm-10 offset-sm-1">
  <app-top-bar selectedTab="home" [heading]="'Helpdesk Login'"></app-top-bar>
  <app-header></app-header>
  <div class="title">
    Welcome to Helpdesk Login
  </div>
  <div class="col-sm-6 offset-sm-3">
  <mat-card *ngIf="!otpStatus">
        <div class="col-sm-8 offset-sm-2">
            <div class="row application-form-row ">
                <label class="form-label">Choose Layout </label>
                <select class="form-textbox" [(ngModel)]="selectedLayout">
                 <!-- <option *ngFor = "let layouts of layout" [value]="layout.layoutName">{{layout.layoutName}}</option> -->
                 <option value="Dr. Shivarama Karanth Layout">Dr. Shivarama Karanth Layout</option>
                </select>
            </div>
        </div>
        <div class="col-sm-8 offset-sm-2">
            <div class="row application-form-row password-field">
                <label class="form-label">Enter Password : </label>
                <input [type]="passwordVisibility" [(ngModel)]="password" class="form-textbox"/>
                <fa-icon [hidden]="passwordVisibility == 'password'" [icon]="faEye" (click)="showPassword()" class="field-icon" ></fa-icon>
                <fa-icon [hidden]="passwordVisibility == 'text'" [icon]="faEyeSlash" (click)="hidePassword()" class="field-icon" ></fa-icon>
            </div>
        </div>
        <div class="col-sm-8 offset-sm-2">
                <div class="row application-form-row ">
                    <label class="form-label">Enter Registered Mobile Number: </label>
                    <input type="number" [(ngModel)]="mobileNo" class="form-textbox"/>
                </div>
            </div>
    
            <div class="row">
                <div class="col-sm-6 offset-sm-3">
                    <button class="btn btn-primary login-btn" (click)="generateOtp()">Generate OTP</button>
                </div>
            </div>
   

  </mat-card>
  <mat-card *ngIf="otpStatus">
    <div class="col-sm-8 offset-sm-2">
        <div class="row application-form-row ">
            <label class="form-label">Choose Layout </label>
            <input type="text" [(ngModel)]="selectedLayout" class="form-textbox" disabled/>
            <!-- <select class="form-textbox" [(ngModel)]="selectedNotification">
             <option *ngFor = "let notification of notifications" [value]="notification.notification_code">{{notification.notification}}</option>
            </select> -->
        </div>
    </div>
    <div class="col-sm-8 offset-sm-2">
            <div class="row application-form-row ">
                <label class="form-label">Enter 4 Digit OTP </label>
                <input type="number" [(ngModel)]="enteredOtp" [maxlength]="4" class="form-textbox"/>
            </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <button class="btn btn-primary submit-btn" (click)="submit()">Submit</button>
        </div>
            <div class="col-sm-6">
                <button class="btn btn-primary login-btn" (click)="generateOtp()">Regenerate OTP</button>
            </div>
        </div>


</mat-card>
</div>
  <app-footer></app-footer>
    </div>
    </div>

