import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
import { Observable } from 'rxjs';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StrorageService } from 'src/services/strorage.service';
import { ApplicationDetailsPreviewDialogComponent } from './application-details-preview-dialog/application-details-preview-dialog.component';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import moment, { Moment } from 'moment';
import { DateTimeUtils } from 'src/app/utils/date-utils';
import { CommonDataUtils } from 'src/app/utils/common-data-utils';
import { RoleConfigService } from 'src/app/shared/config/role/role.config.service';


@Component({
  selector: 'app-application-details',
  templateUrl: './application-details.component.html',
  styleUrls: ['./application-details.component.scss'],
})
export class ApplicationDetailsComponent implements OnInit {

  public data: any;

  public isEditable: any;

  public applicantName: string;

  public registrationNo: string;

  public fatherName: string;

  public applicantDob: Date;

  public mobileNumber: number;

  public city: string;

  public pinCode: number;

  public email: string;

  public aadharNumber: number;

  public selectedVillage;

  public applicantAddress: string;

  private applicantId: any;

  public siteNo: any;

  selectedFormTypeId: any;

  selectedDimensionId: any;

  initialDepositId: any;

  spouseAadhaarNo: any;

  public formTypeList: any[] = [];

  public dimensionList: any[] = [];

  public initialDepositList: any[] = [];

  public genderList: any[] = [];

  public maritialStatusList: any[] = [];

  public paymentModes: any[] = [];

  public categories: any[] = [];

  previousSiteAllotmentTableDataList: any[] = [];

  @Output() newApplicantCallback = new EventEmitter<any>();

  @Output() nextScreenCallback = new EventEmitter<any>();
  state: any;
  country: any;
  dateofRegister: any;
  villageId: any;
  notificationType: any;
  addressBox1: any;
  addressBox2: any;
  addressBox3: any;
  age: number;
  panNo: any;
  initialDepositAmount: any;
  initialDepositDate: any;
  selectedPaymentModeId: any;
  paymentModeNumber: any;
  genderId: any;
  maritialStatusId: any;
  occupation: any;
  annualIncome: any;
  dateOfBirth: any;
  previousFee: any;
  revFee: any;
  diffFee: any;
  allotmentUnderJCategory: any;
  newFee: any;
  domicile: any;
  ownsSiteAllottedByAuthority: any;
  noOfSiteAllotmentAttempts: any;
  spouseName: any;
  spouseDateOfBirth: any;
  aadhaarNo: any;
  noOfChildren: any;
  spouseOccupation: any;
  bankName: any;
  bankAddress: any;
  bankPincode: any;
  bankIfsc: any;
  mobileNo: any;
  bankAccountNo: any;
  selectedFormTypeName: any;
  selectedSiteDimensionName: any;
  selectedInitialDepositName: any;
  selectedGenderName: any;
  selectedMaritalStatus: string = '';
  previousSiteDataTemplate = {
    registrationNo: '',
    applicationNo: '',
    date: '',
    category: '',
    dimension: '',
    initialDeposit: ''
  }
  applicationNo: string;
  batchName: any;
  acknowledgementNo: any;
  selectedPaymentModeName: any;
  userLayoutData: any;
  siteAllotmentLayoutId: any;
  filteredOptions: Observable<string[]>;
  myControl = new FormControl('');
  batchNames: any[] = [];
  selectedBatchName: any;
  selectedCategoriesStr: string = '';
  fullAddress: any;
  selectedCategoryId: any;
  public todayDate: Moment = moment();
  selectedCategoryName: any;
  appNoPrefix: string;
  channelCode: string;
  loginType: string;
  isOnlineUser:boolean = false;

  constructor(private applicationService: ApplicationService,
    public dialog: MatDialog, private _snackBar: MatSnackBar,
    private route: ActivatedRoute, private router: Router, private roleConfig: RoleConfigService,
    private jccEncodeDecoder: JccnoEncodeDecodeService, private storageService: StrorageService) {
    this.userLayoutData = this.storageService.getUserLayoutData();
    let user = this.storageService.getUser();
    this.loginType = user['roleCode'];
  }

  ngOnInit(): void {
    this.applicantId = this.storageService.getItem('applicantId');
    this.validateUserTypeAndFetchResources();
  }

  validateUserTypeAndFetchResources = () => {
    this.fetchAllTemplates();
    if (this.userLayoutData) {
      this.siteAllotmentLayoutId = this.userLayoutData.id;
    }
    if (this.loginType == this.roleConfig.ROLE_ONLINE_USER) {
      this.isOnlineUser = true;
      this.applicationService.validateOnlineApplicantData().subscribe(res => {
        if (!res) {
          return;
        }
        this.applicantId = res.id;
        this.storageService.setItem(this.applicantId, 'applicantId');
        this.getApplicantById();
      })
    }else{
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
      );
      if (this.applicantId) {
        this.getApplicantById();
      }else{
        this.getBatchNames();
      }
      return;
    }
  }

  getBatchNames() {
    try {
      this.applicationService.getAllInwardBatchName().subscribe(res => {
        this.batchNames = res;
      }, err => {
        console.log(err);
        this.openSnackbar(err?.message, "Dismiss");
      })
    } catch (e) {
      this.openSnackbar(e, "Dismiss");
    }
  }

  setFormTypeData(formTypeObj: any) {
    this.applicationNo = '';
    this.appNoPrefix = CommonDataUtils.fetchApplicationNoPrefix(formTypeObj.label) + '- ';
    this.selectedFormTypeName = formTypeObj.label;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.batchNames.filter(option => option.toLowerCase().includes(filterValue));
  }

  setSiteDimensionData(siteDimensionObj: any) {
    this.selectedSiteDimensionName = siteDimensionObj.label;
    // populates on previous attempts table
    this.constructNoOfSiteAllotmentTableSize();
  }

  setInitialDepositData(initialDepositObj: any) {
    this.selectedInitialDepositName = initialDepositObj.label;
  }

  setGenderName(genderObj: any) {
    this.selectedGenderName = genderObj.label;
  }

  setMaritalStatusName(maritalStatusObj: any) {
    this.selectedMaritalStatus = maritalStatusObj.label;
  }

  setPaymentTypeName(event: any) {
    let selectedPaymentModeId = event.target.value;
    let selectedPaymentMode = this.paymentModes.find(paymentMode => paymentMode.id == selectedPaymentModeId);
    this.selectedPaymentModeName = selectedPaymentMode.paymentMode;
  }

  limitText = (size: number, value: string): boolean => {
    if (value.length >= size) {
      return false;
    }
  }


  public openPreviewDialog() {
    let data = null;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '80vh';
    dialogConfig.width = '80vw';
    dialogConfig.data = this.validateAndConstructPayload();
    const dialogRef = this.dialog.open(ApplicationDetailsPreviewDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
      console.log(res);

    })
  }

  categorySelected(category: any) {
    // if (category.checked) {
    //   let categoryData: any = {};
    //   categoryData.label = category.label;
    //   categoryData.categoryTypeId = category.id;
    //   categoryData.checked = true;
    //   this.selectedCategoryList.push(categoryData);
    // } else {
    //   for (let i = 0; i < this.selectedCategoryList.length; i++) {
    //     if (this.selectedCategoryList[i].categoryTypeId == category.id) {
    //       this.selectedCategoryList.splice(i, 1);
    //     }
    //   }
    // }
    // this.checkSelectedCategories();
    // console.log('categoryList', this.selectedCategoryList);
    this.selectedCategoryName = category.label;
  }

  constructNoOfSiteAllotmentTableSize = () => {
    this.previousSiteAllotmentTableDataList = [];
    for (let i = 0; i < 10; i++) {
      this.previousSiteAllotmentTableDataList.push({ registrationNo: this.registrationNo, applicationNo: '', date: '', category: '', dimension: this.selectedSiteDimensionName, dimensionId: this.selectedDimensionId, initialDeposit: '' });
    }
    console.log("tableDataList", this.previousSiteAllotmentTableDataList);
  }

  fetchAllTemplates = () => {
    this.applicationService.getApplicationDetailsTemplates().subscribe(res => {
      this.formTypeList = res.applicationFormTypeList;
      this.dimensionList = res.siteDimensionList;
      this.initialDepositList = res.initialDepositList;
      this.genderList = res.genderList;
      this.maritialStatusList = res.maritalStatusList;
      this.paymentModes = res.paymentModeList;
      this.categories = res.categoryTypeList;
    })
  }

  calculateAgeFromDate(dateStr: any) {
    this.age = CommonDataUtils.calculateAgeInYears(dateStr);
  }

  constructPayloadData() {
    let payload = {};
    if (this.data) {
      payload['applicationNo'] = this.applicationNo;
    } else {
      payload['applicationNo'] = this.appNoPrefix + this.applicationNo;
    }
    if(!this.isOnlineUser){
      payload['batchName'] = this.selectedBatchName;
    }
    payload['siteAllotmentLayoutId'] = this.siteAllotmentLayoutId;
    payload['applicationFormTypeId'] = this.selectedFormTypeId;
    payload['siteDimensionId'] = this.selectedDimensionId;
    payload['initialDepositId'] = this.initialDepositId;
    payload['applicantName'] = this.applicantName;
    payload['familyMemberName'] = this.fatherName;
    let address1 = this.addressBox1;
    let address2 = this.addressBox2;
    let address3 = this.addressBox3;
    this.fullAddress = address1 + (address2 ? ', ' + address2 : '') + (address3 ? ', ' + address3 : '')
    payload['applicantAddress'] = this.fullAddress;
    payload['city'] = this.city;
    payload['pinCode'] = this.pinCode;
    payload['age'] = this.age;
    payload['dateOfBirth'] = DateTimeUtils.formatDateToStr(this.dateOfBirth);
    payload['genderId'] = this.genderId;
    payload['maritalStatusId'] = this.maritialStatusId;
    payload['occupation'] = this.occupation;
    payload['annualIncome'] = this.annualIncome;
    payload['panCardNo'] = this.panNo;
    payload['aadhaarNo'] = this.aadharNumber;
    payload['registrationNo'] = this.registrationNo;
    payload['registrationDate'] = DateTimeUtils.formatDateToStr(this.dateofRegister);
    payload['prevRegistrationFee'] = this.previousFee;
    payload['revisedRegistrationFee'] = this.revFee;
    payload['diffRegistrationFeePaid'] = this.diffFee;
    payload['newRegistrationFee'] = this.newFee;
    payload['initialDepositAmount'] = this.initialDepositAmount;
    payload['initialDepositDate'] = DateTimeUtils.formatDateToStr(this.initialDepositDate);
    payload['noOfSiteAllotmentAttempts'] = this.noOfSiteAllotmentAttempts;
    payload['initialDepositPaymentId'] = this.selectedPaymentModeId;
    payload['initialDepositPaymentModeNo'] = this.paymentModeNumber;
    payload['domicile'] = this.domicile;
    payload['ownSiteHouse'] = this.ownsSiteAllottedByAuthority;
    payload['previousAttempts'] = this.noOfSiteAllotmentAttempts;
    payload['jcategory'] = this.allotmentUnderJCategory;
    if (this.selectedMaritalStatus == 'Married') {
      payload['spouseDOB'] = DateTimeUtils.formatDateToStr(this.spouseDateOfBirth);
      payload['spouseName'] = this.spouseName;
      payload['spouseAadhaarNo'] = this.spouseAadhaarNo;
      payload['spouseNoOfChildren'] = this.noOfChildren;
    }
    payload['bankName'] = this.bankName;
    payload['bankPinCode'] = this.bankPincode;
    payload['bankAddress'] = this.bankAddress;
    payload['bankIfscCode'] = this.bankIfsc;
    payload['bankAccountNo'] = this.bankAccountNo;
    payload['aadharNumber'] = this.aadharNumber;
    payload['bankMobileNo'] = this.mobileNo;
    payload['bankEmail'] = this.email;
    payload['previousSiteAllotments'] = this.previousSiteAllotmentTableDataList;
    payload['categoryTypeId'] = this.selectedCategoryId;
    payload['formTypeName'] = this.selectedFormTypeName;
    payload['siteDimensionName'] = this.selectedSiteDimensionName;
    payload['initialDepositName'] = this.selectedInitialDepositName;
    payload['maritalStatus'] = this.selectedMaritalStatus;
    payload['selectedCategoryName'] = this.selectedCategoryName;
    payload['gender'] = this.selectedGenderName;
    payload['paymentMode'] = this.selectedPaymentModeName;
    return payload;
  }

  validateAndConstructPayload = () => {
    if (!this.applicationNo) {
      throw new Error('Application No cannot be empty!');
    }
    if (!this.isOnlineUser && !this.selectedBatchName) {
      throw new Error('Batch name cannot be empty!');
    }
    if (!this.selectedFormTypeId) {
      throw new Error('Please choose a Application form Type!');
    }
    if (!this.selectedDimensionId) {
      throw new Error('Please choose Site dimensions!');
    }
    if (!this.siteAllotmentLayoutId) {
      throw new Error('Site Allotment Layout Id cannot be empty!');
    }
    if (!this.initialDepositId) {
      throw new Error('Please choose Initial Deposit!');
    }
    if (!this.applicantName) {
      throw new Error('Applicant name field must not be empty');
    }
    if (!this.fatherName) {
      throw new Error("Father's/Husband's Name field must not be empty");
    }
    if (!this.addressBox1 && !this.addressBox2 && !this.addressBox3) {
      throw new Error(' Address field must not be empty');
    }
    if (!this.city) {
      throw new Error('city field must not be empty');
    }
    if (this.pinCode > 999999 || this.pinCode < 100000) {
      throw new Error('Please enter a valid Pin Code');
    }
    if (!this.dateOfBirth) {
      throw new Error(' Date of Birth field must not be empty');
    }
    if (!this.genderId) {
      throw new Error(' Gender field must not be empty');
    }
    if (!this.maritialStatusId) {
      throw new Error(' Marital Status field must not be empty');
    }
    if (!this.occupation) {
      throw new Error(' Occupation field must not be empty');
    }
    if (!this.annualIncome) {
      throw new Error('Annual Income field must not be empty');
    }
    if (!this.panNo || this.panNo.length != 10) {
      throw new Error('Please enter a valid Pan Card Number');
    }
    if (!this.aadharNumber) {
      throw new Error('Please enter a valid Aadhaar number');
    }
    if (this.aadharNumber > 999999999999 || this.aadharNumber < 100000000000) {
      throw new Error('Please enter a valid Aadhaar number');
    }
    if (!this.registrationNo) {
      throw new Error('Register Number field must not be empty');
    }
    if (!this.dateofRegister) {
      throw new Error(' Registration Date field must not be empty');
    }
    if (this.bankPincode > 999999 || this.bankPincode < 100000) {
      throw new Error('Please enter a valid Bank Pin Code');
    }
    if (!this.initialDepositAmount) {
      throw new Error(' Initial Deposit field must not be empty');
    }
    if (!this.initialDepositDate) {
      throw new Error(' Initial Deposit Date field must not be empty');
    }
    if (!this.selectedPaymentModeId) {
      throw new Error(' Initial Deposit Payment Mode field must not be empty');
    }
    if (!this.paymentModeNumber) {
      throw new Error(' Payment Mode No field must not be empty');
    }
    if (!this.selectedCategoryId) {
      throw new Error("Please select an ELigible Category!");
    }
    if (!this.domicile) {
      throw new Error(' Choose whether Applicant resides for less than 15 Years prior to Regn Date!');
    }
    if (!this.ownsSiteAllottedByAuthority) {
      throw new Error(' Choose whether Applicant owns site alloted by provided Authorities!');
    }
    if (this.noOfSiteAllotmentAttempts == null) {
      throw new Error(' No of Attempts field must not be empty');
    }
    if (this.noOfSiteAllotmentAttempts < 0) {
      throw new Error(' No of Attempts field must 0 or greater than 0');
    }
    if (!this.allotmentUnderJCategory) {
      throw new Error(' Allotment under J Category field must not be empty');
    } if (this.selectedMaritalStatus.toLowerCase() == 'married') {
      if (!this.spouseName) {
        throw new Error(' Spouse Name field must not be empty');
      }
      if (!this.spouseDateOfBirth) {
        throw new Error(' Spouse Date of Birth field must not be empty');
      }
      if (this.spouseAadhaarNo > 999999999999 || this.spouseAadhaarNo < 100000000000) {
        throw new Error('Please enter a valid Spouse Aadhaar number');
      }
      if (this.noOfChildren == null) {
        throw new Error(' No of Children field must not be empty');
      }
    }
    if (!this.bankName) {
      throw new Error(' Bank Name field must not be empty');
    }
    if (!this.bankAddress) {
      throw new Error(' Bank Address field must not be empty');
    }
    if (!this.bankPincode) {
      throw new Error(' Bank Pincode field must not be empty');
    }
    if (!this.bankIfsc) {
      throw new Error(' IFSC code is required and must not be empty');
    }
    if (!this.bankAccountNo) {
      throw new Error(' Bank Account No field must not be empty');
    }
    if (this.email) {
      let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!emailRegex.test(this.email)) {
        throw new Error('Please enter a valid email address');
      }
    }
    return this.constructPayloadData();
  }


  setValue(data: any): void {
    console.log('data ', data)
    this.applicationNo = data.applicationNo;
    this.selectedBatchName = data.batchName;
    this.acknowledgementNo = data.acknowledgementNo;
    this.selectedFormTypeId = data.formTypeId;
    this.selectedFormTypeName = data.formTypeName;
    this.selectedDimensionId = data.dimensionId;
    this.selectedSiteDimensionName = data.siteDimensionName;
    this.initialDepositId = data.initialDepositId;
    this.selectedInitialDepositName = data.initialDepositName;
    this.registrationNo = data.applicationNo;
    this.age = data.age;
    this.applicantName = data.applicantName;
    this.fatherName = data.familyMemberName;
    this.city = data.city;
    let addressArr = [];
    addressArr = data.applicantAddress.toString().split(',', 3);
    this.addressBox1 = addressArr[0] ? addressArr[0] : '';
    this.addressBox2 = addressArr[1] ? addressArr[1] : '';
    this.addressBox3 = addressArr[2] ? addressArr[2] : '';
    this.genderId = data.genderId;
    this.pinCode = data.pinCode;
    this.maritialStatusId = data.maritalStatusId;
    this.selectedPaymentModeId = data.paymentModeId;
    this.occupation = data.occupation;
    this.annualIncome = data.annualIncome;
    this.panNo = data.panCardNo;
    this.aadharNumber = data.aadhaarNo;
    this.registrationNo = data.registrationNo;
    this.dateofRegister = moment(data.registrationDate, 'DD/MM/YYYY');
    this.previousFee = data.prevRegistrationFee;
    this.revFee = data.revRegistrationFee;
    this.diffFee = data.diffRegistrationFee;
    this.newFee = data.newRegistrationFee;
    this.initialDepositAmount = data.initialDepositAmount;
    this.initialDepositDate = moment(data.initialDepositDate, 'DD/MM/YYYY');
    this.paymentModeNumber = data.initialDepositPaymentModeNo;
    this.domicile = data.domicile;
    this.ownsSiteAllottedByAuthority = data.ownSiteHouse;
    this.noOfSiteAllotmentAttempts = data.previousAttempts;
    this.constructNoOfSiteAllotmentTableSize();
    this.bankName = data.bankName;
    this.bankAddress = data.bankAddress;
    this.bankPincode = data.bankPincode;
    this.bankIfsc = data.bankIfsc;
    this.bankAccountNo = data.bankAccountNo;
    this.email = data.bankEmail;
    this.mobileNo = data.bankMobileNo;
    this.allotmentUnderJCategory = data.jcategory;
    this.dateOfBirth = moment(data.dateOfBirth, 'DD/MM/YYYY');
    this.selectedMaritalStatus = data.maritalStatus;
    this.spouseName = data.spouseName;
    this.spouseAadhaarNo = data.spouseAadhaarNo;
    this.noOfChildren = data.noOfChildren;
    this.spouseOccupation = data.spouseOccupation;
    this.spouseDateOfBirth = moment(data.spouseDateOfBirth, 'DD/MM/YYYY');
    this.selectedPaymentModeName = data.paymentMode;
    this.selectedCategoryId = data.categoryId;
    this.selectedCategoryName = data.categoryName;
    this.selectedGenderName = data.gender;
    this.previousSiteAllotmentTableDataList = data.previousSiteAllotments;
  }

  onSubmit = () => {
    if (this.data) {
      this.applicantId = this.storageService.getItem('applicantId');
    }
    this.openDialog();
  }

  openSnackbar(message: string, action?: string) {
    if (!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }

  openDialog = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '160px';
    dialogConfig.width = '450px';
    dialogConfig.data = "Are you sure want to submit this record ?"
    const dialogRef = this.dialog.open(ConfirmationModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.saveApplicantBasicDetailsApiCall();
      }
    })
  }

  saveApplicantBasicDetailsApiCall = () => {
    try {
      let payload = this.validateAndConstructPayload();
      this.applicationService.saveApplicationDetails(payload).subscribe(res => {
        this.openSnackbar("Successfully Saved", "Dismiss");
        this.storageService.setItem(res.id, 'applicantId');
        this.applicantId = res.id;
        this.getApplicantById();
      }, err => {
        console.log(err);
        // this.openSnackbar(err?.message, "Dismiss");
      })
    } catch (e) {
      this.openSnackbar(e, "Dismiss");
    }
  }

  public validateNumber = ($event: any, limit?: number): boolean => {
    const charCode = ($event.which) ? $event.which : $event.charCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43) {
      return false;
    }
    if (limit) {
      return this.limitText(limit, $event.target.value);
    }
    return true;
  }

  getApplicantById = () => {
    try {
      this.applicationService.getApplicantById(this.applicantId).subscribe(res => {
        this.openSnackbar("Application " + res.acknowledgementNo + " loaded!", "Dismiss");
        this.updateUI(res);
      }, err => {
        console.log(err);
      })
    } catch (e) {
      this.openSnackbar(e, "Dismiss");
    }
  }

  private updateUI = (res: any) => {
    this.applicantId = res.id;
    this.data = res;
    this.setValue(res);
  }
  reset() {
    this.dateofRegister = null;
    this.mobileNumber = null;
    this.addressBox1 = null;
    this.addressBox2 = null;
    this.addressBox3 = null;
    this.state = null;
    this.city = null;
    this.pinCode = null;
  }

}
