import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-leadership-dashboard',
  templateUrl: './leadership-dashboard.component.html',
  styleUrls: ['./leadership-dashboard.component.scss']
})
export class LeadershipDashboardComponent implements OnInit {

  public isRefreshing = false;

  public faSync = faSync;
user: any;


  constructor(private storageService: StrorageService, private router: Router) { 
    this.user = this.storageService.getUser();

  }

  ngOnInit(): void {
    
  }

  navigateToActiveStatusPage(){
    this.router.navigate(['/active-status-dashboard']);
  }

  navigateToSiteStatusPage(){
    this.router.navigate(['/site-status-dashboard']);
  }

  navigateToSiteMatrixDataPage(){
    this.router.navigate(['/leadership-site-matrix-data']);
  }

  refreshClick(){
    let currentComponent = this;
    this.isRefreshing = true;
    setTimeout(()=>{
      currentComponent.isRefreshing = false;
    }, 3000)
  }

}
