<div class="container">
    <div class="row d-flex justify-content-center mt-5">
        <h1>BDA-SKL website – Landing page</h1>
    </div>
    <div class="row">
        <div class="col-sm-12 mt-4"> <h3>Lorem ipsum Lorem ipsum </h3> </div>
        <div class="col-sm-12 mt-4"> <h3>Lorem ipsum Lorem ipsum </h3> </div>
        <div class="col-sm-12 mt-4"> <h3>Lorem ipsum Lorem ipsum </h3> </div>
        <div class="col-sm-12 mt-4"> <h3>Lorem ipsum Lorem ipsum </h3> </div>
    </div>
    <div class="row" style="height: 3.4rem;">
        <div class="col-sm-6">
            <button class="apply-dskl-btn w-100 h-100" (click)="navigateToInternLogin()">Click here to apply for BDA sites in <br> Dr.Shivarama Karanth Layout</button>
        </div>
        <div class="col-sm-6">
            <button class="dj-com-btn w-100 h-100" (click)="navigateToHomePage()">Click here to login to DJ Committee </button>
        </div>
    </div>
</div>
