import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';
import { UserService } from 'src/services/user-service/user.service';
import { RoleConfigService } from '../shared/config/role/role.config.service';
import { AuthService } from '../shared/services/auth/auth.service';

@Component({
  selector: 'app-generic-login',
  templateUrl: './generic-login.component.html',
  styleUrls: ['./generic-login.component.scss']
})
export class GenericLoginComponent implements OnInit {

  panchayats: any[];

  selectedPanchayat: number;

  password: string;

  mobileNo: number;

  enteredOtp: string;

  loginType: any;

  pageHeading: any;

  faEye = faEye;

  faEyeSlash = faEyeSlash;

  passwordVisibility = 'password';

  selectedLayoutId: any;

  public layouts: any[] = [];
  selectedLayoutName: any;

  otpRequired: boolean = true;

  constructor(private applicationService: ApplicationService, private authService: AuthService,
    private _snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute, private userService: UserService,
    private roleConfig: RoleConfigService, private storageService: StrorageService) {
    this.loginType = this.route.snapshot.queryParams['loginType']
  }

  ngOnInit(): void {
    this.fetchLayouts();
    this.setPageHeading();
    let user = this.storageService.getUser();
    let accessToken = this.storageService.getAccessToken();
    if (user && user['accessToken']) {
      this.loginType = user['roleCode'];
      this.navigateToWorkbench();
    }
  }

  setPageHeading() {
    if (this.loginType == this.roleConfig.ROLE_HELP_DESK_USER) {
      this.pageHeading = "Welcome to Helpdesk Login";
    }
    if (this.loginType == this.roleConfig.ROLE_APPLICATION_INWARD_USER) {
      this.pageHeading = "Welcome to Application Inward Login";
      this.otpRequired = false;
    }
    if (this.loginType == this.roleConfig.ROLE_LEADERSHIP_USER) {
      this.pageHeading = "Welcome to Leadership Login";
      this.otpRequired = false;
    }
    if (this.loginType == this.roleConfig.ROLE_ALLOTMENT_USER) {
      this.pageHeading = "Welcome to Allotment Login";
    }
    if (this.loginType == this.roleConfig.ROLE_ONLINE_USER) {
      this.pageHeading = "Welcome to Online Application Login";
      this.otpRequired = false;

    }
  }

  generateOtp() {
    this.storageService.setItem(this.mobileNo, 'loginMobileNo');
    if (!this.isValidLogin()) {
      return;
    }
    let payload = {};
    payload['siteAllotmentLayoutId'] = this.selectedLayoutId;
    payload['mobile'] = this.mobileNo;
    payload['password'] = this.password;
    payload['loginType'] = this.loginType;
    this.authService.login(payload, this.loginSuccess);
  }

  submit() {
  }

  navigateToWorkbench() {
    if (this.loginType == this.roleConfig.ROLE_HELP_DESK_USER) {
      this.router.navigate(['/application_login']);
    }
    if (this.loginType == this.roleConfig.ROLE_APPLICATION_INWARD_USER) {
      this.router.navigate(['/application-inward-workbench']);
    }
    if (this.loginType == this.roleConfig.ROLE_LEADERSHIP_USER) {
      this.router.navigate(['/leadership-dashboard']);
    }
    if (this.loginType == this.roleConfig.ROLE_ALLOTMENT_USER) {
      this.router.navigate(['/allotment-dashboard']);
    }
  }

  setLayoutName(layoutId: number) {
    for (let i = 0; i < this.layouts.length; i++) {
      if (this.layouts[i].id == layoutId) {
        this.selectedLayoutName = this.layouts[i].label;
      }
    }
  }

  loginSuccess = (res: any): void => {
    console.log("login api", res);
    if (res && res.success) {
      let layoutData = {
        id: this.selectedLayoutId,
        name: this.selectedLayoutName
      }
      this.storageService.setItem(JSON.stringify(layoutData), 'user_layout');
      console.log(res);
      if (this.loginType == this.roleConfig.ROLE_HELP_DESK_USER 
        || this.loginType == this.roleConfig.ROLE_ALLOTMENT_USER) {
        this.router.navigate(['/generate-otp'], {
          queryParams: {
            userId: res.data.id, siteAllotmentLayoutName: layoutData.name,
            pageHeading: this.pageHeading, loginType: this.loginType
          }
        });
        return;
      } else {
        this.navigateToWorkbench();
      }

    }else {
      this.openSnackbar('Invalid credentials', 'Dismiss');
    }
  }

  isValidLogin(): boolean {
    if (!this.mobileNo) {
      this.openSnackbar('Please enter mobile number', 'Dismiss');
      return false;
    }
    if (!this.password) {
      this.openSnackbar('Please enter password', 'Dismiss');
      return false;
    }
    return true;
  }

  fetchLayouts = () => {
    try {
      this.applicationService.getSiteAllotmentLayouts().subscribe(res => {
        this.layouts = res;
      }, err => {
        console.log(err);
        this.openSnackbar(err?.message, "Dismiss");
      })
    } catch (e) {
      this.openSnackbar(e, "Dismiss");
    }
  }


  openSnackbar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  showPassword() {
    this.passwordVisibility = 'password'
  }

  hidePassword() {
    this.passwordVisibility = 'text';
  }

}
