import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-edit-application-inward-workbench',
  templateUrl: './edit-application-inward-workbench.component.html',
  styleUrls: ['./edit-application-inward-workbench.component.scss']
})
export class EditApplicationInwardWorkbenchComponent implements OnInit {

  public inwardId: any;

  public user: any;

  userLayoutData: any;

  public nextBatchName: '';

  public sourceList: any[] = [];

  public selectedSourceId: number = undefined;

  public applicationCount: number;

  inwardData: any;

  constructor(public dialog: MatDialog, private storageService: StrorageService, private applicationService: ApplicationService,
    private _snackBar: MatSnackBar, private router: Router, private route : ActivatedRoute) {
    this.inwardId = this.route.snapshot.queryParams['inwardId'];
  }

  ngOnInit(): void {
    this.user = this.storageService.getUser();
    this.userLayoutData = this.storageService.getUserLayoutData();
    this.fetchBankSources();
    this.fetchInwardDateById();
  }

  fetchBankSources = () => {
    try {
      this.applicationService.getBankSources().subscribe(res => {
        this.sourceList = res;
      }, err => {
        console.log(err);
        this.openSnackbar(err?.message, "Dismiss");
      })
    } catch (e) {
      this.openSnackbar(e, "Dismiss");
    }
  }

  fetchInwardDateById = () => {
    try {
      this.applicationService.getInwardDataById(this.inwardId).subscribe(res => {
        this.inwardData = res;
        this.updateUI(res);
      }, err => {
        console.log(err);
        this.openSnackbar(err?.message, "Dismiss");
      })
    } catch (e) {
      this.openSnackbar(e, "Dismiss");
    }
  }

  updateApplicationInward = () => {
    let payload = this.validateAndConstructPayload();
    try {
      this.applicationService.updateApplicationInward(this.inwardId, payload).subscribe(res => {
        if (res) {
          this.navigateToActivityLogs();
        }
      }, err => {
        console.log(err);
        this.openSnackbar(err?.message, "Dismiss");
      })
    } catch (e) {
      this.openSnackbar(e, "Dismiss");
    }
  }

  navigateToActivityLogs() {
    this.router.navigate(['/application-inward-activitylog']);
  }

  openSnackbar(message: string, action?: string) {
    if (!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }

  openDialog = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '200px';
    dialogConfig.width = '520px';
    dialogConfig.data = "Confirm Update – Batch no- " + this.nextBatchName + " & Batch Size: " + this.applicationCount;
    const dialogRef = this.dialog.open(ConfirmationModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.updateApplicationInward();
      }
    })
  }

  validateAndConstructPayload = () => {
    let payload = {};
    if (!this.applicationCount) {
      throw new Error('Applicant Count field must not be empty');
    }
    if (!this.selectedSourceId) {
      throw new Error('Source field must not be empty');
    }
    payload['applicationCount'] = this.applicationCount;
    payload['bankId'] = this.selectedSourceId;
    return payload;
  }

  onSubmit = () => {
    this.openDialog();
  }

  updateUI(res: any) {
    this.applicationCount = res.applicationCount;
    this.selectedSourceId = res.bankId;
    this.nextBatchName = res.batchName;
  }

  clickBack() {
    this.router.navigate(['/application-inward-workbench']);
  }

}
