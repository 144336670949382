<div class="col-sm-10 offset-sm-1">
    <app-top-bar selectedTab="home"></app-top-bar>
    <app-header></app-header>
    <div class="col-sm-4 offset-sm-8 user-details">
  
        <div class="value-text">
            Welcome 
        </div>
        <div class="value-text">
             {{userName}} !
        </div>
  
      </div>
  
  <div class="col-sm-4 offset-sm-4">
    <form class="example-form">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>JCC Number</mat-label>
          <input matInput placeholder="Enter jcc number here.." autocomplete="off"  [(ngModel)]="applicationNo" name="applicationNo">
        </mat-form-field>
        <label class="text-case-sensitive">Case Sensitive *</label>
        <button  mat-raised-button color="primary" >Select and Edit</button>
        </form>
  </div>
  <app-footer></app-footer>
  </div>