<div class="row d-flex justify-content-center">
    <div class="w-50 count-heading" [ngStyle]="{'background-color':countData.headerColor}">
        {{countData.headerLabel}} <br> ({{countData.headerCount}}) 
    </div>
</div>
<div class="row d-flex justify-content-between mt-1">
    <div *ngFor="let count of dimensionCounts, let i = index" class="col-sm-4 mt-3 d-flex justify-content-center">
        <div class="w-75 count-box" [ngStyle]="{'background-color':colors[i]}">
            {{count.label}} <br> ({{count.count}}) 
        </div>
    </div>
</div>