import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-landloser-letter-printing-workbench',
  templateUrl: './landloser-letter-printing-workbench.component.html',
  styleUrls: ['./landloser-letter-printing-workbench.component.scss']
})
export class LandloserLetterPrintingWorkbenchComponent implements OnInit {

user:any;

ecNo:string;

siteDataList: any[]= [];

public isRefreshing = false;

showDoneBtn : boolean = false;

public faSync = faSync;
  ecPrintingData: any = {};
  ecId: any;
  allotmentTypeId : number = 0;
  selectedVillageName: any;

  constructor(private storageService:StrorageService, private _snackBar:MatSnackBar,
     public dialog: MatDialog, private router: Router, private route : ActivatedRoute,
     public applicationService: ApplicationService) {
    this.user = this.storageService.getUser();
    this.allotmentTypeId = this.route.snapshot.queryParams['allotmentTypeId'];
    this.ecNo = this.route.snapshot.queryParams['ecNo'];
   }

  ngOnInit(): void {
    this.getEcPrintingData();
  }

  onPrint = (idx:number) => {
    try{
      this.applicationService.printSiteAndUpdateStatus(this.siteDataList[idx].siteNo).subscribe(res => {
        if(res){
          this.openSnackbar("Printed site "+this.siteDataList[idx].siteNo+"!", "Dismiss");
          this.getEcPrintingData();
          for(let siteData of this.siteDataList){
            if(siteData.printed == false){
              return;
            }
          }
          this.showDoneBtn = true;
        }
      });
    }catch (err){

    }
  }

  getEcPrintingData = () => {
    this.applicationService.fetchEcPrintingDetails(this.ecNo).subscribe(res => {
      this.ecPrintingData = res;
      console.log("printing data", res);
      this.ecId = res.ecId;
      this.selectedVillageName = res.villageName;
      this.ecNo = res.ecNo;
      this.siteDataList = res.siteDataList;
      for(let siteData of this.siteDataList){
        if(siteData.printed == false){
          return;
        }
      }
      this.showDoneBtn = true;
    });
  }

  openDialog = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '180px';
    dialogConfig.width = '450px';
    dialogConfig.data = "Village: "+ this.selectedVillageName + " " +this.ecNo +", successfully serviced.Do you want to continue?";
    const dialogRef = this.dialog.open(ConfirmationModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.saveSiteLetterPrinting();
      }
    })
  }

  saveSiteLetterPrinting(){
    this.applicationService.printEcDetails(this.ecId).subscribe(res => {
        if(res){
          this.router.navigate(['/allotment-dashboard']);
        }
    });
  }

  refreshClick() {
    let currentComponent = this;
    this.isRefreshing = true;
    setTimeout(()=>{
      currentComponent.isRefreshing = false;
    }, 3000)
  }

  clickBack(){
    window.history.back();
  }

  openSnackbar(message: string, action: string){
    this._snackBar.open(message, action);
  }

}
