import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { UserService } from 'src/services/user-service/user.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  constructor(private applicationService : ApplicationService,  private jccEncodeDecoder: JccnoEncodeDecodeService, private router: Router, private userService: UserService) { 
    // if(this.isOnlineApplicationUser()){
    //   this.router.navigate(['/'])
    // }
    if(this.userService.getUserType() != this.userService.ADMIN_USER){
      this.router.navigate(['/admin-login'])
    }    
  }

  public applicationNo;
 
  public selectedApplication;

  public userName = "Username";


  ngOnInit() {
     this.userName = this.userService.getUserName();
  }

  isOnlineApplicationUser(){
    return this.userService.getUserType() == this.userService.ONLINE_APPLICATION_USER;
  }


  selectOption(option){
    let applicationNumber = this.jccEncodeDecoder.encode(option);
    this.router.navigate(['/application_login'], { queryParams: { applicationNo:  applicationNumber} });
  }

  // searchApplication(){
  //   if(this.applicationNo && this.applicationNo.length<=0){
       
  //   }
  //   this.applicationService.overrideApplication(this.applicationNo?.trim()).subscribe(res =>{
  //     let applicationNumber = this.jccEncodeDecoder.encode(res.applicantNo);
  //     this.router.navigate(['/application_login'], { queryParams: { applicationNo:  applicationNumber} });
  //   })
  // }

}
