<div class="container">
    <div class="row">
        <div class="offset-sm-2 col-sm-8">
            <h1 class="mt-5 text-center">Site Matrix – Dr. Shivarama Karanth Layout - {{selectedVillageName}}</h1>

        </div>
        <img class="col-sm-2" src="../../../assets/bda-logo.png" alt="..">
    </div>
    <div class="row">
        <div class="col-sm-1">
            <button class="btn btn-primary refresh-btn w-100" (click)="refreshClick()">
                <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
            </button>
        </div>
        <div class="col-sm-3 offset-sm-8">
            <mat-form-field>
              <mat-label>Village </mat-label>
              <mat-select id="Villages" name="Villages" [(ngModel)]="selectedVillageId"
                (selectionChange)="villageChanged(selectedVillageId)">
                <mat-option *ngFor="let village of villageList" [value]="village.id">{{village.id+'
                  '+village.villageName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
    </div>
    <div class="row mt-4">
        <div class="col-sm-2 status-label">
            Available - ({{availableSitesCount}})
        </div>
        <div class="col-sm-1 w-75 available-cell"></div>
    </div>
    <div class="row mt-2">
        <div class="col-sm-2 status-label">
            Allotted - ({{allottedSitesCount}})
        </div>
        <div class="col-sm-1 w-75 allotted-cell"></div>
    </div>
    <div class="row pb-5">
        <div *ngFor="let siteData of siteDataList; " class="col-sm-6 mt-5">
            <h3 class="w-100 fw-bold">
                {{siteData.dimensionName}}
            </h3>
            <div class="row">
                <ng-container *ngFor="let siteStatus of siteData.siteDTOList" class="col-sm-2">
                    <div class="site-data-cell" [ngClass]="siteStatus.siteStatus == 'available' ? 'available-cell' : 'allotted-cell'">
                        {{siteStatus.siteNumber}}
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>