<app-top-bar> </app-top-bar>
<div class="container">
 <app-header></app-header>
  <div class="my-5">
    <div class="row">
      <div class="title" style="margin-left: 35%;">
        Site Allotment Workflow
      </div>
      <div class="col-sm-2 offset-sm-3 user-details">
        <div>
          <div class="row value-text">
            Welcome! <br> {{user['firstName']}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="my-5">
    <div class="row d-flex justify-content-center">
      <div class="col-sm-3">
        <button class="total-btn btn" (click)="onTotalBtnClicked()" type="button"> Total </button>
      </div>
      <div *ngFor="let allotmentType of allotmentTypeList; let i = index" class="col-sm-3">
        <button class="allotment-type-btn btn" (click)="allotmentTypeClickHandler(allotmentType)"
        [style.background-color] = "colorList[i]" type="button"> {{allotmentType.allotmentType}} </button>
      </div>
    </div>
    <div *ngIf="landLoserClicked" class="row mt-3 d-flex justify-content-start">
      <div class="offset-sm-1 col-sm-2">
        <button class="site-allotment-btn btn" (click)="onSiteAssignmentBtnClicked()" type="button"> Site Assignment
        </button>
      </div>
      <div class="col-sm-2">
        <button class="site-mapping-btn btn" (click)="onSiteMappingBtnClicked()" type="button"> Site Mapping</button>
      </div>
      <div class="col-sm-2">
        <button class="allotment-letter-btn btn" (click)="onAllotmentLetterBtnClicked()" type="button"> Allotment Letter
          <br> Printing </button>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>