import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { EcStatusService } from 'src/app/shared/config/status-constants/ec-status.service';
import { ApplicationService } from 'src/services/application.service';

@Component({
  selector: 'app-landloser-site-mapping-workbench',
  templateUrl: './landloser-site-mapping-workbench.component.html',
  styleUrls: ['./landloser-site-mapping-workbench.component.scss']
})
export class LandloserSiteMappingWorkbenchComponent implements OnInit {

  @Input() siteMappingEnabled: boolean = true;

  @Input() selectedVillageName: string;

  @Input() villageId: number;

  @Input() allotmentType: string;

  ecData: any = {};

  ecNumbers: string[] = [];

  selectedEcNumber: string;

  selectedSiteMapId: number;

  // filteredOptions: Observable<string[]>;

  myControl = new FormControl('');

  selectedSiteNo: any;

  siteMappingList: any[] = [];

  selectedSiteMappingList: any[] = [];

  siteList: any[] = [];

  selectedSiteId: any =0;

  selectedVillageId: any;
  entitlementMatrixList: any[] = [];
  ecDetailId: any;
  entitledCount: number = 0;
  totalDimensionSum: number = 0;
  totalSitesSum: number = 0;
  selectedSiteMap: any = {};
  ecNumber: any;
  hasChoosenAllSites : boolean = false;
  allotmentTypeId : number = 0;
  isSubmitBtnEnabled: boolean = false;


  constructor(public dialog: MatDialog, private _snackBar: MatSnackBar, private route : ActivatedRoute,
    private applicationService: ApplicationService, private ecStatusConstants: EcStatusService, private router: Router) {
      this.allotmentTypeId = this.route.snapshot.queryParams['allotmentTypeId'];
     }

  ngOnInit(): void {
  }

  getEcNumbers(keyword: string) {
    this.applicationService.searchEcNumbers(keyword, this.villageId, this.ecStatusConstants.EC_SITE_SUMMARY_DETERMINED_CODE).subscribe(res => {
      this.ecNumbers = res;
    })
  }

  getEcDetails = () => {
    if (!this.selectedEcNumber) {
      return;
    }
    this.applicationService.fetchEcDetails(this.selectedEcNumber).subscribe(res => {
      this.ecData = res;
      this.ecDetailId = res.ecId;
      this.ecNumber = res.ecNo;
      this.entitlementMatrixList = res.entitledMatrixDataList;
      this.siteMappingList = res.siteMappingDataList;
      this.totalDimensionSum = res.alExtent;
      this.totalSitesSum = res.totalNoOfSites;
      this.selectedVillageId = res.villageId;
      this.selectedVillageName = res.villageName;
      this.ecData.comments = res.comments;
    })
  }

  calculateTotalSitesAndAllottedSum(){
    for(let i = 0; i < this.entitlementMatrixList.length; i++){
      this.totalDimensionSum = this.totalDimensionSum + this.entitlementMatrixList[i].totalDimension;
      this.totalSitesSum = this.totalSitesSum + this.entitlementMatrixList[i].noOfSites;
    }
  }

  setSelectedEcNumber(event: any) {
    console.log("event", event);
    this.selectedEcNumber = event.option.value;
    this.getEcDetails();
  }

  fetchAllSiteData = () => {
    this.applicationService.fetchAllSiteMasterData('available', this.selectedSiteMapId, this.selectedVillageId).subscribe(res => {
      if (res) {
        this.siteList = res;
        this.selectedSiteMap['availableCount'] = this.siteList.length;
      }
    });
  }

  setSiteMapping(siteMapObj: any, index: number) {
    this.selectedSiteMapId = null;
    if (siteMapObj.checked) {
      this.selectedSiteMapId = siteMapObj.dimensionId;
      siteMapObj.hasSiteMappingSelected = true;
    }else{
      this.siteMappingList[index].siteNo = '';
      siteMapObj.hasSiteMappingSelected = false;
    }
    this.fetchAllSiteData();
    this.selectedSiteMap = siteMapObj;
    this.selectedSiteMap.allottedCount = 0;
    this.selectedSiteId = "";
    this.selectedSiteMapId = siteMapObj.dimensionId;
  }

  setSelectedSiteData(siteId: any) {
    if (siteId == "") {
      return;
    }
    let siteData = this.siteList.find(site => site.siteMstId == siteId);
    for (let i = 0; i < this.siteMappingList.length; i++) {
      if(this.validateSiteMapping(this.siteMappingList[i], siteData, i)){
        if(this.siteMappingList[i].siteNo){
          this.siteMappingList[i].siteNo =  this.siteMappingList[i].siteNo +","+siteData.siteNo ;
        }else{
          this.siteMappingList[i].siteNo = siteData.siteNo;
        }
        this.selectedSiteMap.allottedCount = this.siteMappingList[i].siteNo ? this.siteMappingList[i].siteNo.split(',').length : 0;
      }
    }
    //calculate allotted sites for checked site
    this.enableSaveBtn();
  }

  validateSiteMapping(siteMappingObj : any, siteData: any, idx:number){
    if (!(this.selectedSiteMapId == siteMappingObj.dimensionId && siteMappingObj.checked)) {
      return false;
    }
    let siteNos = siteMappingObj.siteNo ? siteMappingObj.siteNo.split(',') : [];
    if(siteNos.length >= siteMappingObj.noOfSites){ //compare if added sites count is within noOfSites
      this.siteMappingList[idx].siteNo = ''; 
      return true;
    }
    if(!siteMappingObj.siteNo){ //first site no
      return true;
    }  
    for(let i = 0; i < siteNos.length; i++){
      if(siteNos[i] == siteData.siteNo){ //loop and check if selected site no already exists
        return false;
      }
    }
    return true;
  }

  enableSaveBtn(){
    for(let i = 0; i < this.siteMappingList.length; i++){
      let addedSites = this.siteMappingList[i].siteNo ? this.siteMappingList[i].siteNo.split(',') : [];
      if(this.siteMappingList[i].noOfSites != addedSites.length){
        this.hasChoosenAllSites = false;
        break;
      }else{
        this.hasChoosenAllSites = true;
      }
    }
  }

  fetchEntitledCount(){
    this.entitledCount = 0;
    for(let i = 0; i < this.siteMappingList.length; i++){
      this.entitledCount = this.entitledCount + this.siteMappingList[i].noOfSites;
    }
    return this.entitledCount;
  }

  _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.ecNumbers.filter(option => option.toLowerCase().includes(filterValue));
  }

  openDialog = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '180px';
    dialogConfig.width = '450px';
    dialogConfig.data = "Village: "+ this.selectedVillageName +" EC No: "+this.ecNumber+", successfully serviced.Do you want to continue?";
    const dialogRef = this.dialog.open(ConfirmationModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.saveSiteMapping();
      }
    })
  }

  openSnackbar(message: string, action?: string) {
    if (!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }

  saveSiteMapping = () => {
      let payload = this.validateAndConstructPayload(this.siteMappingList);
      this.applicationService.saveSiteMapping(this.ecDetailId, payload).subscribe(res =>{
        this.openSnackbar("Successfully Saved", "Dismiss");
        this.router.navigate(['/allotment-dashboard']);
      })
  }

  validateAndConstructPayload(data: any) {
    let mappingListData : any[] = [];
    for (let i = 0; i < data.length; i++) {
      let j = 0;
      if (data[i].checked) {
        j = j + 1;
        if (!data[i].dimensionId) {
          throw new Error("Row " + j + " : dimension must not be empty");
        }
        if (!data[i].siteNo) {
          throw new Error("Row " + j + " : site number must not be empty");
        }
        let payload = {};
        payload['dimensionId'] = data[i].dimensionId;
        payload['noOfSites'] = data[i].noOfSites;
        payload['siteNumber'] = data[i].siteNo;
        console.log(payload);
        mappingListData.push(payload);
      }
    }
    return mappingListData;
  }

  onRefreshClicked(data: any) {
    console.log(data);
    data.siteNo = null;
    this.selectedSiteMap.allottedCount = 0;
  }
  printPage() {
    let res = window.print();
    console.log("printed",res);
  }

  checkBoxClicked(event: any) {
    console.log(event.checked);
    if (event.checked) {
        this.isSubmitBtnEnabled = true;
    } else{
      this.isSubmitBtnEnabled = false;
    }
  }
}
