import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { RoleConfigService } from 'src/app/shared/config/role/role.config.service';
import { AllotmentTypeConstants } from 'src/app/utils/allotment-type-constants';
import { ApplicationService } from 'src/services/application.service';
import { AuthService } from 'src/services/auth.service';
import { StrorageService } from 'src/services/strorage.service';
import { UserService } from 'src/services/user-service/user.service';

@Component({
  selector: 'app-allotment-dashboard',
  templateUrl: './allotment-dashboard.component.html',
  styleUrls: ['./allotment-dashboard.component.scss']
})
export class AllotmentDashboardComponent implements OnInit {

  user: any;

  landLoserClicked: boolean = false;

  allotmentTypeList : any [] = [];

  allotmentTypeId : number = 0;

  colorList : string [] = ['#4472c4', '#ed7d31','#065465'];

  constructor(private applicationService: ApplicationService, private authService: AuthService,
    private _snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute, private userService: UserService,
    private roleConfig: RoleConfigService, private storageService: StrorageService) { }

  ngOnInit() {
    this.user = this.storageService.getUser();
    this.fetchAllotmentTypes();
  }

  onTotalBtnClicked () {
    this.router.navigate(['/allotment-workbench'], {queryParams:{allotmentCountType:'total'}});
  }

  allotmentTypeClickHandler(allotmentType : any){
    if(allotmentType.allotmentTypeCode == AllotmentTypeConstants.ALLOTMENT_TYPE_LANDLOSER){
      this.landLoserClicked = !this.landLoserClicked;
      this.allotmentTypeId = allotmentType.id;
    }
  }

  onLandLoserBtnClicked () {
    this.landLoserClicked = !this.landLoserClicked;
  }

  onGeneralBtnClicked () {
    // this.router.navigate(['/allotment-workbench'], {queryParams:{allotmentCountType:'general'}});
  }

  onSiteAssignmentBtnClicked () {
    this.router.navigate(['/allotment-workbench'], {queryParams:{allotmentCountType:'site_allotment', allotmentTypeId: this.allotmentTypeId}});
  }

  onSiteMappingBtnClicked () {
    this.router.navigate(['/allotment-workbench'], {queryParams:{allotmentCountType:'site_allotment', allowSiteMapping:'true', allotmentTypeId: this.allotmentTypeId}});
  }

  onAllotmentLetterBtnClicked () {
    this.router.navigate(['/allotment-workbench'], {queryParams:{allotmentCountType:'allotment_letter_printing', allotmentTypeId: this.allotmentTypeId}});
  }

  fetchAllotmentTypes = () => {
    this.applicationService.fetchAllotmentTypes().subscribe(res => {
      this.allotmentTypeList = res;
      this.recycleColorsList(); //recycles existing colors if dataList length exceeds colorset
    })
  }

  recycleColorsList = () => {
    let colorIdx = 0;
    for(let i = 0; i < this.allotmentTypeList.length ; i++){
      if(colorIdx > this.colorList.length){
        colorIdx = 0;
      }
      if(i > this.colorList.length){
        this.colorList.push(this.colorList[colorIdx]);
        colorIdx++;
      }
    }
  }

}
