import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BlobDownloaderService {

  constructor() { }

  public static downloadFile(blob: Blob, fileExt:string): void {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    // a.download = this.getFilenameFromUrl(url); 
    a.download = 'site-allotment-download.'+fileExt;
    if(fileExt.toLowerCase() == 'pdf'){
      this.openDocument(blob);
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      return;
    }
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  public static printPDF(blob: Blob): void {
    const url = window.URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);

    iframe.style.display = 'none';
    iframe.src = url;
    iframe.onload = () => {
      setTimeout(() => {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
  }

  public static openDocument(blob: Blob): void {
    const url = window.URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);
    window.open(url);
    window.URL.revokeObjectURL(url);
    document.body.removeChild(iframe);
  }

  public static getFilenameFromUrl(url:string) {
    const pathname = new URL(url).pathname;
    return pathname.substring(pathname.lastIndexOf('/') + 1);
  }

}
