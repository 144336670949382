    <div class="row mt-3">
        <div class="ml-5 col-sm-12">
            <h1 class="w-100 heading-text">Site Status</h1>
        </div>
    </div>

    <div class="row d-flex justify-content-between">
        <div *ngFor="let countData of siteCountList; let i = index" class="col-sm-4 my-4 d-flex justify-content-center">
            <div class="status-tile" [style.background-color]="colors[i]">
                {{countData.statusName+' '}}({{countData.count}})
            </div>
        </div>
    </div>

