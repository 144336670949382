import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { DateTimeUtils } from 'src/app/utils/date-utils';


@Component({
  selector: 'app-application-details-preview-dialog',
  templateUrl: './application-details-preview-dialog.component.html',
  styleUrls: ['./application-details-preview-dialog.component.scss']
})
export class ApplicationDetailsPreviewDialogComponent implements OnInit {

  public faTimes = faTimes;
  previousSiteAllotmentTableDataList : any[] = [];
  
  constructor(public dialogRef: MatDialogRef<ApplicationDetailsPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
      console.log('Preview Data', data);
      this.previousSiteAllotmentTableDataList = data.previousSiteAllotments;
   }

  ngOnInit(): void {
  }

  cancelClick(){
    this.dialogRef.close();
  }

  dateFormatter = (date: any) => {
    return DateTimeUtils.formatDateFromStrToDate(date);
  }
  

}
