<div class="row">
    <div class="d-flex justify-content-end w-100">
        <fa-icon style="color: #282828;" [icon]="faTimes" size="2x" class="close-icon" (click)="cancelClick()"></fa-icon>
    </div>
</div>
<div class="container">
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            Application No:
        </div>
        <div class="value-label col-sm-5">
            {{data.applicationNo}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            Batch Name:
        </div>
        <div class="value-label col-sm-5">
            {{data.batchName}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            Application Form Type:
        </div>
        <div class="value-label col-sm-5">
            {{data.formTypeName}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            1. Site Dimension :
        </div>
        <div class="value-label col-sm-5">
            {{data.siteDimensionName}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            2. Initial Deposit : 
        </div>
        <div class="value-label col-sm-5">
            {{data.initialDepositName}}
        </div>
    </div>
    <div class="row mt-4 d-flex justify-content-left">
        <h2>Personal Info</h2>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            3. Name of the Applicant :
        </div>
        <div class="value-label col-sm-5">
            {{data.applicantName}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            4. Father / Husband Name :
        </div>
        <div class="value-label col-sm-5">
            {{data.familyMemberName}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            5. Address :
        </div>
        <div class="value-label col-sm-5">
            {{data.applicantAddress}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            City :
        </div>
        <div class="value-label col-sm-5">
            {{data.city}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            Pin code :
        </div>
        <div class="value-label col-sm-5">
            {{data.pinCode}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            6. Date of Birth (dd:mm:yyyy) :
        </div>
        <div class="value-label col-sm-5">
            {{data.dateOfBirth}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            7. Age :
        </div>
        <div class="value-label col-sm-5">
            {{data.age}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            8. Gender :
        </div>
        <div class="value-label col-sm-5">
            {{data.gender}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            9. Marital Status :
        </div>
        <div class="value-label col-sm-5">
            {{data.maritalStatus}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            10. Occupation :
        </div>
        <div class="value-label col-sm-5">
            {{data.occupation}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            11. Annual Income :
        </div>
        <div class="value-label col-sm-5">
            {{data.annualIncome}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            12. PAN No :
        </div>
        <div class="value-label col-sm-5">
            {{data.panNo || data.panCardNo}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            13. AADHAAR No :
        </div>
        <div class="value-label col-sm-5">
            {{data.aadhaarNo}}
        </div>
    </div>
    <div class="row mt-4 d-flex justify-content-start">
        <h2>Registration Information</h2>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            14. Registration No:
        </div>
        <div class="value-label col-sm-5">
            {{data.registrationNo}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            15. Registration Date (dd:mm:yyyy) :
        </div>
        <div class="value-label col-sm-5">
            {{dateFormatter(data.dateofRegister)}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label"> 16. Registration Fee :</div>
    </div>
    <div class="row ">
        <div class="offset-sm-1 col-sm-6 key-label">
            Previous Registration Fee
        </div>
        <div class="value-label col-sm-5">
            {{data.previousFee ? data.previousFee : data.prevRegistrationFee}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="offset-sm-1 col-sm-6 key-label">
            Revised Registration Fee:
        </div>
        <div class="value-label col-sm-5">
            {{data.revisedRegistrationFee ? data.revisedRegistrationFee : data.revRegistrationFee}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="offset-sm-1 col-sm-6 key-label">
            Difference Regn Fee Paid:
        </div>
        <div class="value-label col-sm-5">
            {{data.diffRegistrationFeePaid ? data.diffRegistrationFeePaid : data.revRegistrationFee}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="offset-sm-1 col-sm-6 key-label">
            New Registration Fee:
        </div>
        <div class="value-label col-sm-5">
            {{data.newFee ? data.newFee : data.newRegistrationFee}}
        </div>
    </div>
    <div class="row mt-4 d-flex justify-content-start">
        <h2>Initial Deposit Details </h2>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            17. Initial Deposit :
        </div>
        <div class="value-label col-sm-5">
            {{data.initialDepositAmount}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            Initial Deposit Date
        </div>
        <div class="value-label col-sm-5">
            {{dateFormatter(data.initialDepositDate)}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            Payment Mode
        </div>
        <div class="value-label col-sm-5">
            {{data.paymentMode}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            DD/ NEFT/ RTGS No:
        </div>
        <div class="value-label col-sm-5">
            {{data.paymentModeNumber ? data.paymentModeNumber : data.initialDepositPaymentModeNo}}
        </div>
    </div>
    <div class="row mt-4 d-flex justify-content-start">
        <h2>Previous Attempt & Eligible Category Info </h2>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            18. Category :
        </div>
        <div class="value-label col-sm-5">
            {{data.selectedCategoryName}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            19. Are you residing in Karnataka for not less than 15 years prior to the date of Registration?
        </div>
        <div class="value-label col-sm-5">
            {{data.residesAbove15Yrs || data.domicile}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            20. Do you or your wife/husband or your dependent children or your dependent parents or your dependent brothers/sisters own a site or a house in Bangalore Metropolitan area or have been allotted a site or a house in the Bangalore Metropolitan area by the erstwhile CITB, BDA, KHB, HBCS or any other authority?
        </div>
        <div class="value-label col-sm-5">
            {{data.ownSiteHouse}}
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label">
            21. No of attempts made previously for allotment of site (excluding this application):
        </div>
        <div class="value-label col-sm-5">
            {{data.previousAttempts || '0'}}
        </div>
    </div>
    <table border="1">
        <thead>
            <tr>
                <th class="key-label">Sl. No.</th>
                <th class="key-label">Reg. No</th>
                <th class="key-label">Application No.</th>
                <th class="key-label">Date (dd/mm/yyyy)</th>
                <th class="key-label">Category</th>
                <th class="key-label">Dimension (AA X BB)</th>
                <th class="key-label">Initial Deposit</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let prevSiteTableData of previousSiteAllotmentTableDataList; let i = index">
                <td class="value-label">{{ i+1 }}</td>
                <td class="value-label">{{prevSiteTableData.registrationNo}}</td>
                <td class="value-label">{{prevSiteTableData.applicationNo}}</td>
                <td class="value-label">{{prevSiteTableData.date}}</td>
                <td class="value-label">{{prevSiteTableData.category}}</td>
                <td class="value-label">{{prevSiteTableData.dimension}}</td>
                <td class="value-label">{{prevSiteTableData.initialDeposit}}</td>
            </tr>
        </tbody>
    </table>
    <div class="row mt-3">
        <div class="col-sm-7 key-label"> 23. Do you wish to be considered for allotment under J Category?
        </div>
        <div class="value-label col-sm-5">
            {{data.allotmentUnderJCategory || data.jcategory}}
         </div>
    </div>
    <div *ngIf="data.maritalStatus == 'Married'">
        <div class="row mt-4 d-flex justify-content-start">
            <h2>24. If Married </h2>
        </div>
        <div class="row mt-3">
            <div class="col-sm-7 key-label"> a. Spouse’s Name:
            </div>
            <div class="value-label col-sm-5">
                {{data.spouseName}} 
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-sm-7 key-label"> b. Spouse Date of Birth :
            </div>
            <div class="value-label col-sm-5"> {{dateFormatter(data.spouseDateOfBirth)}} </div>
        </div>
        <div class="row mt-3">
            <div class="col-sm-7 key-label"> c. Spouse AADHAAR No :
            </div>
            <div class="value-label col-sm-5"> {{data.spouseAadhaarNo}} </div>
        </div>
        <div class="row mt-3">
            <div class="col-sm-7 key-label"> d. No. of Children :
            </div>
            <div class="value-label col-sm-5"> {{data.noOfChildren || data.spouseNoOfChildren}} </div>
        </div>
        <div class="row mt-3">
            <div class="col-sm-7 key-label"> e. Occupation:
            </div>
            <div class="value-label  col-sm-5"> {{data.spouseOccupation}} </div>
        </div>
    </div>
    <div class="row mt-4 d-flex justify-content-left">
        <h2>25. Bank Info </h2>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label"> Name of the Bank:
        </div>
        <div class="value-label col-sm-5"> {{data.bankName}} </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label"> Address of the Bank:
        </div>
        <div class="value-label col-sm-5"> {{data.bankAddress}} </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label"> Pin code :
        </div>
        <div class="value-label col-sm-5"> {{data.bankPinCode || data.bankPincode}} </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label"> IFSC Code:
        </div>
        <div class="value-label col-sm-5"> {{data.bankIfsc || data.bankIfscCode}} </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label"> Account No :
        </div>
        <div class="value-label col-sm-5"> {{data.bankAccountNo}} </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label"> AADHAAR No :
        </div>
        <div class="value-label col-sm-5"> {{data.aadharNumber || data.aadhaarNo}} </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label"> Mobile No:
        </div>
        <div class="value-label col-sm-5"> {{data.mobileNo ||data.bankMobileNo}} </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-7 key-label"> Email :
        </div>
        <div class="value-label col-sm-5"> {{data.email || data.bankEmail}} </div>
    </div>
</div>