import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';
import { UserService } from 'src/services/user-service/user.service';

@Component({
  selector: 'app-statics',
  templateUrl: './statics.component.html',
  styleUrls: ['./statics.component.scss']
})
export class StaticsComponent implements OnInit {
@Output() staticsClick = new EventEmitter();
@Input() notificationType: any;

  public applicantCount;
  public ApplicantStatistics;
  public onlineApplicantCount;
  public helpdeskApplicantCount;
  public submitWithoutDocuments;
  public readyToScrutinized;
  public onlineApplicantPercent;
  public helpdeskApplicantPercent;
  public submitWithoutPercent;
  public readyToScrutinizedPercent;
  isCommitteeUser: boolean;
  constructor(private applicationService : ApplicationService,  private userService: UserService,
    private route: ActivatedRoute) {
   }

  ngOnInit(): void {
    this.fetchAllApplicant();
  
  }
  fetchAllApplicant(){
    this.applicationService.fetchAllApplicantCount().subscribe(res =>{
      this.applicantCount = res;
      this.getApplicantStatistics();
    })
  }

  onStaticsClick(){
     this.staticsClick.emit();
  }

getApplicantStatistics(){
this.applicationService.getApplicantStatistics().subscribe(res =>{
  this.ApplicantStatistics = res;
  // if(res && res.onlineApplicantCount != null && res.onlineApplicantCount != undefined && this.applicantCount){
  //   this.onlineApplicantCount = res.onlineApplicantCount;
  //   this.onlineApplicantPercent = ((res.onlineApplicantCount/this.applicantCount)*100).toFixed(2);
  // } 
      if(res && res.helpdeskApplicantCount != null){
      this.applicantCount = res.totalCount;
      this.helpdeskApplicantCount = res.helpdeskApplicantCount;
      this.onlineApplicantCount = res.onlineApplicantCount;
      this.helpdeskApplicantPercent = ((res.helpdeskApplicantCount/this.applicantCount)*100).toFixed(2);
      this.onlineApplicantPercent = ((res.onlineApplicantCount/this.applicantCount)*100).toFixed(2);
      } 
    })
  }
}