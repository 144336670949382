import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-allotment-count-container',
  templateUrl: './allotment-count-container.component.html',
  styleUrls: ['./allotment-count-container.component.scss']
})
export class AllotmentCountContainerComponent implements OnInit {

  @Input() countData : any;

  @Input() dimensionCounts:any;

  colors: string[] = ['gray', 'olive', 'navy', 'skyblue', 'orange', 'yellow'];

  constructor() {
  }

  ngOnInit(): void {
    console.log(this.countData,'count data site allotment');
  }

}
