import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { ApiService } from 'src/services/api.service';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { RoleConfigService } from 'src/app/shared/config/role/role.config.service';

@Component({
  selector: 'app-leadership-applicant-datatable',
  templateUrl: './leadership-applicant-datatable.component.html',
  styleUrls: ['./leadership-applicant-datatable.component.scss']
})
export class LeadershipApplicantDatatableComponent implements OnInit {

  constructor(private cdr:ChangeDetectorRef,private applicationService: ApplicationService,private router: Router,private confirmDialogService: ConfirmDialogService, private apiService:ApiService,
    private storageService: StrorageService, private route:ActivatedRoute,private roleConfig: RoleConfigService) { }

    public dataSource = new MatTableDataSource();
 
    public displayedColumns: string[] = ['siNo', 'acknowledgementNo', 'age', 'applicationNo', 'applicantName', 'batchName', 'registrationNo', 'formTypeName', 'categoryName'];
  
    public faSync = faSync;
    public isRefreshing = false;
    public currentPage = 0;
    public show = false;
    public totalSize = 0;
    user: any;
    public pageSize =5;
    public totalApplicantCount: any = 0;
    datatableHeading : any;
    statusCode: any;
    searchKeyword:string;

  ngOnInit(): void {
    this.user = this.storageService.getUser();
    this.statusCode = this.route.snapshot.queryParams['channelCode'];
    this.setPageHeading();
    this.getApplicantData();
  }

  setPageHeading() {
    if(this.statusCode == this.roleConfig.CHANNEL_HELPDESK){
      this.datatableHeading = "HelpDesk";
    }
    if(this.statusCode == this.roleConfig.CHANNEL_ONLINE){
      this.datatableHeading = "Online";
    }
  }

  getApplicantData() {
    this.applicationService.fetchApplicantLeadershipData(this.currentPage, this.pageSize, this.statusCode,this.searchKeyword).subscribe(res=>{
      this.refreshDatatable(res);
    })  
  }

  refreshDatatable = (res: any) => {
    this.dataSource.data = res.data;
    this.totalSize = res.totalRecord;
    this.currentPage = (res.currentPage - 1);
    this.dataSource._updateChangeSubscription();
    this.cdr.detectChanges();
  }

  getApplicantPaginationData(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getApplicantData();
  }

 goBack(): void {
  window.history.back();
 }

 onClear(){
  this.searchKeyword = '';
  this.getApplicantData();
  }

 refreshClick(): void {
  this.currentPage = 0;
  this.pageSize = 10;
  this.getApplicantData();
}

}
