<div class="jumbotron" (click)="onStaticsClick()">
    <div class="TotalApplicant-header">
        Total Applications : {{applicantCount}}
    </div>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <div class="row">
        <div class="col-sm-10 offset-sm-1">
            &nbsp;&nbsp;&nbsp;
            <div class="row">
                <div class="col-sm-3">
                    <div class="percent-txt">{{helpdeskApplicantCount}}</div>
                    <div class="percent">{{helpdeskApplicantPercent}} %</div>
                    <div class="explain-txt">Applications Digitized - Helpdesk</div>
                </div>
                <div class="col-sm-3">
                    <div class="percent-txt">{{onlineApplicantCount}}</div>
                    <div class="percent">{{onlineApplicantPercent}} %</div>
                    <div class="explain-txt">Applications Digitized - Online</div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="h-50"></div>
