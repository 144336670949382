import { Component, OnInit } from '@angular/core';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';

@Component({
  selector: 'app-site-status-dashboard',
  templateUrl: './site-status-dashboard.component.html',
  styleUrls: ['./site-status-dashboard.component.scss']
})
export class SiteStatusDashboardComponent implements OnInit {

  constructor(private applicationService: ApplicationService) { }

  siteCountList : any [] = [];

  villageList : any [] = [];

  villageId : number = 0;

  colors : string [] = ['#479c9d','#e39e83','#3c4d93','#96c180','#e49c64','#a9973f','#671819'];
  public isRefreshing = false;


  public faSync = faSync;

  ngOnInit(): void {
    this.fetchSiteStatusCount();
    this.fetchAllVillages();
  }

  fetchSiteStatusCount = () => {
    this.applicationService.fetchSiteStatusCount(this.villageId).subscribe(res => {
      this.siteCountList = res;
      this.recycleColorsList();
    })
  }

  fetchAllVillages = () => {
    this.applicationService.fetchAllVillages().subscribe(res => {
      this.villageList = res;
    });
  }

  recycleColorsList = () => {
    let colorIdx = 0;
    for(let i = 0; i < this.siteCountList.length ; i++){
      if(colorIdx > this.colors.length){
        colorIdx = 0;
      }
      if(i > this.colors.length){
        this.colors.push(this.colors[colorIdx]);
        colorIdx++;
      }
    }
  }

  refreshClick() {
    let currentComponent = this;
    this.isRefreshing = true;
    this.fetchSiteStatusCount();
    setTimeout(()=>{
      currentComponent.isRefreshing = false;
    }, 3000)
  }

  clickBack() {
    window.history.back();
  }

}
